import common from './common'
import workbench from './workbench'
import platform from './platform'
import business from './business'
import industry from './industry'

import apps from './apps'
import build from './build'


let URL = {
  common,
  workbench,
  platform,
  business,
  apps,
  build,
  industry
}

export default URL