const industry = {
  namespaced: true,
  state: {
    clientFilter: null,
    supplierFilter: null
  },
  mutations: {
    setClientFilter(state, filter){
      state.clientFilter = filter
    },
    setSupplierFilter(state, filter){
      state.supplierFilter = filter
    }
  }
}

export default industry