import { domainHost, domainRootHost } from '@/assets/script/host.js'

import siteConfig from '@/config/site.config'

const TokenKey = 'login-token'

let setToken = token => localStorage.setItem(TokenKey, token);
let getToken = () => localStorage.getItem(TokenKey)
let removeToken = () => localStorage.removeItem(TokenKey)

const setUser = (res, commit, dispatch, reject) => {
  if(res.code == 400) {
    dispatch('logout')

    let url = location.protocol + '//' + domainHost
    location.href = url + siteConfig.loginUrl
  }

  let { data } = res
  if (!data) {
    reject('Verification failed, please Login again.')
  }

  let { roleList, user, org, deptList, regionCode, tenantId, inviteLink, inviteStatus, domain, domainStatus} = data

  localStorage.setItem('login-region', regionCode)
  localStorage.setItem('login-tenant', tenantId)
  localStorage.setItem('login-user', JSON.stringify(user))

  roleList.forEach(role => {
    if(role.roleCode == "tenant_admin") {
      commit('SET_ISSUPER', 1)
      data.isSuper = 1
    }
    // todo admin管理员标识记录
  })
  commit('SET_USER', user)
  commit('SET_USER_ROLES', roleList)
  commit('SET_USER_DEPTS', deptList)
  commit('SET_ORG', org)
  commit('SET_CURRENT_REGION_CODE', regionCode)
  commit('SET_INVITE_LINK', inviteLink)
  commit('SET_INVITE_STATUS', inviteStatus)
  commit('SET_DOMAIN', {
    domain, domainStatus
  })
  // 判断当前url域名是否和domain一致，不一致则退出登录
  if(domainStatus === 1 && process.env.NODE_ENV !== 'sit') {
    let uDomain = location.host.split('.')[0]
    if(uDomain !== domain) {
      dispatch('logout')

      let url = location.protocol + '//' + domainHost
      location.href = url + siteConfig.loginUrl
    }
  }
  
  return data
}

const setRegionConfig = (res, dispatch, commit) => {
  if(res.code == 400) {
    dispatch('logout')

    let url = location.protocol + '//' + domainHost
    location.href = url + siteConfig.loginUrl
  }
  
  commit('SET_REGION_CONFIG', res.data)
}

const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i=0; i<ca.length; i++) 
  {
    var c = ca[i].trim();
    if (c.indexOf(name)==0) return c.substring(name.length,c.length);
  }
  return "";
}
const delCookie = (name) => {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval= getCookie(name);
  if(cval!=null)
  document.cookie= name + "="+cval+"; domain=" + domainRootHost + ";expires="+exp.toGMTString();
}
const setLoginData = () => {
  var data = getCookie('u-data');
  if(!data) return
  data = JSON.parse(data)

  localStorage.setItem('login-tenant', data.tenant)
  localStorage.setItem('login-token', data.token)
  localStorage.setItem('login-userid', data.userid)
  localStorage.setItem('login-region', data.region)
  localStorage.setItem('login-remember', data.remember)
  localStorage.setItem('login-domain', data.domain)
  
  sessionStorage.setItem('login-status', data.status)

  delCookie('u-data');
}

export {
  setToken,
  getToken,
  removeToken,
  setUser,
  setRegionConfig,
  setLoginData
}