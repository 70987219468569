var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tooltip",
        {
          staticClass: "icon-item",
          attrs: { effect: "dark", content: "微信公众号", placement: "bottom" },
        },
        [
          _c("i", {
            staticClass: "iconfont mb-icon-mobile",
            on: { click: _vm.wxOAOpen },
          }),
        ]
      ),
      _c("WXOfficialAccounts", { ref: "wxOA" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }