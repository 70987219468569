import { Message } from 'element-ui'

const MY_MESSAGE_DEFAULT = {
  showClose: true,
  duration: 2000,
  offset: 50
}
const showMessage = (type, options, single) => {
  let m = null
  if (typeof (options) === 'object') options = Object.assign({}, MY_MESSAGE_DEFAULT, options)
  else options = Object.assign({message: options}, MY_MESSAGE_DEFAULT)

  if(single) {
    if(document.querySelectorAll('.el-message').length === 0) {
      m = Message[type](options)
    }    
  } else {
    m = Message[type](options)
  }
  return m
}

const OwMessage = function(options, single = true) {
  return showMessage(options.type, options, single)
} 

OwMessage.success = (options, single = true) => {
  showMessage('success', options, single)
}

OwMessage.warning = (options, single = true) => {
  showMessage('warning', options, single)
}

OwMessage.info = (options, single = true) => {
  showMessage('info', options, single)
}

OwMessage.error = (options, single = true) => {
  showMessage('error', options, single)
}

export default OwMessage



