<template>
  <div>
    <section class="operation">
      <el-checkbox v-model="allChecked" @change="onAllChange">全选</el-checkbox>
      <div class="btn-container">
        <el-button v-if="msgType == 'new'" icon="el-icon-edit" type="primary" @click="read()">标记为已读</el-button>
        <el-button icon="iconfont mb-icon-common-delete" @click="del()">删除</el-button>
      </div>
    </section>
    <section class="content">
      <message-item v-for="(d, dIndex) in datas" :key="dIndex" :msgType="msgType" :data="d"
       @rowClick="rowClick" @itemClick='itemClick' @read="itemRead" @delete="itemDel"></message-item>
    </section>
    <section class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageIndex"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total="dataTotal">
    </el-pagination>
    </section>
  </div>
</template>
<script>
import MessageItem from './MessageItem'

export default {
  components: {
    MessageItem
  },
  props: {
    datas: Array,
    msgType: String,
    dataTotal: Number
  },
  data() {
    return {
      allChecked: false,
      pageIndex: 1,
      pageSize: 10,
    }
  },
  methods:{
    onAllChange(val) {
      this.datas.forEach(d => { d.checked = val })
    },
    handleSizeChange(val) {
      this.pageIndex = 1
      this.$emit('pagingChange', this.pageIndex, val)
    },
    handleCurrentChange(val) {
      this.$emit('pagingChange', val, this.pageSize)
    },
    rowClick(data) {
      this.$emit('rowClick', data)
    },
    itemClick() {
      this.allChecked = !this.datas.some(d => !d.checked)
    },
    itemRead() {
      this.allChecked = false

      this.$emit('read', this.msgType)
    },
    itemDel() {
      this.allChecked = false

      this.$emit('delete', this.msgType)
    },
    read() {
      let dataChecked = this.datas.filter(d => { return d.checked })
      if(dataChecked.length == 0) {
        this.$message({ message: '请选择要标记的消息', type:'error', customClass:'zZindex' })
        return 
      }

      let req = dataChecked.map(d => { return d.id })
      this.$axios.post(this.$URL.common.message.mark, req)
        .then(res => {
          this.allChecked = false
          this.$store.commit('common/readMessage', req)
          this.$emit('read', this.msgType)
        })
    },
    del() {
      let dataChecked = this.datas.filter(d => { return d.checked })
      if(dataChecked.length == 0) {

        this.$message({ message: '请选择要删除的消息', type:'error', customClass:'zZindex' })
        return 
      }

      this.$confirm('确定要删除消息吗', '提示', {
        type: 'warning',
        confirmButtonClass: 'dialog-btn danger'
      }).then(() => {
        let req = dataChecked.map(d => { return d.id })
        this.$axios.post(this.$URL.common.message.delMsg, req)
          .then(res => {
            this.allChecked = false
            this.$store.commit('common/delMessage', { type: this.msgType, id: req })
            this.$emit('delete', this.msgType)
          })
      }).catch(() => {})
    }
  }
}
</script>
<style scoped>
.operation{
  height: 60px;
  line-height: 60px;
  box-sizing: border-box;
  border-bottom: 1px solid #DCDCDC;
}
.btn-container{
  float: right;
}
.pagination{
  text-align: right;
  margin-top: 20px;
}
</style>