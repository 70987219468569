import layout from '@/modules/saas/layout'

const workbench = [{
  path: '/',
  component: layout,
  children: [
    { name: 'main',  path: '/main',  component: () => import('@/modules/saas/views/main') },
  ]
}, {
  name: 'main_workflow_default', 
  path: '/main/workflow',
  redirect: '/main/workflow/todo'
}, {
  name: 'main_workflow', 
  path: '/main/workflow/:entry',
  component: () => import('@/modules/saas/views/main/workflow')
}]

export default workbench