<template>
  <div class="header">
    <div class="header-left">
      <!-- TODO: 新增组织切换 -->
      <region-change></region-change>
    </div>
    <div class="header-right">
      <div v-if="isIndustry" class="icon-area" @click="toIndustry">
        <i class="iconfont mb-icon-assist-center"></i> 
        企联协作
        <i class="iconfont mb-icon-jump"></i>
      </div>
      <div v-if="isSuper" class="icon-area" @click="toBuild">
        <i class="iconfont mb-icon-design-center"></i> 
        构建中心
        <i class="iconfont mb-icon-jump"></i>
      </div>
      <div v-if="isIndustry || isSuper" class="split-line"></div>
      <div class="icon">
        <MobileIcon></MobileIcon>
      </div>
      <div class="icon" v-if="isSuper">
        <el-tooltip class="icon-item" effect="dark" content="系统管理" placement="bottom">
          <i class="el-icon-setting" @click="$_goto('/system')"></i>
        </el-tooltip>
      </div>
      <div class="icon">
        <MessageIcon></MessageIcon>
      </div>  
      <div class="avatar">
        <AvatarIcon></AvatarIcon>
      </div>
    </div>
  </div>
</template>
<script>
import MessageIcon from '@/components/Message/Icon'
import AvatarIcon from '@/components/Avatar/Icon'
import MobileIcon from '@/components/Avatar/MobileAccount'

import RegionChange from '@/components/RegionChange'

export default {
  name: 'framepage-header',  
  components: {
    MessageIcon,
    AvatarIcon,
    RegionChange,
    MobileIcon
  },
  data() {
    return {
      isSuper: false,
      regionConfig: [],
    }
  },
  computed: {
    isIndustry() { // er_std
      return this.isSuper && this.regionConfig
        && ((this.regionConfig.versionPackage 
            && this.regionConfig.versionPackage.versionType == 'er_std') 
          || (this.regionConfig.profile && this.regionConfig.profile.eaService))
    },
  },
  created() {
    this.isSuper = this.$store.getters.isSuper

    this.regionConfig = this.$store.getters.regionConfig
  },
  methods: {
    switchAsideCollapse () {
      this.$store.commit('common/switchAsideCollapse')
    },
    toBuild() {
      window.open(location.origin + `/build.html`)
    },
    toIndustry() {
      window.open(location.origin + `/industry.html`)
    },
  },
}
</script>

<style scope>
.header,
.header-left,
.header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .header-right .icon{
  margin-left: 0;
}
.header-right .split-line{
  width: 1px;
  height: 24px;
  margin-left: 10px;
  margin-right: 20px;
  background-color: #CED5D5;
}
.header-right .icon .icon-item {
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  color: #7891BE;
  cursor: pointer;
}
.icon-area{
  display: flex;
  align-items: center;
  line-height: 16px;
  padding: 10px 0 10px 10px;
  color: #899897;
  cursor: pointer;
  border-radius: 5px;
}
.icon-area i{
  margin-right: 7px;
  color: #7891BE;
}

.icon-area:hover{
  background-color: #E8F8F3;
  color: #00C185;
}
.icon-area:hover i {
  color: #00C185;
}
i.mb-icon-jump{
  margin-left: 2px;
  font-size: 16px;
  transform: scale(0.5);
  visibility: hidden;
}
.icon-area:hover i.mb-icon-jump {
  color: #7891BE;
  visibility: visible;
}
.icon-area:hover{
  background-color: #E8F8F3;
  color: #00C185;
}
.icon-area:hover i {
  color: #00C185;
}
</style>