<template>
  <div class="member-group-container" :class="{'inline': inline}">
    <template v-if="entry == 'dialog'">
      <!-- 外部的显示区域 -->
      <div class="user-area" :style="{cursor: disabled ? 'not-allowed': 'pointer'}">

        <User-Item v-for="(user, $index) in outTagList" :key="user.id"
          :userInfo="user" :deleteable="!disabled" @delete="removeTagOuter(user, $index)"></User-Item>
        <!-- <div class="user-item" v-for="(user, $index) in outTagList" :key="user.id">
          {{user.name}}
          <i v-if="!disabled" class="el-icon-close" @click="removeTagOuter(user, $index)"></i>
        </div> -->
        <div v-if="inline" class="place"></div>
        <i v-if="!disabled" @click="openUserDialog" class="create-icon"></i>
      </div>

      <el-dialog :title="title" :visible.sync="dialogVisible" :append-to-body="true" class="small">
        <div class="_components_member_group">
          <!-- 弹窗的用户显示 -->
          <div class="border-block user-list user-inner-container">
            <span v-if="selectTagList.length == 0">未选择</span>
            <User-Item v-for="(user, $index) in selectTagList" :key="user.id"
              :userInfo="user" :deleteable="!disabled" @delete="removeTag(user, $index)"></User-Item>
            <!-- <el-tag closable v-for="(tag, $index) in selectTagList"
              :key="tag.id" @close="removeTag(tag, $index)" class="user-tag">{{tag.name}}</el-tag> -->
          </div>
          <!--    用户选择区域    -->
          <div class="border-block user-select-area">
            <div class="top-bar" :class="{'focus': isSearchFocus}">

              <div class="float-l" v-show="!isSearchFocus">
                <ul>
                  <li class="option-item" :class="{'active': currentOptionId == item.id}"
                    @click="selectOption(item.id)" v-for="item in optionList" :key="item.id"
                  >{{item.label}}</li>
                </ul>
              </div>

              <div class="float-r">
                <el-input ref="searchRef" placeholder="搜索" clearable @blur="blurFocus"
                  v-model.trim="searchStr" @input="searchUser" @focus="handleSearchFocus"
                  @clear="clearFocus">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
            </div>

            <div class="user-content">
              <div v-show="!isSearchFocus && (currentOptionId == 1 || currentOptionId == 3)">
                <div class="float-l block info">
                  <!--  部门   -->
                  <div v-show="currentOptionId == 1">
                    <el-tree class="menu-tree" ref="orgTree"
                      node-key="id" icon-class="el-icon-arrow-right"
                      :data="orgs" :props="treeOrgDataDefaultProps"
                      @node-click="treeNodeClick" highlight-current></el-tree>
                  </div>
                  <!-- 角色 -->
                  <div v-show="currentOptionId == 3">
                    <el-tree class="menu-tree" ref="roleTree"
                      node-key="id" icon-class="el-icon-arrow-right"
                      :data="roles" :props="treeOrgDataDefaultProps"
                      @node-click="roleNodeClick" highlight-current></el-tree>
                  </div>
                </div>
                <div class="float-r block choose">
                  <div v-show="currentOptionId == 1">
                    <label class="check-box select-all" v-if="!isSingel">
                      <span class="float-l">已选 {{currentGroupUser.checkedUser.length || 0 + '/' + currentGroupUser.list.length}}</span>
                      <span class="float-r">
                        <el-checkbox v-show="currentGroupUser.list.length > 0"
                          :indeterminate="currentGroupUser.isIndeterminate"
                          @change="selectAllUserChange" v-model="currentGroupUser.selectAll"
                        ></el-checkbox>
                      </span>
                    </label>
                    <div class="single-radio-area" v-if="isSingel">
                      <el-radio-group v-model="currentGroupUser.checkedUser" style="width: 100%;">
                        <section
                          class="check-box"
                          v-for="user in currentGroupUser.list"
                          :key="user.id"
                          @click="changeTagRow(user, 'group', user.id)"
                        >
                          <div style="display: flex;align-items: center;">
                            <img class="user-head" :src="user.headImgUrl || defaultImg" />
                            <!-- <div class="float-l icon">{{user.realName}}</div> -->
                            <div class="float-l name">{{user.realName}}</div>
                          </div>
                          <el-radio @change="changeTag(user, $event)" @click.native="e => e.stopPropagation()" :label="user.id">&nbsp;</el-radio>
                        </section>
                      </el-radio-group>
                    </div>
                    <div class="single-checkbox-area" v-else>
                      <el-checkbox-group v-model="currentGroupUser.checkedUser">
                        <section
                          class="check-box"
                          v-for="user in currentGroupUser.list"
                          :key="user.id"
                          @click="changeTagRow(user, 'group')"
                        >
                          <div style="display: flex;align-items: center;">
                            <img class="user-head" :src="user.headImgUrl || defaultImg" />
                            <div class="float-l name">{{user.realName}}</div>
                          </div>
                          <el-checkbox @change="changeTag(user, $event)" @click.native="e => e.stopPropagation()" :label="user.id">&nbsp;</el-checkbox>
                        </section>
                      </el-checkbox-group>
                    </div>
                  </div>
                  <!-- TODO: 角色用户列表 -->
                  <div v-show="currentOptionId == 3">
                    <div class="single-radio-area" v-if="isSingel">
                      <el-radio-group v-model="currentRoleUser.checkedUser" style="width: 100%;">
                        <section class="check-box" v-for="user in currentRoleUser.list" :key="user.id" @click="changeTagRow(user, 'role', user.id)">
                          <div style="display: flex;align-items: center;">
                            <img class="user-head" :src="user.headImgUrl || defaultImg" />
                            <div class="float-l name">{{user.realName}}</div>
                          </div>
                          <el-radio @change="changeTag(user, $event)" @click.native="e => e.stopPropagation()" :label="user.id">&nbsp;</el-radio>
                        </section>
                      </el-radio-group>
                    </div>
                    <div class="single-checkbox-area" v-else>
                      <el-checkbox-group v-model="currentRoleUser.checkedUser">
                        <section
                          class="check-box"
                          v-for="user in currentRoleUser.list"
                          :key="user.id"
                          @click="changeTagRow(user, 'role')"
                        >
                          <div style="display: flex;align-items: center;">
                            <img class="user-head" :src="user.headImgUrl || defaultImg" />
                            <div class="float-l name">{{user.realName}}</div>
                          </div>
                          <el-checkbox @click.native="e => e.stopPropagation()" @change="changeTag(user, $event)" :label="user.id">&nbsp;</el-checkbox>
                        </section>
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 搜索区域和全部 -->
              <div v-show="isSearchFocus || currentOptionId == 2 || currentOptionId == 4" class="search-content">
                <ul>
                  <li v-for="user in userList" :key="user.id">
                    <label>
                      <p class="user-info-detail">
                        <img class="user-head" :src="user.headImgUrl || defaultImg" />
                        <span>{{user.realName}}</span>
                      </p>
                      <p>
                        <el-checkbox
                          v-if="!isSingel"
                          @click.native="e => e.stopPropagation()"
                          @change="changeUserStatus(user, $event)"
                          v-model="user.__checked"
                        ></el-checkbox>
                        <el-radio
                          v-else
                          @click.native="e => e.stopPropagation()"
                          @change="changeUserStatus(user, $event)"
                          :label="user.id"
                          v-model="user.__checked"
                        >&nbsp;</el-radio>
                      </p>
                    </label>
                  </li>
                </ul>
              </div>
              <!-- 动态参数区域 -->
              <div v-show="!isSearchFocus && currentOptionId == 5" class="search-content">
                <ul>
                  <li v-for="user in dynamicList" :key="user.id">
                    <label>
                      <p>{{user.realName}}</p>
                      <p>
                        <el-checkbox 
                          v-if="!isSingel" 
                          @click.native="e => e.stopPropagation()"
                          @change="changeUserStatus(user, $event)"
                          v-model="user.__checked"></el-checkbox>
                        <el-radio 
                          v-else 
                          :label="user.id" 
                          v-model="user.__checked"
                          @click.native="e => e.stopPropagation()"
                          @change="changeUserStatus(user, $event)">&nbsp;</el-radio>
                      </p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <footer>
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
          </footer>
        </div>
      </el-dialog>
    </template>
    <template v-if="entry == 'popover'">
      <!-- 外部的显示区域 -->
      <div class="user-area" :style="{cursor: disabled ? 'not-allowed': 'pointer'}">
        <User-Item v-for="(user, $index) in outTagList" :key="user.id"
          :userInfo="user" :deleteable="!disabled" @delete="removeTagOuter(user, $index)"></User-Item>
          
        <!-- <i v-if="!disabled" @click="openUserDialog" class="create-icon"></i> -->
        <el-popover :title="title" placement="bottom" trigger="click" v-model="dialogVisible" :append-to-body="true" popper-class="small">
          <div class="user-popover _components_member_group">
            <!-- 弹窗的用户显示 -->
            <div class="border-block user-list user-inner-container">
              <span v-if="selectTagList.length == 0">未选择</span>
              <User-Item v-for="(user, $index) in selectTagList" :key="user.id"
                :userInfo="user" :deleteable="!disabled" @delete="removeTag(user, $index)"></User-Item>
              <!-- <el-tag closable v-for="(tag, $index) in selectTagList"
                :key="tag.id" @close="removeTag(tag, $index)" class="user-tag">{{tag.name}}</el-tag> -->
            </div>
            <!--    用户选择区域    -->
            <div class="border-block user-select-area">
              <div class="top-bar" :class="{'focus': isSearchFocus}">

                <div class="float-l" v-show="!isSearchFocus">
                  <ul>
                    <li class="option-item" :class="{'active': currentOptionId == item.id}"
                      @click="selectOption(item.id)" v-for="item in optionList" :key="item.id"
                    >{{item.label}}</li>
                  </ul>
                </div>

                <div class="float-r">
                  <el-input ref="searchRef" placeholder="搜索" clearable @blur="blurFocus"
                    v-model.trim="searchStr" @input="searchUser" @focus="handleSearchFocus"
                    @clear="clearFocus">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                  </el-input>
                </div>
              </div>

              <div class="user-content">
                <div v-show="!isSearchFocus && (currentOptionId == 1 || currentOptionId == 3)">
                  <div class="float-l block info">
                    <!--  部门   -->
                    <div v-show="currentOptionId == 1">
                      <el-tree class="menu-tree" ref="orgTree"
                        node-key="id" icon-class="el-icon-arrow-right"
                        :data="orgs" :props="treeOrgDataDefaultProps"
                        @node-click="treeNodeClick" highlight-current></el-tree>
                    </div>
                    <!-- 角色 -->
                    <div v-show="currentOptionId == 3">
                      <el-tree class="menu-tree" ref="roleTree"
                        node-key="id" icon-class="el-icon-arrow-right"
                        :data="roles" :props="treeOrgDataDefaultProps"
                        @node-click="roleNodeClick" highlight-current></el-tree>
                    </div>
                  </div>
                  <div class="float-r block choose">
                    <div v-show="currentOptionId == 1">
                      <label class="check-box select-all" v-if="!isSingel">
                        <span class="float-l">已选 {{currentGroupUser.checkedUser.length || 0 + '/' + currentGroupUser.list.length}}</span>
                        <span class="float-r">
                          <el-checkbox v-show="currentGroupUser.list.length > 0"
                            :indeterminate="currentGroupUser.isIndeterminate"
                            @change="selectAllUserChange" v-model="currentGroupUser.selectAll"
                          ></el-checkbox>
                        </span>
                      </label>
                      <div class="single-radio-area" v-if="isSingel">
                        <el-radio-group v-model="currentGroupUser.checkedUser" style="width: 100%;">
                          <section
                            class="check-box"
                            v-for="user in currentGroupUser.list"
                            :key="user.id"
                            @click="changeTagRow(user, 'group', user.id)"
                          >
                            <div style="display: flex;align-items: center;">
                              <img class="user-head" :src="user.headImgUrl || defaultImg" />
                              <!-- <div class="float-l icon">{{user.realName}}</div> -->
                              <div class="float-l name">{{user.realName}}</div>
                            </div>
                            <el-radio @change="changeTag(user, $event)" @click.native="e => e.stopPropagation()" :label="user.id">&nbsp;</el-radio>
                          </section>
                        </el-radio-group>
                      </div>
                      <div class="single-checkbox-area" v-else>
                        <el-checkbox-group v-model="currentGroupUser.checkedUser">
                          <section
                            class="check-box"
                            v-for="user in currentGroupUser.list"
                            :key="user.id"
                            @click="changeTagRow(user, 'group')"
                          >
                            <div style="display: flex;align-items: center;">
                              <img class="user-head" :src="user.headImgUrl || defaultImg" />
                              <div class="float-l name">{{user.realName}}</div>
                            </div>
                            <el-checkbox @change="changeTag(user, $event)" @click.native="e => e.stopPropagation()" :label="user.id">&nbsp;</el-checkbox>
                          </section>
                        </el-checkbox-group>
                      </div>
                    </div>
                    <!-- TODO: 角色用户列表 -->
                    <div v-show="currentOptionId == 3">
                      <div class="single-radio-area" v-if="isSingel">
                        <el-radio-group v-model="currentRoleUser.checkedUser" style="width: 100%;">
                          <section class="check-box" v-for="user in currentRoleUser.list" :key="user.id" @click="changeTagRow(user, 'role', user.id)">
                            <div style="display: flex;align-items: center;">
                              <img class="user-head" :src="user.headImgUrl || defaultImg" />
                              <div class="float-l name">{{user.realName}}</div>
                            </div>
                            <el-radio @change="changeTag(user, 'role', $event)" @click.native="e => e.stopPropagation()" :label="user.id">&nbsp;</el-radio>
                          </section>
                        </el-radio-group>
                      </div>
                      <div class="single-checkbox-area" v-else>
                        <el-checkbox-group v-model="currentRoleUser.checkedUser">
                          <section
                            class="check-box"
                            v-for="user in currentRoleUser.list"
                            :key="user.id"
                            @click="changeTagRow(user, 'role')"
                          >
                            <div style="display: flex;align-items: center;">
                              <img class="user-head" :src="user.headImgUrl || defaultImg" />
                              <div class="float-l name">{{user.realName}}</div>
                            </div>
                            <el-checkbox @click.native="e => e.stopPropagation()" @change="changeTag(user, $event)" :label="user.id">&nbsp;</el-checkbox>
                          </section>
                        </el-checkbox-group>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 搜索区域和全部 -->
                <div v-show="isSearchFocus || currentOptionId == 2 || currentOptionId == 4" class="search-content">
                  <ul>
                    <li v-for="user in userList" :key="user.id">
                      <label>
                        <p class="user-info-detail">
                          <img class="user-head" :src="user.headImgUrl || defaultImg" />
                          <span>{{user.realName}}</span>
                        </p>
                        <p>
                          <el-checkbox
                            v-if="!isSingel"
                            @click.native="e => e.stopPropagation()"
                            @change="changeUserStatus(user, $event)"
                            v-model="user.__checked"
                          ></el-checkbox>
                          <el-radio
                            v-else
                            @click.native="e => e.stopPropagation()"
                            @change="changeUserStatus(user, $event)"
                            :label="user.id"
                            v-model="user.__checked"
                          >&nbsp;</el-radio>
                        </p>
                      </label>
                    </li>
                  </ul>
                </div>
                <!-- 动态参数区域 -->
                <div v-show="!isSearchFocus && currentOptionId == 5" class="search-content">
                  <ul>
                    <li v-for="user in dynamicList" :key="user.id">
                      <label>
                        <p>{{user.realName}}</p>
                        <p>
                          <el-checkbox 
                            v-if="!isSingel" 
                            @click.native="e => e.stopPropagation()"
                            @change="changeUserStatus(user, $event)"
                            v-model="user.__checked"></el-checkbox>
                          <el-radio 
                            v-else 
                            :label="user.id" 
                            v-model="user.__checked"
                            @click.native="e => e.stopPropagation()"
                            @change="changeUserStatus(user, $event)">&nbsp;</el-radio>
                        </p>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <footer>
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="confirm">确定</el-button>
            </footer>
          </div>
          <i slot="reference" v-if="!disabled" @click="openUserDialog" class="create-icon" style="display:block;"></i>
        </el-popover>
      </div>
    </template>
  </div>
</template>
<script>

// const CURRENT_USER_LABEL = '当前用户'
// const CURRENT_USER = {
//   id: CURRENT_USER_KEY,
//   name: CURRENT_USER_LABEL,
//   realName: CURRENT_USER_LABEL
// }

import { USER_OPTION_LIST, DYNAMIC_USER } from './config';
import Util from '@/assets/script/utils';

const CURRENT_USER_KEY = DYNAMIC_USER.id;
const CURRENT_USER = {...DYNAMIC_USER};

export default {
  components: {
    UserItem: () => import("@/components/User/UserItem")
  },
  props: {
    placeholder: String,
    selectList: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSingel: {
      type: Boolean,
      default: false,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '选择用户'
    },
    inline: {
      type: Boolean,
      default: false
    },
    /*
      调用模式: form:表单模式,filter:查询模式
      1.表单模式-{当前用户}取登录人id及姓名
      2.查询模式-{当前用户}为特殊标记(id=${current_user})
    */
    useMode: {
      type: String,
      default: 'form'
    },
    // 展示方式 dialog 、 popover
    entry: {
      type: String,
      default: 'dialog'
    },
    range: {
      type: Array,
      default: () => (['orgs', 'current'])
    },
    limit: Object,
    limitType: {
      type: String,
      default: ''
    }
  },
  watch: {
    selectList: {
      handler(newVal, oldVal){
        if(Array.isArray(newVal) && Array.isArray(oldVal) 
          && newVal.toString() == oldVal.toString()) return

        if(newVal.length != 0 && typeof newVal[0] != 'object'){ 
          this.getSelectUserList(newVal, true)
        }else{
          this.outTagList = [];
          this.selectTagList = [];
        }
      },
      immediate: true
    }
  },
  model: {
    prop: "selectList",
    event: "selectChange",
  },
  data() {
    return {
      // 是否初始化过点击
      initSelect: false,
      initRoleSelect: false,
      defaultImg: require('@/assets/img/user-head.png'),
      isRequesting: false,
      dialogVisible: false,
      allUserListData: {},
      allRoleUserListData: {},
      currentOptionId: "1",
      searchStr: "",
      isSearchFocus: false,
      treeOrgDataDefaultProps: {
        children: "children",
        label: "title",
      },
      optionList: [
        {
          label: "部门",
          id: "1",
        },
        {
          label: DYNAMIC_USER.realName,
          id: "2",
        },
      ],

      selectTagList: [], //  所有选中的用户
      outTagList: [],   // 用来显示在外面的用户

      orgs: [],                                         //  所有组织的列表
      roles: [],                                        //  所有角色的列表
      dynamicList: [Util.deepClone(CURRENT_USER)],  //  动态参数列表
      //  当前选中的组织
      currentGroupUser: {
        list: [], //  当前选中组织的用户列表
        checkedUser: [], //  当前选中组织内部选中的用户
        isIndeterminate: false, //  当前选中组织的用户列表全选状态
        selectAll: false, //  当前选中组织的全选按钮是否选中
      },
      // 当前选中的角色
      currentRoleUser: {
        list: [],
        checkedUser: []
      },

      userList: [],
      mineUserList: [],
    }
  },  
  methods: {
    openUserDialog() {
      if (this.disabled) return
      this.entry == 'dialog' && (this.dialogVisible = true)

      this.initOptionList();
      this.initData();
      this.initSelectTagList()
    },
    // 根据range更新顶部的tab
    initOptionList(){
      this.optionList = [];

      this.range.forEach(ra => {
        this.optionList.push(USER_OPTION_LIST[ra])
      })

      this.currentOptionId = this.optionList.length > 0 ? this.optionList[0].id : '';

    },
    // 初始化组织数据
    initData() {
      this.selectOption(this.currentOptionId);
    },
    getSelectUserList(ids, isSetContent = false){
      if(!ids || ids.length == 0) return
      if(ids.length == 1 && ids[0] == '') return
      if(typeof ids[0] == 'object' || typeof ids[0] == 'undefined') return

      let params = [...ids].filter(id => {
        return id != CURRENT_USER_KEY
      })

      let selectTagList = [];
      let outTagList = [];
      // 只有当前用户
      if(ids[0] == CURRENT_USER_KEY && ids.length == 1) {
        this.selectTagList = [CURRENT_USER]
        this.outTagList = [CURRENT_USER]
        return
      }
      // 包含当前用户
      if(ids.indexOf(CURRENT_USER_KEY) > -1){
        selectTagList.push(CURRENT_USER)
        outTagList.push(CURRENT_USER)
      }

      this.$axios.post(this.$URL.business.user.userListByIds, {ids: params}).then(res => {
        res.data.forEach(user => user.name = user.realName)
        this.selectTagList = [...selectTagList, ...res.data]
        this.outTagList = [...outTagList, ...res.data]
        
        isSetContent && this.$emit('setContents', this.selectTagList)
      })
    },
    // 切换tab
    selectOption(id) {
      this.currentOptionId = id;

      if (id == 1) this.getOrgData();

      if (id == 2) this.getCurrentUser();

      if (id == 3) this.getRoleTree();

      if (id == 4) this.getAllUser();
      // 计算动态参数的选中状态
      if (id == 5) this.calculateDynamic()
    },
    // 检测是不是没有limit的状态
    checkAllEmpty(){
      let flag = true,
          limitKeyArr = Object.keys(this.limit)

      if(limitKeyArr.length == 0) return flag

      Object.keys(this.limit).forEach(key => {
        if(this.limit[key] && this.limit[key].length > 0) flag = false
      })

      return flag
    },
    // 部门相关操作
    getOrgData() {
      // 如果存在范围信息  筛选条件不需要做处理
      if(this.limit && this.limit.deptIds && (!this.checkAllEmpty() || this.limitType == 'byOrg') && this.useMode != 'filter'){
        // this.currentGroupUser && (this.currentGroupUser.list = []);
        this.getOrgByLimit()
        return;
      }

      if(this.orgs.length > 0) {
        let id = this.orgs[0] && this.orgs[0].id

        if(id) {
          this.$nextTick(() => {
            this.$refs.orgTree.setCurrentKey(id)
            this.treeNodeClick(this.orgs[0])
          })
        }
        return
      }
      
      this.orgs = [{ id: -1, title: '全部' }].concat(JSON.parse(JSON.stringify(this.$store.getters.deptList)))
      // 默认选中全部
      this.$nextTick(() => {
        this.$refs.orgTree.setCurrentKey(-1)
        this.treeNodeClick(this.orgs[0])
      })
    },
    getOrgByLimit(){
      let params = {
        deptIds: this.limit.deptIds
      }
      this.$axios.post(this.$URL.platform.user.org.listUserByCnd, params).then(res => {
        res.data.depts.forEach(item => item.title = item.name);
        this.orgs = res.data.depts;
        if(this.orgs.length > 0){
          this.$nextTick(() => {
            this.$refs.orgTree.setCurrentKey(this.orgs[0].id);
            this.treeNodeClick(this.orgs[0]);
          })
          this.initSelect = true
        }
      })
    },
    // 部门树点击
    treeNodeClick(data, node) {
      let groupId = data.id;

      if (this.allUserListData[groupId]) {
        this.currentGroupUser = this.allUserListData[groupId];
        this.setCheckedStatus(this.currentGroupUser.list);
      } else {
        let params = {orgId: groupId, active: true},
            url = this.$URL.platform.user.userOrg.getUserByOrg;
        this.$axios.get(url, { params }).then((res) => {
          if (res.code == 200) {
            this.allUserListData[groupId] = {
              selectAll: false,
              checkedUser: [],
              list: res.data
            };
            this.currentGroupUser = this.allUserListData[groupId];
            this.setCheckedStatus(this.currentGroupUser.list);
          }
        });
      }
    },
    setCheckedStatus(list){
      // 单选
      if(this.isSingel){
        let chooseUser = this.selectTagList.find(user => list.find(u => u.id == user.id))
        if(this.currentGroupUser) this.currentGroupUser.checkedUser = chooseUser ? chooseUser.id : [];
        if(this.currentRoleUser) this.currentRoleUser.checkedUser = chooseUser ? chooseUser.id : [];
      }else{
        let chooseUser = [];

        this.selectTagList.forEach(user => {

          let sameUser = list.find(u => u.id == user.id);

          if(sameUser) chooseUser.push(sameUser.id);
        })

        let isChooseAll = this.selectTagList.length == chooseUser.length && chooseUser.length != 0;
        if(this.currentGroupUser) {
          this.currentGroupUser.checkedUser = chooseUser;
          this.currentGroupUser.selectAll = isChooseAll;
        }
        if(this.currentRoleUser) {
          this.currentRoleUser.checkedUser = chooseUser;
          this.currentRoleUser.selectAll = isChooseAll;
        }
      }
    },
    // 获取角色列表
    getRoleTree(){
      // 如果存在范围信息
      if(this.limit && this.limit.roleIds && !this.checkAllEmpty()){
        this.getRoleByLimit();
        return;
      }

      this.roles = this.$store.getters.roleList
      if(!this.initRoleSelect && this.roles.length > 0) {
        this.setSelectRole(this.roles[0])
      }else{
        if(this.currentRoleUser && this.currentRoleUser.list) this.setCheckedStatus(this.currentRoleUser.list)
      }
    },
    getRoleByLimit(){
      let params = {
        deptIds: [],
        roleIds: this.limit.roleIds,
        userIds: []
      }
      this.$axios.post(this.$URL.platform.user.org.listUserByCnd, params).then(res => {
        res.data.roles.forEach(item => item.title = item.name);
        this.roles = res.data.roles;
        if(!this.initRoleSelect && this.roles.length > 0) this.setSelectRole(this.roles[0])
      })
    },
    setSelectRole(role){
      this.$nextTick(() => {
        this.$refs.roleTree.setCurrentKey(role.id);
        this.roleNodeClick(role);
        this.initRoleSelect = true;
      })
    },
    // 角色树点击
    roleNodeClick(data, node){
      if(this.allRoleUserListData[data.id]) { 
        this.currentRoleUser.list = this.allRoleUserListData[data.id].list;
        this.setCheckedStatus(this.currentRoleUser.list);
        return;
      }
      this.getUserByRoleId(data.id)
    },
    getUserByRoleId(id){
      let params = {
        ids: [id]
      }

      this.$axios.post(this.$URL.platform.role.userRole.listRoleUsers, params).then(res => {
          this.allRoleUserListData[id] = {
            list: res.data,
            checkedUser: []
          }
          this.currentRoleUser = Util.deepClone(this.allRoleUserListData[id]);

          this.setCheckedStatus(this.currentRoleUser.list);
      })
    },
    getAllUser(){
      // 如果存在范围信息
      if(this.limit && this.limit.userIds && !this.checkAllEmpty()){
        this.getUserByLimit();
        return;
      }

      let params = {orgId: -1, active: true},
          url = this.$URL.platform.user.userOrg.getUserByOrg;

      this.$axios.get(url, { params }).then(res => {
        res.data.forEach(user => {
          let findUser = this.selectTagList.find(tag => tag.id == user.id);
          user.__checked = this.isSingel ? (findUser ? findUser.id : '') : !!findUser
        })
        this.userList = res.data;
      })
    },
    getUserByLimit(){
      let params = {
        deptIds: [],
        roleIds: [],
        userIds: this.limit.userIds
      }
      this.$axios.post(this.$URL.platform.user.org.listUserByCnd, params).then(res => {
        res.data.users.forEach(user => {
          user.realName = user.name;
          let findUser = this.selectTagList.find(tag => tag.id == user.id);
          user.__checked = this.isSingel ? (findUser ? findUser.id : '') : !!findUser
        });
        this.userList = res.data.users;
        this.$forceUpdate()
      })
    },
    resetAll() {
      // this.initData();
      // 做一次判断  如果prop的list的length为0  重置内部
      if(this.selectList.length == 0){
        this.selectTagList = []
      }
      if(this.$refs.orgTree) {
        this.$refs.orgTree.setCurrentKey(null)
        Object.keys(this.$refs.orgTree.store.nodesMap).forEach(key => {
          this.$refs.orgTree.store.nodesMap[key].expanded = false
        })
      }

      this.allUserListData = {}
      if (this.currentGroupUser) {
        this.currentGroupUser = {
          checkedUser: [],
          list: [],
          isIndeterminate: false,
          selectAll: false,
        };
      }

      this.currentOptionId = 1;
      // 清空用户的选中状态
      this.userList.forEach(user => {
        if(user.id == CURRENT_USER_KEY){
          user.__checked = false;
        }
      })

      this.$forceUpdate();
    },
    // 清楚数据选中状态
    resetUserChecked(){
      this.dynamicList.forEach(item => {
        item.__checked = false;
      })
    },
    initSelectTagList() {
      let idList = []

      /*
        原有代码:现在查询时需要处理独特的${current_user}
        if(this.selectList[0] && this.selectList[0] == CURRENT_USER_KEY){
        idList = [this.$store.state.user.user.id]
        // 再执行一次emit方法  把数据再传出去
        this.$emit("selectChange", idList)
      }else{
        idList = [...this.selectList]
      }
      */
      idList = [...this.selectList]

      if(idList.length == 0) return
      this.getSelectUserList(idList)
    },
    getCurrentUser() {
      if (this.mineUserList.length == 0) {
        let userInfo = {}
        if(this.useMode == 'filter') {
          userInfo = CURRENT_USER
        } else {
          userInfo = this.$store.state.user.user
          userInfo.isSuper = !!this.$store.getters.isSuper
        }

        this.mineUserList = [userInfo]
      }
      // 如果被选中  需要回显

      this.userList = [...this.mineUserList];

      this.userList.forEach(user => {
        let sameUser = this.selectTagList.find(info => info.id == user.id)
        if(sameUser) {
          user.__checked = this.isSingel ? sameUser.id : true;
        }else{
          user.__checked = false;
        }
      })

      this.$forceUpdate()
    },
    //  输入框获取焦点需要长度变为100%  失去焦点如果输入框没值  还原数据
    blurFocus(event) {
      let { value } = event.target;
      if (!value) {
        this.isSearchFocus = false;
        if (this.currentOptionId == 4) this.selectOption(4);
      }
    },
    clearFocus() {
      this.isSearchFocus = false;
      this.$refs.searchRef.blur();
      if (this.currentOptionId == 4) this.selectOption(4);
    },
    handleSearchFocus() {
      if (this.searchStr == "") {
        this.userList = [];
      }
      this.isSearchFocus = true;
    },
    //  调用接口 搜索用户  需要注意的是需要判断这个用户是否在selectTagList中
    searchUser() {
      if(this.searchStr == '') return;
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
      let searchTimer = setTimeout(() => {
        this.getUserByName();
      }, 300);
    },
    getUserByName() {
      let params = {name: this.searchStr}

      if(this.limit){
        Object.keys(this.limit).forEach(key => {
          if(this.limit[key] && this.limit[key].length > 0 && key != 'dynamicIds'){
            params[key] = this.limit[key]
          }
        })
      }

      this.$axios.post(this.$URL.common.user.listUserByDefault, params)
        .then((res) => {
          if (res.code == 200) {
            //  先将数据和selectTagList进行对比  判断是否选中
            res.data.forEach((user) => {
              let sameUser = this.selectTagList.find(
                (item) => item.id == user.id
              );
              user.__checked = !!sameUser;
            });
            this.userList = [...res.data];
            this.$forceUpdate()
          }
        })
    },
    calculateDynamic(){
      let dynamicUser = this.selectTagList.find(user => user.id == CURRENT_USER_KEY)
      if(!dynamicUser && this.isSingel){
        this.dynamicList.forEach(item => {
          item.__checked = false;
        })
      }
    },
    selectAllUserChange(val) {
      this.currentGroupUser.checkedUser = val
        ? this.currentGroupUser.list.map((item) => item.id)
        : [];
      this.currentGroupUser.list.forEach((item) => {
        this.changeTag(item, val);
      });
    },
    changeTag({ realName, orgId, id, headImgUrl }, val) {
      if (this.isSingel) {
        //  每次选中的时候，把别的清空再选择
        this.clearSingelData({ realName, orgId, id, headImgUrl }, val)
        if(this.currentOptionId == 1) this.currentGroupUser.checkedUser = val 
        if(this.currentOptionId == 3) this.currentRoleUser.checkedUser = val        
      } else {
        // 确定全选状态
        if(this.currentOptionId == 1) {
          this.currentGroupUser.isIndeterminate =
            this.currentGroupUser.checkedUser.length <
              this.currentGroupUser.list.length &&
            this.currentGroupUser.checkedUser.length > 0;
          this.currentGroupUser.selectAll =
            this.currentGroupUser.checkedUser.length ==
            this.currentGroupUser.list.length;
        }

        if (val) {
          let hasThisTag = this.selectTagList.find((item) => item.id == id);
          if (hasThisTag) return;
          this.selectTagList.push({
            id: id,
            orgId: orgId,
            headImgUrl: headImgUrl,
            name: realName,
          });
        } else {
          this.selectTagList = this.selectTagList.filter(
            (item) => item.id != id
          );
        }
      }
    },
    changeTagRow(user, entry, val) {
      let checked = entry == 'group' ? this.currentGroupUser.checkedUser : this.currentRoleUser.checkedUser
      if (this.isSingel) {
        checked = user.id
      } else {
        const uIndex = checked.indexOf(user.id)
        if(uIndex > -1) checked.splice(uIndex, 1)
        else checked.push(user.id)

        val = uIndex == -1
      }

      this.changeTag(user, val)
    },
    clearSingelData({ realName, orgId, id, headImgUrl }, userId) {
      Object.keys(this.allUserListData).forEach((key) => {
        if (key != orgId) this.allUserListData[key].checkedUser = "";
      });
      this.selectTagList = [
        {
          id: id,
          orgId: orgId,
          name: realName,
          headImgUrl
        },
      ];
      this.userList.forEach((item) => {
        item.__checked = false;
      })
    },
    //  userList 选中和取消的操作  选中和取消  选中和取消都会影响到  组织列表里面的用户属性  需要同步处理
    changeUserStatus(user, isSelect) {
      if (this.isSingel) {
        this.clearSingelData(user, user.id);
        this.$nextTick(() => {
          user.__checked = user.id;
          this.$forceUpdate()
        });

      } else {
        let hasThisUser = this.selectTagList.find((item) => item.id == user.id);
        if (isSelect && !hasThisUser) {
          this.selectTagList.push({
            id: user.id,
            name: user.realName,
            headImgUrl: user.headImgUrl
          });
        } else {
          this.selectTagList = this.selectTagList.filter(
            (tag) => tag.id != user.id
          );
        }
      }
    },
    //  移除标签 组织内的用户必须移除  当前用户也必须移除  如果现在是搜索  也得移除搜索区域的内容
    removeTag({ id, orgId, name }, idx) {
      this.selectTagList.splice(idx, 1);

      // 组织内的用户必须移除
      let hasThisRoleData = this.allUserListData[orgId];
      if (hasThisRoleData) {
        if(this.isSingel) {
          hasThisRoleData.checkedUser = ''
        } else {
          hasThisRoleData.checkedUser = hasThisRoleData.checkedUser.filter(
            (userId) => id != userId
          );
          hasThisRoleData.isIndeterminate =
            hasThisRoleData.checkedUser.length < hasThisRoleData.list.length &&
            hasThisRoleData.checkedUser.length > 0;
          hasThisRoleData.selectAll =
            hasThisRoleData.checkedUser.length == hasThisRoleData.list.length;
        }
      }

      if (this.currentOptionId == 1) {
        if(this.isSingel) this.currentGroupUser.checkedUser = ''
        else {
          let uIndex = this.currentGroupUser.checkedUser.findIndex(u => u == id)
          this.currentGroupUser.checkedUser.splice(uIndex, 1)
        }
      }

      if (this.currentOptionId == 3) {
        if(this.isSingel) this.currentRoleUser.checkedUser = ''
        else {
          let uIndex = this.currentRoleUser.checkedUser.findIndex(u => u == id)
          this.currentRoleUser.checkedUser.splice(uIndex, 1)
        }
      }

      if (this.currentOptionId == 2 || this.isSearchFocus) {
        //  移除当前用户
        let removeUser = this.userList.find((user) => user.id == id);
        if (removeUser) removeUser.__checked = false;
      }
    },
    removeTagOuter(user, idx){
      this.removeTag(user, idx)
      this.outTagList = [...this.selectTagList]
      this.$emit("selectChange", this.selectTagList.map(user => user.id))
      this.$emit("confirm", this.selectTagList)
    },
    resetDialog(){
      this.dialogVisible = false
      this.isSearchFocus = false
      this.searchStr = ''
    },
    //  按钮操作
    cancel() {
      this.$emit("cancel")
      this.resetDialog()
      this.resetAll()
    },
    confirm() {
      this.resetDialog()
      this.outTagList = [...this.selectTagList]
      this.$emit("selectChange", this.selectTagList.map(user => user.id))
      this.$emit("confirm", this.selectTagList)
      this.resetAll()
    },
  },
};
</script>

<style scoped>
.member-group-container{
  width: 100%;
}
.member-group-container.inline{
  border: 1px solid #E6E6E6;
  border-radius: 4px;
}
.member-group-container.inline .user-area{
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: nowrap;
  overflow: hidden;
  padding: 0 30px 0 12px;
}

.member-group-container.inline .user-area .place{
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: #fff;
  /* z-index: 10; */
  top: 2px;
}
.member-group-container.inline .user-area .create-icon{
  position: absolute;
  right: 8px;
  top: 8px;
  margin-left: 0;
  /* z-index: 30; */
}


.member-group-container .user-area .create-icon{
  margin-left: 4px;
  margin-bottom: 4px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-size: cover;
  background-image: url(../../assets/img/create-icon.png);
  cursor: pointer;
}
.member-group-container .user-area .create-icon:hover{
  background-image: url(../../assets/img/create-icon-active.png);
}
.search-content{
  height: 260px;
  overflow-y: auto;
}
.member-group-container .menu-tree{
  margin-top: 0;
}
.search-content .user-head, .user-content .user-head{
  width: 26px;
  height: 26px;
  border-radius: 13px;
}
.search-content .user-head{
  margin-right: 6px;
}
.search-content .user-info-detail{
  display: flex;
  align-items: center;
}
.member-group-container .user-area {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  border-radius: 4px;
  min-height: 36px;
  line-height: 36px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}
.member-group-container .user-area .iconfont{
  font-size: 20px;
  line-height: 20px;
}

.member-group-container .user-area .user-item{
  position: relative;
  display: inline-block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADHElEQVRYR81XX0hTURj/nbtLW+GfqVO3iUUGbbWIegoqMYm664+9VSCW9Jg+VBg9FEQ+VA8REWQ9RiAEvVqkiJhk1Fsh3ryLlMg/S610mjr1zhPH3bs23d3ObKLn9Xy/7/ud3/m+73yHgHMdam+3WBwFFZTSMkLIHgqUALQgDCcjBOijlH4ihHQE/SNNb8rLgzyuSTIjr6K4KNRLgHABoJZk9hqhILDwlEB82Ox2+xJhEhKQeuTbILjOF9TAiuJOyw7PDSMfcQkc9nWViNT0DMDB/wr+D9wpErH6lcvVt9TfMgLHZHnvggkvATjTFFx3MySEcPK1x/Mx2m8MgRM+X4lK1berEDxCQiWh0jbX7ogSMQQkRWbB0yW7kYCdLW5Pqb4ZIZCWhOO9s6jEXCQQLrWQwovfJAg4ZytAWWYWBubm0DUzhcafo7zwcOeAyc1KdJGApHQ/BshFHg/FG8y46nDCZdkYY974azRFEvRJi3tXDWEdzuwoHONtMjeLirE/IzMu1/rBfrz/M8lzDqZBcNY/nEO8inyaAi94UPsyMlFfVGxoKs9Mo+77Nx5X2jXgDJF6uh+BkFoeVIU1F7WFdkPT36qKyt4vPK7CNpQ2MAU6KXCAB+XNtuKy3bg/BUIqzn7lJ0CAd0RS5CEADh4CZkHA823bwaog3moaH0PDsJ/HlW7jJ5LSrQLExIuqKbTjlDU3rvndoQF0TE7wumJ3EEqZwBazGQ82b12mQtf0NK718yeglgSMAP8V6EersuWjKi8/5qS3BvvxgbsEI1B/SknIYAIhqMyzLSNwzz+ItolACvKzbsiSkLMMM0wmHM2y4rwtHxaDJOydDaI1MI7WQABTC6HkZLQyTNqImOTHs3OQK4rJnQIYnp9H68R40tZMgDNJW3Gd3Ykj2VauwEuNmBr3f7Aqj7e0Vsy2Ej1Gza6dKwqug7y+zwZ47TFiu4me4yt2J6QVKpCoNGOe40UV0jEB82q1dCDRcWs6kjES2ji+qkOpSMTS6PF8fY3l+lVo43laPyYqCVVHj+N6rPX5NYtO5jX9nEYTWa3v+V+kklVgjicxTQAAAABJRU5ErkJggg==);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: left 2px center;
  margin-right: 6px;
  margin-bottom: 6px;
  font-size: 12px;
  padding:0 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 20px;
  color: #274048;
  background-color: #F7F7F7;
}
.member-group-container .user-area .user-item .el-icon-close{
  position: absolute;
  right: 4px;
  font-size: 14px;
  top: 3px;
  z-index: 10;
  color: #274048;
}
.member-group-container .user-area .iconfont:hover{
  color: #00BD89;
}
.member-group-container .user-area .tip {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #C4C4C4;
  font-size: 14px;
  text-align: left;
  padding-left: 15px;
}
._components_member_group {
  color: #274048;
}
._components_member_group .border-block {
  border-radius: 4px;
  border: 1px solid #dcdcdc;
}
.border-block.user-inner-container{
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
}
._components_member_group .user-list {
  min-height: 80px;
  padding: 8px;
}
.member-group-container .user-tag, ._components_member_group .user-tag{
  margin-right: 8px;
  margin-bottom: 8px;
}
._components_member_group .user-select-area {
  margin-top: 12px;
  min-height: 200px;
  overflow: hidden;
}
._components_member_group .user-select-area .top-bar {
  height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid #dcdcdc;
}
._components_member_group .user-select-area .option-item {
  position: relative;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 5px;
  margin: 0 10px;
  cursor: pointer;
  box-sizing: border-box;
}
._components_member_group .user-select-area .option-item:hover {
  color: #02BD89;
}
._components_member_group .user-select-area .option-item.active {
  border-bottom: solid 2px #02BD89;
  color: #02BD89;
}

._components_member_group .user-content .block {
  width: 50%;
  height: 260px;
  overflow-y: auto;
  box-sizing: border-box;
}
._components_member_group .user-content .block.choose{
  padding: 0 20px;
}
._components_member_group .user-content .info {
  border-right: 1px solid #dcdcdc;
}
._components_member_group .user-content .title {
  height: 40px;
  background: #ebf8f7;
  padding-left: 30px;
  line-height: 40px;
}

._components_member_group .choose {
  padding: 20px;
  padding-top: 0;
}

._components_member_group .choose .check-box {
  display: flex;
  height: 36px;
  color: #274048;
  justify-content: space-between;
  align-items: center;
}
._components_member_group .choose .check-box.select-all{
  cursor: pointer;
  margin: 0 -20px;
  padding: 0 20px;
}
._components_member_group .choose .check-box.select-all:hover{
  background: #E8F8F3;
}
._components_member_group .choose .check-box .icon {
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 13px;
  background: #02bd89;
  color: #fff;
  font-size: 12px;
  overflow: hidden;
}
._components_member_group .choose .check-box .name {
  font-size: 14px;
  margin-left: 10px;
}
._components_member_group footer {
  margin-top: 20px;
  text-align: right;
}
._components_member_group .search-content {
  padding: 0;
}
._components_member_group .search-content li label{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  cursor: pointer;
}
._components_member_group .search-content li:hover {
  background: #E8F8F3;
}
._components_member_group .single-radio-area {
  width: 100%;
}
._components_member_group .single-radio-area .check-box, 
._components_member_group .single-checkbox-area .check-box{
  cursor: pointer;
  margin: 0 -20px;
  padding: 0 20px;
}
._components_member_group .single-radio-area .check-box:hover, 
._components_member_group .single-checkbox-area .check-box:hover{
  background: #E8F8F3;
}

.filter-item-field-value .member-group-container .user-area .iconfont {
  line-height: 36px;
}
.filter-item-field-value .member-group-container .user-area .user-item-container {
  margin-bottom: 2px;
  margin-top: 2px;
}

.user-popover {
  width: 600px;
}
</style>

<style>
.member-group-container .user-area .user-item-container{
  display: inline-block;
  margin-right: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.border-block.user-inner-container .user-item-container{
  margin-right: 6px;
  margin-bottom: 6px;
}
.member-group-container.inline .user-area .user-item-container{
  margin-bottom: 0;
  float: left;
}
.member-group-container.inline .user-area .user-item-container{
  /* overflow: hidden; */
  display: flex;
  flex-wrap: nowrap;
}
.member-group-container.inline .user-area .user-item-container .user-item{
  margin-right: 4px;
}
</style>





