import URL from '@/interface/index'
import host from '@/assets/script/host'
import { get, postJson } from '@/assets/script/http'

const business = {
  // 批量新增业务实例数据
  addBusiness: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/action/BulkCreate`, params),
  addOuterBusiness: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/action/EaBulkCreate`, params),
  // 获取业务 实例数据列表
  getBusinessList: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/controller/List`, params),
  // 获取业务 实例button列表
  getBusinessListHeader: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/controller/ListHeader`, params),
  // 获取业务 引用数据列表
  getRelatedList: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/controller/RelatedList`, params),
  // 获取业务 关联查询数据列表
  getRecordRefList: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/controller/RecordRefList`, params),
  // 业务实例 删除
  deleteBusiness: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/action/BulkDelete`, params),
  // 业务实例 编辑
  editBusiness: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/action/BulkEdit`, params),
  // 获取联动业务数据 （联动字段查询具体业务数据）(单个/多个)
  getLinkList: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/controller/LinkList`, params),
  getDistinctList: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/controller/DistinctList`, params),
  // 获取映射字段业务数据 
  getMappingList: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/controller/MappingList`, params),
  // 获取统计字段数据 (它表)
  getCountFormula: (params) => postJson(`${host}/bizmeta/v1/object/calculate/service/rollupFormula`, params),
  // 获取业务实例数据列表（企联）
  getBusinessEaList: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/controller/EaList`, params),
  // 业务实例 删除（企联）
  deleteEaBusiness: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/action/EaBulkDelete`, params),
  // 业务实例 编辑（企联）
  editEaBusiness: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/action/EaBulkEdit`, params),
  // 业务实例 编辑 修改字段值
  editBusinessField: (params, apiName) => postJson(`${host}/bizmeta/v1/object/${apiName}/action/UpdateDataField`, params),
}
export default business