<template>
  <el-container>
    <el-aside width="280px"><my-aside/></el-aside>
    <el-container>
      <el-header class="my-header"><my-header /></el-header>
      <el-main class="my-main"><router-view /></el-main>
    </el-container>
  </el-container>
</template>

<script>
import MyHeader from './Header'
import MyAside from './Aside'
 
export default {
  name: 'app',
  components: {
    MyHeader,
    MyAside,
  },
}
</script>
<style scoped>
.my-header {
  border-bottom: 1px solid #E6EAEE;
}
</style>