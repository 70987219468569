//工作台接口
import host from '@/assets/script/host'

let workbench = {}
// 首页
workbench.main = {
  count: `${host}/bpm/work/flow/dashboard/count`, // 查询首页流程中心任务计数
  flowList: `${host}/bizmeta/application/describesWithFlow`, // 查询组织下所有有流程模板的业务表列表
  details: `${host}/bizmeta/dashboard/details`, // 首页配置
  setApp: `${host}/bizmeta/dashboard/app/update`, // 常用应用 设置
  setBus: `${host}/bizmeta/dashboard/describe/update`, // 快捷入口 设置
  setChart: `${host}/bizmeta/dashboard/pageComponent/update`, // 我的关注 设置
  getAppCharts(appId) { return `${host}/bizmeta/v1/app/${appId}/page/components` }, // 获取应用下可选的仪表盘图表
}
// 流程中心
workbench.workflow = {
  todo: `${host}/bpm/work/todo`, // 待办列表
  apply: `${host}/bpm/work/owner`, //我的申请
  draft: `${host}/bpm/work/viewDraft`, //我的草稿
  completed: `${host}/bpm/work/finished`, // 已办结
  taskInfo: `${host}/bpm/work/todoTaskInfo`, //创建类型的业务数据
  processHistory: `${host}/bpm/process/processHistory`, //流程操作日志
  trackhistory: `${host}/busmodel/workbench/trackhistory`, // 历史追溯
  approval: `${host}/bpm/work/approval`, //流程审批
  deleteList: `${host}/bpm/work/delete`, // 删除列表记录 
  busDetail: `${host}/bizmeta/objectData/bpm/detail`,// 除创建类型的 流程的业务数据及原因
  cc: `${host}/bpm/work/cc`, //抄送列表
  ccmask: `${host}/bpm/work/mask`, //标记已读抄送
  getAppWfBus(appId) { return `${host}/bizmeta/application/${appId}/flowDescribes` }, //获取流程模板的简单节点信息
  getAppWfBusNodes(apiName) { return `${host}/bpm/template/business/${apiName}/nodes` } //获取流程模板的简单节点信息
}
// 发起流程
workbench.process = {
  draftOperate: `${host}/busmodel/workbench/draftoperate`, // (修改, 删除)类型数据草稿
  delete: `${host}/busmodel/workbench/delete`, //删除类型
  draftSubmit: `${host}/bpm/work/draftProcess/start`, //草稿id 发起流程
  draftUpdate: `${host}/bizmeta/objectData/pre/update`, //修改草稿
}

export default workbench