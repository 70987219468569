//系统管理接口
import host from '@/assets/script/host'

let industry = {
  // 2.0迭代4 新增接口
  industry: {
    create: `${host}/upm/industryResource/create`, // 创建企联客户
    update: `${host}/upm/industryResource/update`, // 修改企联客户
    remove: `${host}/upm/industryResource/remove`, // 删除企联客户
    pages: `${host}/upm/industryResource/pages`, // 企联客户,服务商 列表
    detailProvider: `${host}/upm/industryResource/detailProvider`, // 企联服务商 详情
    detail: `${host}/upm/industryResource/detail`, // 企联客户 详情

    awardApp: `${host}/bizmeta/eaLink/copyToCustomer`, // 授予应用
    updateCustomerProcessor: `${host}/bizmeta/eaLink/updateCustomerProcessor`, // 修改 客户设置负责人
    updateProviderProcessor: `${host}/bizmeta/eaLink/updateProviderProcessor`, // 修改 服务商设置负责人

    appList: `${host}/bizmeta/eaLane/appList`, // 首页-企联应用
    optionList: `${host}/bizmeta/eaLane/optionList`, // 首页-企联应用下的业务项
    customerList: `${host}/bizmeta/eaLane/customerList`, // 首页-企联应用-业务项被授予的客户信息
  },
  manage: {

    providerAppList: `${host}/bizmeta/eaManage/providerAppList`, // 服务商授予应用的可选列表
    providerOptionList: `${host}/bizmeta/eaManage/getProviderAppOptionsMap`, // 服务商授予应用下服务项的可选列表

    customerAppList: `${host}/bizmeta/eaManage/customerAppList`, // 客户被授予的应用列表
    customerOptionList: `${host}/bizmeta/eaManage/getCustomerAppOptionsMap`, // 客户被授予应用下服务项的列表
  },
  app: {
    list: `${host}/bizmeta/ea/app/manager/list`, // 企联应用列表
    detail: `${host}/bizmeta/ea/app/manager/detail`, // 企联应用详情
    
    sort: `${host}/bizmeta/ea/app/update/sort`, // 企联应用次序调整

    save: `${host}/bizmeta/ea/app/save`, // 企联应用创建
    update: `${host}/bizmeta/ea/app/update`, // 企联应用修改
    delete: `${host}/bizmeta/ea/app/delete`, // 企联应用删除
    active: `${host}/bizmeta/ea/app/active`, // 企联应用停用，启用
  },
  section: {
    create(appId){ return `${host}/bizmeta/ea/${appId}/section/save` }, // 企联应用分组创建
    update(appId){ return `${host}/bizmeta/ea/${appId}/section/update` }, // 企联应用分组修改
    delete(appId){ return `${host}/bizmeta/ea/${appId}/section/delete` }, // 企联应用分组删除
    sort(appId){ return `${host}/bizmeta/ea/${appId}/section/update/sort` }, // 企联应用分组业务项次序调整
  },
  option: {
    create: `${host}/bizmeta/option/create`, // 业务项创建
    update: `${host}/bizmeta/option/update`, // 业务项修改
    delete: `${host}/bizmeta/option/delete`, // 业务项删除
    publish: `${host}/bizmeta/option/publish`, // 业务项发布
  },
  business: {
    create: `${host}/bizmeta/v1/object/describe/service/createEADescribe`, // 业务项 模板创建
    update: `${host}/bizmeta/v1/object/describe/service/updateEADescribe`, // 业务项 模板修改
    detail: `${host}/bizmeta/v1/object/describe/service/detailEADescribe`, // 业务项 模板详情

    updateName: `${host}/bizmeta/v1/object/describe/service/updateEADesName`,

    getObjectDetail(apiName) {
      return `${host}/bizmeta/v1/object/${apiName}/controller/EaDetail`
    },
    generateTemplate: `${host}/bizmeta/v1/object/bulkimport/generateEaTemplate`,
    importData: `${host}/bizmeta/v1/object/bulkimport/importEaData`,
    exportData(apiName){
      return `${host}/bizmeta/v1/object/${apiName}/eaExport`
    },
  },
  // 行业管理 旧接口（需 按需删除）
  clients: {
    checkCodeAndName: `${host}/upm/tenant/checkCodeAndName`, //验证名称
  },
  resources: {
    packageAssociationGet: `${host}/bizmeta/appPackage/packageAssociationGet`, // 应用包关联工作组列表
  },
  proxy: {
    importDescribe: `${host}/bizmeta/v1/object/bulkimport/importSubmitOrder`
  }

}

export default industry