import Vue from 'vue'

import 'normalize.css/normalize.css'

import App from './App.vue'
import axios from '@/axios/service'
import URL from '@/interface/index'
import router from './router'

import store from '@/store'

import ElementUI from 'element-ui'
import OwMessage from '@/elementui/message'
import MemberGroup from '@/components/MemberGroup/Common'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/theme/index.css'

import '@/assets/script/polyfill'
import utils from '@/assets/script/utils'

import '@/assets/css/element.override.css'
import '@/assets/css/common.css'
import '@/assets/css/mb-common.css'
import '@/modules/saas/assets/css/platform.css'
import '@/modules/saas/assets/css/workbench.css'
//************富文本编辑器css****************
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(quillEditor)
/*****************************************/
import '@/assets/css/fonts/iconfont.css'

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

import Mixin from '@/assets/script/root.mixins'
import '@/assets/script/plugins'

//sentry日志
import SentryReport from '@/sentry/report'
let sentry = SentryReport.getInstance({})

Vue.use(ElementUI)
Vue.use(VXETable)
Vue.mixin(Mixin)

Vue.component('MemberGroup', MemberGroup)

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$utils = utils
Vue.prototype.$URL = URL
// 重写element-ui 全局方法$message方法
Vue.prototype.$message = OwMessage
Vue.prototype.$sentry = sentry

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')