import api from '@/modules/saas/api'

const state = {
  currentUser: {
    menus: []
  },
  //aside折叠
  asideCollapse: false,
  newMessages: [],
  oldMessages: [],
  areaList: [],
  deptList: [],
  roleList: [],
  appUrlFlag: false,
  diffTimeStamp: null, // 服务器时间 - 本地时间 差值
}

const mutations = {
  switchAsideCollapse: (state) => {
    state.asideCollapse = !state.asideCollapse
  },    
  setNewMessages: (state, messages) => {
    state.newMessages = messages
  },
  setOldMessages: (state, messages) => {
    state.oldMessages = messages
  },
  readMessage: (state, messageId) => {
    const t = Object.prototype.toString.call(messageId)
    if(t == '[object String]' || t == '[object Number]') {
      let map = state.newMessages.find(m => { return m.id == messageId })
      if(!map) return
      map.checked = false
      map.isRead = 1
      //放到已读中并在新消息中剔除
      state.oldMessages.unshift(map)
      delMessage(state, {type: 'new', id: messageId })            
    } else if(Array.isArray(t)){
      messageId.forEach(id => {
        let map = state.newMessages.find(m => { return m.id == id })
        if(map) return

        map.checked = false
        map.isRead = 1
        //放到已读中并在新消息中剔除
        state.oldMessages.unshift(map)
        delMessage(state, {type: 'new', id: id })
      })
    }
  },    
  delMessage: delMessage,    
  setUser: (state, currentUser) => {
    state.currentUser = currentUser
  },
  setAreaList: (state, areaList) => {
    state.areaList = areaList
  },
  setDepts: (state, depts) => {
    state.deptList = depts
  },
  setRoles: (state, roles) => {
    state.roleList = roles.map(r => { 
      r.roleName = r.title
      r.name = r.title
      return r
    })
  },
  setAppUrlFlag: (state, flag) => {
    state.appUrlFlag = flag
  },
  setDiffTimeStamp: (state, diffTimeStamp) => {
    state.diffTimeStamp = diffTimeStamp
  },
  
}

function delMessage(state, data){
  const t = Object.prototype.toString.call(data.id)
  if (t == '[object String]' || t == '[object Number]') {
    let index = state[data.type + 'Messages'].findIndex(m => { return m.id == data.id })
    state[data.type + 'Messages'].splice(index, 1)
  } else if(Array.isArray(t)) {
    data.id.forEach(id => {
      let index = state[data.type + 'Messages'].findIndex(m => { return m.id == id })
      state[data.type + 'Messages'].splice(index, 1)
    })
  }
}

const actions = {
  setUser: (context, currentUser) => {
    context.commit('setUser', currentUser)
  },
  setAreaList({ commit }) {
    return new Promise((resolve, reject) => {
      api.user.getAreaList().then(res => {
        let areaList = Object.prototype.toString.call(res.data) == '[object Array]' ? res.data : res.data.data
        commit('setAreaList', areaList)
        resolve(areaList)
      })
    })
  },
  setDepts: (context, depts) => {
    context.commit('setDepts', depts)
  },
  setRoles: (context, roles) => {
    context.commit('setRoles', roles)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}