import Vue from 'vue'

import PerfectScrollbar from 'perfect-scrollbar';
import "perfect-scrollbar/css/perfect-scrollbar.css";

const preventReClick = {
  inserted (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
            el.disabled = false
        }, binding.value || 1500)
      }
    })
  }
}
// 防重复点击
Vue.directive('prevent_re_click', preventReClick)


/**
 * @description 自动判断该更新PerfectScrollbar还是创建它
 * @param {HTMLElement} el - 必填。dom元素
 */
const el_scrollBar = (el) => {
  if (el._ps_ instanceof PerfectScrollbar) el._ps_.update();
  else {
    el._ps_ = new PerfectScrollbar(el, {
      // 要配什么属性自己看官网，此处不会解释任何其配置项的含义
      suppressScrollX: true,
    });
  }
};

const scrollBar = {
  //使用inserted钩子函数（初次创建dom）获取使用自定义指令处的dom
  inserted(el, binding, vnode) {
    const rules = ["fixed", "absolute", "relative", "sticky"];
    if (!rules.includes(window.getComputedStyle(el, null).position)) {
      console.error(`perfect-scrollbar所在的容器的position属性必须是以下之一：${rules.join("、")}`)
    }
    el_scrollBar(el);
  },
  //更新dom的时候
  componentUpdated(el, binding, vnode, oldVnode) {
    //容器大小变动时,不知原因造成容器class="ps"丢失,导致鼠标移入容器时,无法展示滚动条,只有在滚轴滚动时才有
    //解决方式:手动增加class="ps"
    !el.classList.contains('ps') && el.classList.add('ps')
    //vnode.context其实就是vue实例，这里其实无需实例也直接用Vue的静态方法
    //故而也可以写成Vue.nextTick
    vnode.context.$nextTick(() => el_scrollBar(el))
  }
}
// 自定义滚动条
Vue.directive("scrollBar", scrollBar)
