const application = [{
    name: 'object_list_default', 
    path: '/app/:appId', 
    component: () => import('@/modules/saas/views/application/app_index')
  }, {
    name: 'object_list', 
    path: '/app/:appId/:busApiName', 
    component: () => import('@/modules/saas/views/application/app_index')
  }, {
    name: 'object_list_group', 
    path: '/app/:appId/:busApiName/:groupId', 
    component: () => import('@/modules/saas/views/application/app_index')
  }, {
    name: 'object_list_group_view', 
    path: '/app/:appId/:busApiName/:groupId/:viewId', 
    component: () => import('@/modules/saas/views/application/app_index')
  }, {
    name: 'customApp',
    path: '/eaapp/customer/:appId',
    component: () => import('@/modules/saas/views/application/cooperation/custom/')
  }, {
    name: 'providerApp',
    path: '/eaapp/provider/:appId',
    component: () => import('@/modules/saas/views/application/cooperation/provider/')
  }, {
    name: 'space',
    path: '/appspace/:appId/:apiName/:dataId/:childApiName/:groupId/:viewId',
    component: () => import('@/modules/saas/views/application/business/Space/index')
  }]

export default application