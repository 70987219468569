var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c("div", { staticClass: "header-left" }, [_c("region-change")], 1),
    _c("div", { staticClass: "header-right" }, [
      _vm.isIndustry
        ? _c(
            "div",
            { staticClass: "icon-area", on: { click: _vm.toIndustry } },
            [
              _c("i", { staticClass: "iconfont mb-icon-assist-center" }),
              _vm._v(" \n      企联协作\n      "),
              _c("i", { staticClass: "iconfont mb-icon-jump" }),
            ]
          )
        : _vm._e(),
      _vm.isSuper
        ? _c("div", { staticClass: "icon-area", on: { click: _vm.toBuild } }, [
            _c("i", { staticClass: "iconfont mb-icon-design-center" }),
            _vm._v(" \n      构建中心\n      "),
            _c("i", { staticClass: "iconfont mb-icon-jump" }),
          ])
        : _vm._e(),
      _vm.isIndustry || _vm.isSuper
        ? _c("div", { staticClass: "split-line" })
        : _vm._e(),
      _c("div", { staticClass: "icon" }, [_c("MobileIcon")], 1),
      _vm.isSuper
        ? _c(
            "div",
            { staticClass: "icon" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "icon-item",
                  attrs: {
                    effect: "dark",
                    content: "系统管理",
                    placement: "bottom",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-setting",
                    on: {
                      click: function ($event) {
                        return _vm.$_goto("/system")
                      },
                    },
                  }),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "icon" }, [_c("MessageIcon")], 1),
      _c("div", { staticClass: "avatar" }, [_c("AvatarIcon")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }