import URL from '@/interface/index'
import { get, postJson, postStringify } from '@/assets/script/http'

const platform = {
  fetchDictTypes: () => get(URL.platform.system.dictionary.catalog),
  fetchDict: pcode => get(URL.platform.system.dictionary.list, { pcode }),  
  // 根据角色获取人员
  listUserRoleByCnd: params => get(URL.platform.role.userRole.listUserRoleByCnd, params),
  // 流程模板列表
  fetchWfTmplList: params => postJson(URL.platform.workflow.template.list, params),
  // 流程模板详情
  fetchWfTmplDetail: id => get(`${URL.platform.workflow.template.detail}?bpmTemplateId=${id}`)
}

export default platform