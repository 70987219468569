<template>
  <el-drawer title="消息提醒" :visible.sync="visible" :append-to-body='true' 
    direction="btt" custom-class="mb-message-mgr" size="80%" @open="onOpen">
    <section class="message-tab">
      <div v-for="(mt, tIndex) in messageTypes" :key="tIndex" class="message-type" 
        :class="{'active': mt.active}"
        @click="activeType(mt)">
        <i class="message-type-icon" :class="mt.icon"></i>
        <div v-if="mt.unRead" class="new-flag"></div>
        <span class="message-type-label">{{mt.label}}</span>
      </div>
    </section>
    <section class="message-box">
      <el-tabs v-model="activeName" class="mb-tab-container">
        <el-tab-pane label="未读" name="new">
          <message-list :datas="$store.state.common.newMessages" :dataTotal="newTotal" :msgType="'new'"
            @pagingChange="getNewMessages" @rowClick="rowClick" @read="reloadMessage" @delete="reloadMessage"></message-list>
        </el-tab-pane>
        <el-tab-pane label="已读" name="all">
          <message-list :datas="$store.state.common.oldMessages" :dataTotal="oldTotal" :msgType="'old'"
            @pagingChange="getOldMessages" @rowClick="rowClick" @read="reloadMessage" @delete="reloadMessage"></message-list>
        </el-tab-pane>
      </el-tabs>
    </section>
    <detail ref="msgDetail"></detail>
  </el-drawer>
</template>
<script>
import MessageList from './MessageList'
import Detail from './Detail'

export default {
  components: {
    MessageList,
    Detail
  },
  data() {
    return {
      visible: false,
      messageTypes: [
        { label: '工作消息', icon: 'iconfont mb-icon-saas-workmsg', type: 'work', active: true, unRead: false },
        //{ label: '系统消息', icon: 'iconfont mb-icon-saas-systemmsg', type: 'system', unRead: false }
      ],
      activeName: 'new',
      newTotal: 0,
      oldTotal: 0,
      
    }
  },
  methods: {  
    onOpen() {
      this.getNewMessages(1, 10)
      this.getOldMessages(1, 10)
    },
    showMessage() {
      this.visible = true
    },
    reloadMessage(msgType) {
      let handleMap = {
        'new': this.getNewMessages,
        'old': this.getOldMessages
      }

      handleMap[msgType](1, 10)
    },
    getNewMessages(pageIndex, pageSize) {
      let req = {
        current: pageIndex,
        size: pageSize,        
        isRead: 0
      }

      this.$axios.post(this.$URL.common.message.getMessages, req)
        .then(res => {
          res.data.records.forEach(r => { 
            r.checked = false
            r.hover = false
          })

          this.newTotal = res.data.total
          this.messageTypes[0].unRead = res.data.records.any(r => { return r.isRead == 0 })          
          this.$store.commit('common/setNewMessages', res.data.records)
        })
    },
    getOldMessages(pageIndex, pageSize) {
      let req = {
        current: pageIndex,
        size: pageSize,        
        isRead: 1
      }

      this.$axios.post(this.$URL.common.message.getMessages, req)
        .then(res => {
          res.data.records.forEach(r => { 
            r.checked = false
            r.hover = false
          })

          this.oldTotal = res.data.total
          this.messageTypes[0].unRead = res.data.records.any(r => { return r.isRead == 0 })
          this.$store.commit('common/setOldMessages', res.data.records)
        })
    },
    activeType(type) {
      this.messageTypes.forEach(t => { t.active = false })
      type.active = true
    },
    rowClick(data) {
      //this.$refs.msgDetail.init(data)
    }
  }
}
</script>
<style scoped>
.message-tab{
  padding: 20px 0;
  position: fixed;
  top: calc(20% + 60px + 20px);
  left: calc(50% - 450px - 180px - 35px); /* 距离消息左侧35px左右 */
  width: 180px;
  height: 160px;
  background: #fff;
  box-shadow:0px 2px 4px 0px rgba(101,101,101,0.5);
  border-radius:4px;
}
.message-box{
  width: 900px;
  min-height: calc(100% - 40px);
  overflow: auto;
  padding: 20px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #fff;
  box-shadow:0px 2px 4px 0px rgba(101,101,101,0.5);
  border-radius:4px;
}
.message-tab .new-flag{
  top: 18px;
  left: 60px;
  background: #F5292D;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.message-type{
  position: relative;
  text-align: center;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  border-left: 5px solid #fff;
}
.message-type.active{
  border-color: #00BD89;
}
.message-type .message-type-icon{
  margin-right: 10px;
}
.message-type.active .message-type-icon,
.message-type.active .message-type-label{
  color: #00BD89;
  font-size: 14px;
}
</style>
<style>
.message-box .el-tabs__content{
  padding-top: 0;
}
.mb-message-mgr{
  color: #161616;
}
.mb-message-mgr .el-drawer__header{
  text-align: left;
  box-sizing: border-box;
}
.mb-message-mgr .el-drawer__body{
  background: #F3F5F8;  
  position: relative;
  overflow: auto;
}
</style>