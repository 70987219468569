import host from '@/assets/script/host'

// 1.0 设计器相关接口， （待确认后，需去除）
const apps = {
  application: {
    getAppById: `${host}/bizmeta/application/get`,
    listNoPackage: `${host}/bizmeta/application/listNoPackage`,
    create: `${host}/bizmeta/v1/object/describe/service/createApplicationDescribe`,
    delete: `${host}/bizmeta/application/delete`,
    update: `${host}/bizmeta/application/update`,
    createRelation: `${host}/bizmeta/application/createRelation`,
    updateBusiness: `${host}/bizmeta/v1/object/describe/service/updateApplicationDescribe`,    
    appWorkflowList: `${host}/bpm/template/app/list`,
    appWorkflowCount: `${host}/bpm/work/flowCenter/dashboard/count`
  },
  appPackage: {
    checkName: `${host}/bizmeta/appPackage/validName`,
    create: `${host}/bizmeta/appPackage/create`,
    update: `${host}/bizmeta/appPackage/update`,
    delete: `${host}/bizmeta/appPackage/delete`,
    get: `${host}/bizmeta/appPackage/get`,
    getPackagesByIds: `${host}/bizmeta/appPackage/getPackagesByIds`,
    getApps: `${host}/bizmeta/appPackage/getApps`,
    lists: `${host}/bizmeta/appPackage/lists`,
    getPackagesInfo: `${host}/bizmeta/appPackage/getPackagesInfo`,
  },  
}

export default apps