import { MessageBox } from 'element-ui'
import api from '@/modules/saas/api'

import { getToken, setToken, removeToken, setUser, setRegionConfig } from '@/modules/saas/assets/script/auth'

import siteConfig from '@/config/site.config'
import { domainHost } from '@/assets/script/host.js'

let userInfo = {}
const user = {
  namespaced: true,
  state: {
    isSuper: 0,
    token: getToken(),
    tenant: '',
    org: {},
    user: {},
    roles: [],
    depts: [],
    regionList: [],
    regionConfig: {}, //组织版本信息
    inviteInfo: {},
    domain: {}, // 组织域名信息
  },
  mutations: {
    SET_ORG: (state, org) => {
      state.org = org;
    },
    SET_ISSUPER: (state, isSuper) => {
      state.isSuper = isSuper
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_TENANT: (state, tenant) => {
      state.tenant = tenant
    },
    SET_USER_REALNAME: (state, realName) => {
      state.user.realName = realName
    },
    SET_INVITE_LINK: (state, link) => {
      state.inviteInfo.link = link
    },
    SET_INVITE_STATUS: (state, status) => {
      state.inviteInfo.status = status
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_USER_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USER_DEPTS: (state, list) => {
      state.depts = list
    },
    SET_HEADIMGURL: (state, headImgUrl) => {
      state.user.headImgUrl = headImgUrl
    },
    SET_REGION: (state, region) => {
      state.regionList = region
    },
    SET_CURRENT_REGION_CODE: (state, regionCode) => {
      state.currentRegion = regionCode
    },
    SET_REGION_CONFIG: (state, regionConfig) => {
      state.regionConfig = regionConfig
    },
    SET_DOMAIN: (state, domain) => {
      state.domain = domain
    },
  },
  actions: {
    // user login
    login({ commit }) {
      return new Promise((resolve, reject) => {
        // 登录成功
        let token = localStorage.getItem('login-token')
        let tenant = localStorage.getItem('login-tenant')
        let regionCode = localStorage.getItem('login-region')
        commit('SET_TOKEN', token)
        commit('SET_TENANT', tenant)
        commit('SET_CURRENT_REGION_CODE', regionCode)
        setToken(token)

        resolve()
      })
    },
    // get user info
    getInfo({ commit, dispatch }) {
      return new Promise((resolve, reject) => {

        // 如果没有region  做特殊处理
        let region = localStorage.getItem('login-region');

        if(!region){
          // MessageBox({ 
          //   message: `用户无公司，请联系管理员！`, 
          //   title: '提示', 
          //   type: 'error', 
          //   showConfirmButton: true, 
          //   showCancelButton: false, 
          //   confirmButtonText: '确定',
          //   callback: (action) => {
          //     let url = location.protocol + '//' + domainHost
          //     location.href = url + siteConfig.loginUrl
          //   },
          // })
          
          // return
        }
        
        api.user.getInfo()
          .then(res => {
            userInfo = setUser(res, commit, dispatch, reject)

            // 获取组织信息
            api.user.getRegionList().then(res => {
              commit('SET_REGION', res.data)
              resolve(userInfo)
            })
          }).catch(error => {
            reject(error)
          })
      })
    },
    getRegionConfig({ commit, dispatch }) {
      api.user.getRegionConfig()
        .then(res => {
          setRegionConfig(res, dispatch, commit)
        })      
    },
    // user logout
    logout({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        api.user.logout(state.token).then((res) => {
          if (res.code == 200) {
            commit('SET_TOKEN', '')
            commit('SET_USER_ROLES', [])

            localStorage.clear()
            sessionStorage.clear()
 
            resolve()
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // remove token
    resetToken({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_TENANT', '')
        commit('SET_USER_ROLES', [])
        removeToken()
        resolve()
      })
    },
  }
}

export default user