var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "mb-message-detail",
      attrs: {
        title: "内容",
        "append-to-body": true,
        visible: _vm.visable,
        width: "400px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visable = $event
        },
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [_vm._v(_vm._s(_vm.data.content))]),
      _c("div", { staticClass: "date" }, [_vm._v(_vm._s(_vm.data.date))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }