<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import siteConfig from '@/config/site.config'
import { domainHost } from '@/assets/script/host.js'
import { setLoginData } from '@/modules/saas/assets/script/auth'

export default {
  name: "app",
  data () {
    return {
      show: false
    }
  },
  mounted() {
    this.initAppUrlFlag()
    // 因为域名改变 需要重新设置参数
    setLoginData()
    
    // 下次自动登录功能需要优化 暂时屏蔽
    // let isRem = localStorage.getItem('login-remember'),
    //     isLogin = sessionStorage.getItem('login-status') || ''

    // if(isRem!= 1 && isLogin != 1){
    //   let url = location.protocol + '//' + domainHost
    //   let pathName = location.pathname
      
    //   location.href = url + siteConfig.loginUrl + (pathName.length > 1 ? '?r_url='+pathName : '')

    //   return
    // }

    let tenant = localStorage.getItem("login-tenant") || ""
    let token = localStorage.getItem("login-token") || ""

    if (!tenant && token && token !== 'null') {
      this.$alert("用户无公司，请联系管理员！", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          let url = location.protocol + '//' + domainHost
          let pathName = location.pathname
          location.href = url + siteConfig.loginUrl + (pathName.length > 1  ? '?r_url='+pathName : '')
        },
      });
    }

    this.$store.dispatch("user/login")
    this.$store.dispatch("common/setAreaList")
    
    let joinToken = this.getUrlQuery('token')
    // 拦截计入组织操作
    if(joinToken) return

    this.initAppList()
    this.initRegionConfig()
    this.getDepts()
    this.getRoles()
  },
  methods: {
    initAppUrlFlag() {
      const pathname = location.pathname
      const hash = location.hash

      if((pathname.length > 1 && pathname.indexOf('/apps/') > -1) && (!hash || hash =='#/')) {
        this.$store.commit('common/setAppUrlFlag', true)
      }
    },
    initAppList() {
      let params = { 
        includeProjectAuth: true,
        describeListScope: 'include_all_obj'
      }

      this.$axios.all([
        this.$axios.post(this.$URL.business.business.appList, params),
        this.$axios.get(this.$URL.industry.industry.appList),
      ]).then(res => {
        let appList = res[0].data
        if(Array.isArray(appList)) {
          appList.forEach((app) => {
            let style = app.style ? JSON.parse(app.style) : {"color":"#FCF0E9","iconColor":"#FF9966","icon":"mb-icon-app-icon-1"}
            let { icon, color, iconColor } = style
            
            app.$_style = {
              icon,
              color: iconColor,
              backgroundColor: color,
            }
          })
        } else appList = []

        let industryAppList = res[1].data
        if(Array.isArray(industryAppList)) {
          industryAppList = industryAppList.map(d => {
            let style = d.style ? JSON.parse(d.style) : {"color":"#FCF0E9","iconColor":"#FF9966","icon":"mb-icon-app-icon-1"}
            let { icon, color, iconColor } = style
            return {
              id: d.appId,
              name: d.appName,
              style: d.style,
              $_style: {
                icon,
                color: iconColor,
                backgroundColor: color,
              },
              type: d.type
            }
          })
        } else industryAppList = []

        this.$store.commit("business/SET_APP_LIST", appList)
        this.$store.commit("business/SET_Industry_APP_LIST", industryAppList)
      })
    },
    initRegionConfig() {
      this.$store.dispatch("user/getRegionConfig")
    },
    getDepts(){
      this.$axios.post(this.$URL.platform.user.org.assignedTree, {ids: []})
        .then((res) => {
          let depts = []
          if (res.code == 200) depts = res.data

          this.$store.commit('common/setDepts', depts)
        })
    },
    getRoles() {
      this.$axios.post(this.$URL.platform.role.role.tree, {ids: []})
        .then(res => {
          let roles = []
          if (res.code == 200) roles = res.data

          this.$store.commit('common/setRoles', roles)
        })
    },
    getUrlQuery(paraName){
      var url = document.location.toString()
      var arrObj = url.split("?")
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&")
        var arr
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=")
          if (arr != null && arr[0] == paraName) {
            return arr[1]
          }
        }
        return ""
      } else {
        return ""
      }
    },

  }
}
</script>