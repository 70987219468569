//公共接口
import host from '@/assets/script/host'
import { WXCbHost } from '@/assets/script/host'

let common = {}

common.login = {
  checkByMail: `${host}/upm/public/checkByMail`,
  register: `${host}/upm/public/register`,
  registerUpdatePwd: `${host}/upm/public/registerUpdatePwd`,
  getToken: `${host}/upm/public/getToken`,
  activeNewUser: `${host}/upm/public/activeNewUser`,
  linkRegisterUser: `${host}/upm/public/linkRegisterUser`,
  active: `${host}/upm/public/active`,
  updateEmailActive: `${host}/upm/public/updateEmailActive`,
  userActiveBySms: `${host}/upm/public/userActiveBySms`,
  userActive: `${host}/upm/public/userActive`,
  
  inviteValid: `${host}/upm/public/invite/valid`, // 邀请页校验信息
  join: `${host}/upm/public/join`, // 邀请页加入
}

common.wx = {
  wxCallBackLogin: `${WXCbHost}/upm/anno/wxCallBackLogin`,
  wxCallBackBind: `${WXCbHost}/upm/anno/wxCallBackBind`,
  wxBindedSign: `${host}/upm/anno/dashboard`,
  wxBind: `${host}/upm/anno/wxBind`,
  wxUnbind: `${host}/upm/sysUser/unBind`
}

common.base = {
  registerUser: `${host}/`, // 产品用户注册
  registerSendMessage: `${host}/`, // 注册短信发送
  listArea: `${host}/upm/public/listArea`, //获取地区信息
  sendSMS: `${host}/upm/public/sendSMS`, // 获取验证码
  captcha: `${host}/upm/anno/captcha`, //校验图片
  captchaCheck: `${host}/upm/anno/captcha/check`,//校验图片处理
  forgetPwd: `${host}/upm/public/forgetPwd`,// 忘记密码
  switchRegion: `${host}/upm/region/switch`, //  切换组织
  getSummary: `${host}/upm/region/profile/summary`,  // 指标信息聚合查询
  sendUpdatePhoneSMS: `${host}/upm/public/sendUpdatePhoneSMS`,  //  发送修改手机号短信
  verifyUpdatePhoneSMS: `${host}/upm/public/verifyUpdatePhoneSMS`,
  checkPhonePwd: `${host}/upm/sysUser/checkPhonePwd`,
  checkEmailByMobile: `${host}/upm/sysUser/checkEmailByMobile`,
  getActiveUserToken: `${host}/upm/region/getActiveUserToken`,
  joinRegion: `${host}/upm/region/join`,
  inviteLinkControl: `${host}/upm/region/inviteLinkControl`,
  smsCode: `${host}/upm/public/sms/code`, // 获取验证码 手机
  emailCode: `${host}/upm/public/email/code`, // 获取验证码 邮箱
}

common.user = {
  login: `${host}/upm/anno/signIn`, //登录
  logout: `${host}/upm/tenant/logout`, //注销
  getCurrentUser: `${host}/upm/functionAuth/userAuths`, //获取当前用户信息(权限)
  getRegionConfig: `${host}/upm/region/profile/config/info`, //用户配置信息
  getUser: `${host}/upm/tenant/getUserTenant`, //获取当前用户信息(个人中心)
  updateUser: `${host}/upm/sysUser/update`, //修改当前用户信息(个人中心)
  updatePhone: `${host}/upm/sysUser/updatePhone`, //修改电话
  updatePassword: `${host}/upm/sysUser/updatePassword`, //修改密码
  // updateEmail: `${host}/upm/tenant/preUpdateEmail`, //修改邮箱
  checkPassword: `${host}/upm/sysUser/checkPassword`, //验证密码正确
  changeAdmin: `${host}/upm/tenant/changeAdmin`, //验证密码正确
  resendTenantMail: `${host}/upm/tenant/reSendTenantMail`,
  listUserByDefault: `${host}/upm/sysUser/listUserByDefault`,


  bind: `${host}/upm/person/bind`, // 绑定手机邮箱
  unbind: `${host}/upm/person/unbind`, // 解绑手机邮箱
  updateMobile: `${host}/upm/person/updateMobile`, // 修改手机
  updateEmail: `${host}/upm/person/updateEmail`, // 修改邮箱
  smsSetpwd: `${host}/upm/person/sms/setpwd`, // 手机修改密码
  emailSetpwd: `${host}/upm/person/email/setpwd`, // 邮箱修改密码
}

common.file = {
  upload: `${host}/storage/attachment/upload`, // 上传
  download: `${host}/storage/attachment/att`, // 下载
  getAliPolicy: `${host}/storage/attachment/uploadSign`
}

common.message = {
  getMessages: `${host}/msg/msgsCenterInfo/page`, //获取消息列表
  getUnread: `${host}/msg/msgsCenterInfo/unread`, //获取未读数量
  delMsg: `${host}/msg/msgsCenterInfo/delmsg`, //删除
  mark: `${host}/msg/msgsCenterInfo/mark` //标记已读
}

common.plugin = {
  getPluginList: `${host}/system/plugin/list`,
  updatePluginStatus: `${host}/system/plugin/updateStatus`,
  ocrTemplateList: `${host}/system/plugin/ocr/rule/list`,
  ocrTemplateCreate: `${host}/system/plugin/ocr/rule/save`,
  ocrTemplateUpdate: `${host}/system/plugin/ocr/rule/update`,
  ocrTemplateDetail: `${host}/system/plugin/ocr/rule/detail`,
  ocrTemplateDelete: `${host}/system/plugin/ocr/rule/delete`,
  ocrRecords: `${host}/log/ocr/list`,
  smsRecords: `${host}/msg/smsSendLogs/page`,
  loginLog: `${host}/log/loginLog/list`,
  operationLog: `${host}/log/operationLog/search`
}

export default common