<template>
  <span class="placeholder"></span>
</template>
<script>
export default {
  props: {
    viewModel: Object,
    data: {
      require: true
    }
  },
  created() {
    window.open(this.viewModel.getUrl(this.data))
  }
}
</script>
<style scoped>
.placeholder{
  display: none;
}
</style>