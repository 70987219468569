function simpleFunc(message){ 
  return { 
    content: message.content, 
    date: message.createTime 
  }
}

export default [
  { code: 'user_mod', name:'用户修改', container: 'Loader', 
    getData: (message) => { return message.handlerParams },
    path: 'modules/saas/views/platform/user/user/detail/index.vue' 
  },
  { code: 'user_add', name:'用户新增', container: 'Loader', 
    getData: (message) => { return message.handlerParams },
    path: 'modules/saas/views/platform/user/user/detail/index.vue' 
  },
  { code: 'user_del', name:'用户删除', container: 'Simple', getData: simpleFunc},
  { code: 'role_mod', name:'角色修改', container: 'Drawer', title: '角色详情',
    getData: (message) => { return message.handlerParams },
    path: 'modules/saas/views/platform/role/RoleDetail.vue' 
  },
  { code: 'role_add', name:'角色新增', container: 'Drawer', title: '角色详情',
    getData: (message) => { return message.handlerParams },
    path: 'modules/saas/views/platform/role/RoleDetail.vue'  
  },
  { code: 'role_del', name:'角色删除', container: 'Simple', getData: simpleFunc},
  { code: 'bus_col_mod', name:'业务数据修改', container: 'Loader', path: '' },
  { code: 'bus_del', name:'业务数据删除', container: 'Simple', getData: simpleFunc},
  { code: 'bus_add', name:'业务数据新增', container: 'Loader', path: '' },
  { code: 'bus_mod_user', name:'业务表修改用户类字段', container: 'Window', 
    getData: (message) => { return JSON.parse(message.handlerParams) },
    getUrl: (data) => { 
      return `index.html#/platform/business/template/detail?id=${data.id}`
    }
  },
  { code: 'bus_tem_add', name:'新建业务表', container: 'Window', 
    getData: (message) => { return JSON.parse(message.handlerParams) },
    getUrl: (data) => { 
      return `index.html#/platform/business/template/detail?id=${data.id}`
    }
  },
  { code: 'bus_tem_mod', name:'修改业务表', container: 'Window', 
    getData: (message) => { return JSON.parse(message.handlerParams) },
    getUrl: (data) => { 
      return `index.html#/platform/business/template/detail?id=${data.id}`
    }
  },
  { code: 'bus_tem_opt', name:'启用/停用业务表', container: 'Window',
    getData: (message) => { return JSON.parse(message.handlerParams) },
    getUrl: (data) => { 
      return `index.html#/platform/business/template/detail?id=${data.id}`
    }
  },
  { code: 'pro_tem_del', name:'删除业务表', container: 'Simple', getData: simpleFunc},
  { code: 'pro_tem_add', name:'新建流程模板', container: 'Window', 
    getData: (message) => { return JSON.parse(message.handlerParams) },
    getUrl: (data) => { 
      return `index.html#/platform/workflow/template/detail?id=${data.templateId}&editMode=0`
    }
  },
  { code: 'pro_tem_mod', name:'修改流程模板', container: 'Window', 
    getData: (message) => { return JSON.parse(message.handlerParams) },
    getUrl: (data) => { 
      return `index.html#/platform/workflow/template/detail?id=${data.templateId}&editMode=0`
    }
  },
  { code: 'pro_tem_opt', name:'启用/停用流程模板', container: 'Window', 
    getData: (message) => { return JSON.parse(message.handlerParams) },
    getUrl: (data) => { 
      return `index.html#/platform/workflow/template/detail?id=${data.templateId}&editMode=0`
    }
  },
  { code: 'pro_node_trans', name:'离职转交', container: 'Loader', path: '' },
  { code: 'pro_trans', name:'待办转交', container: 'Loader', path: '' },
  { code: 'pro_confirm', name:'服务商服务确认', container: 'Window', 
    getUrl: (data) => { 
      return `/industry.html#/industry.html#/industry/application/supplier/detail?id=${data}`
    } 
  },
  { code: 'pro_cancel', name:'服务商解除合作', container: 'Simple', getData: simpleFunc},
  { code: 'pro_mod', name:'服务商变更授权期限', container: 'Window',
    getData: (message) => { return JSON.parse(message.handlerParams) }, 
    getUrl: (data) => { 
      return `/industry.html#/industry.html#/industry/application/supplier/detail?id=${data}`
    }
  },
  { code: 'con_feedback', name:'客户反馈意见', container: 'Loader', path: '' },
  { code: 'con_confirm', name:'客户确认合作关系', container: 'Loader', path: '' }
]