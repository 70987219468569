import URL from '@/interface/index'
import { get, postStringify } from '@/assets/script/http'

const user = {
  getInfo: (params) => get(URL.common.user.getCurrentUser, params),
  getRegionConfig: (params) => get(URL.common.user.getRegionConfig, params),
  logout: (params) => postStringify(URL.common.user.logout, params),
  getAreaList: (params) => get(URL.common.base.listArea, params),
  getRegionList: (params) => get(URL.platform.user.user.getUserRegion, params)
}
export default user