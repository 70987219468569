<template>
  <div v-if="container">
    <component :is="container" :viewModel="viewModel" :data="data"></component>
  </div>
</template>
<script>
import Simple from './Simple'
import Drawer from './Drawer'
import Loader from './Loader'
import Window from './Window'
import config from './config'

export default {
  components: {
    Simple,
    Loader,
    Drawer,
    Window
  },
  data() {
    return {
      container: '',
      data: '',
      viewModel: {}
    }
  },
  methods: {
    init(message) {
      this.container = ''
      let map = config.find(c => { return c.code == message.code })  
      this.data = map.getData ? map.getData(message) : message
      this.viewModel = map

      this.$nextTick(() => {
        this.container = map.container
      })
    }
  }
}
</script>
<style>
.mb-message-detail .el-dialog__header .el-dialog__title{
  color: #8B8B8E;
}
.mb-message-detail .el-dialog__body .content{
  color: #161616;
  margin-bottom: 20px;
}
.mb-message-detail .el-dialog__body .date{
  color: #8B8B8E
}
</style>