//业务管理接口
import host from '@/assets/script/host'

let business = {
  business: {
    getObjectList(apiName){
      return `${host}/bizmeta/v1/object/${apiName}/controller/List`
    },
    getObjectDetail(apiName){
      return `${host}/bizmeta/v1/object/${apiName}/controller/Detail`
    },
    getObjectView(apiName){
      return `${host}/bizmeta/v1/object/${apiName}/controller/View`
    },
    getFlowDetail(apiName){
      return `${host}/bizmeta/v1/object/${apiName}/controller/FlowDetail`
    },
    getMasterList: `${host}/bizmeta/v1/object/describe/service/listMasterObj`,
    getMasterDetail: `${host}/bizmeta/v1/object/describe/service/detailMasterDescribe`,
    processDynamicUpdate: `${host}/bpm/template/dynamic/update`,
    msgConfig: `${host}/bpm/template/msg/config`,
    msgConfigList: `${host}/bpm/template/msg/config/list`,
    getViewports: `${host}/bizmeta/v1/object/filterView/service/getTemplateForCustomCondition`,//获取视图模板
    createView: (isProject) => {
      return `${host}/bizmeta/${isProject ? 'project/' : ''}customView/create`
    }, //创建视图
    updateView: (isProject) => {
      return `${host}/bizmeta/${isProject ? 'project/' : ''}customView/update`
    }, //更新视图
    updateViewName: (isProject) => {
      return `${host}/bizmeta/${isProject ? 'project/' : ''}customView/updateName`
    }, //重命名视图
    deleteView: (isProject) => {
      return `${host}/bizmeta/${isProject ? 'project/' : ''}customView/delete` //删除视图
    },
    updateSort: `${host}/bizmeta/customView/updateDataSort`,
    updateProjectSort: `${host}/bizmeta/project/customView/updateDataSort`,
    findViewport: `${host}/bizmeta/v1/object/filterView/service/findFilterViewById`, //根据id查询视图
    setDefaultViewport: `${host}/bizmeta/v1/object/filterView/service/setDefaultTemplate`, //设置默认视图
    linkList(apiName) { return `${host}/bizmeta/v1/object/${apiName}/controller/LinkList` },
    distinctList(apiName){ return `${host}/bizmeta/v1/object/${apiName}/controller/DistinctList` },
    appList: `${host}/bizmeta/application/home/list`,
    appDetail: `${host}/bizmeta/application/home/detail`,
    // 流程中所用的应用列表及详情
    appManageList: `${host}/bizmeta/application/manage/list`,
    appManageDetail: `${host}/bizmeta/application/manage/detail`,
    eaappManageDetail: `${host}/bizmeta/ea/app/manage/config/detail`, // 企联应用

    getDescribeDetail: `${host}/bizmeta/v1/object/describe/service/detailDescribe`,
    getRecordRefDetail: `${host}/bizmeta/v1/object/describe/service/recordRefDetail`,
    layoutUpdate: (isProject) => {
      return `${host}/bizmeta/${isProject ? 'project/' : ''}customView/layout/update`
    },
    viewSort: (isProject) => {
      return `${host}/bizmeta/${isProject ? 'project/' : ''}customView/sort`
    },
    getRecordLinkRecords(apiName) { return `${host}/bizmeta/v1/object/${apiName}/controller/GetRecordLinkRecords` },
    updateRecordLinkRecords(apiName) { return `${host}/bizmeta/v1/object/${apiName}/action/UpdateRecordLinkRecords` },
    updateShowFields: `${host}/bizmeta/customView/updateShowFields`,
    updateDataFilter: `${host}/bizmeta/customView/updateDataFilter`,
    updateProjectShowFields: `${host}/bizmeta/project/customView/updateShowFields`,
    getOCRContent: `${host}/bizmeta/plugin/ocr/getContent`,
    getDataChangeLog: `${host}/bizmeta/dataChangeLog/list`,
    getDataComments: `${host}/bizmeta/v1/comment/list`,
    setComment: `${host}/bizmeta/v1/comment/create`,
    findViewsList: `${host}/bizmeta/customView/findViewsList`,
    updateGroups: `${host}/bizmeta/customView/updateGroups`,
    updateProjectGroups: `${host}/bizmeta/project/customView/updateGroups`,
    listDescribes: `${host}/bizmeta/v1/object/describe/service/listDescribesInAppId`,
    lookup: `${host}/bizmeta/v1/object/calculate/service/lookup`,
    // 自定义按钮接口
    buttonUpdateFields(apiName, buttonName){
      return `${host}/bizmeta/v1/object/${apiName}/action/${buttonName}`
    },
    buttonUpdateFieldsBulk(apiName){
      return `${host}/bizmeta/v1/object/${apiName}/action/BulkCustomButton`
    },
    setAuthGroupDefault(appId, apiName) {
      return `${host}/bizmeta/admin/app/${appId}/object/${apiName}/authgroup/defaultType/set`
    },
    //普通模板的权限组设置视图样式
    updateAuthGroupViewType(appId, apiName) {
      return `${host}/bizmeta/admin/app/${appId}/object/${apiName}/authgroup/viewtype/set`
    }
  },
  instance: {
    datail: `${host}/busmodel/instance/metadata`,
    objectlist: `${host}/busmodel/instance/objectlist`,
    create: `${host}/busmodel/instance/objectcreate`,
    draft: `${host}/busmodel/instance/objectdraft`,
    delete: `${host}/busmodel/instance/objectdelete`,    
    // update: `${host}/busmodel/instance/objectmodifier`, 
    expressionCheck: `${host}/bizmeta/v1/object/calculate/service/expressionCheck`,
    calculate: `${host}/bizmeta/v1/object/calculate/service/expressionCalculate`,
    busBatchCalculate: `${host}/bizmeta/v1/object/calculate/service/batchDataExpressionCalculate`,
    sfBatchCalculate:`${host}/bizmeta/v1/smartform/rest/ext/data/batchDataExpressionCalculate`,

    recno: `${host}/bizmeta/v1/object/calculate/service/recno`,
    smartformRecno: `${host}/bizmeta/v1/smartform/rest/ext/data/recno`,

    importData: `${host}/bizmeta/v1/object/bulkimport/importData`,
    importUpdateData: `${host}/bizmeta/v1/object/bulkimport/updateData`,
    importCreateData: `${host}/bizmeta/v1/object/bulkimport/createData`,

    importCustomerOrder: `${host}/bizmeta/v1/object/bulkimport/importCustomerOrder`
    
  },
  dashboard: {
    statistics(pageApiName, compApiName){ return `${host}/bizmeta/v1/page/${pageApiName}/component/${compApiName}/statistics` }
  },
  user: {
    userList: `${host}/upm/sysUser/userPageList`,
    userListByIds: `${host}/upm/sysUser/listUserByIds`
  },

  auth: {
    lists: `${host}/busmodel/workBenchAuth/lists`,
    create: `${host}/busmodel/workBenchAuth/create`,
    delete: `${host}/busmodel/workBenchAuth/delete`,
  }
}

export default business