<template>
  <div>
    <el-container v-if="show">
      <el-aside width="280px">
        <my-aside />
      </el-aside>
      <el-container>
        <el-header class="my-header"><my-header /></el-header>
        <el-main class="my-main" v-scrollBar><router-view /></el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import MyHeader from './Header'
import MyAside from './Aside'

import siteConfig from '@/config/site.config'
 
export default {
  name: 'app',
  components: {
    MyHeader,
    MyAside,
  },
  data () {
    return {
      show: false
    }
  },
  created () {
    this.initAppUrl()
  },
  methods: {
    initAppUrl() {
      // 判断url是否为应用url,解析应用ID跳转
      const pathname = location.pathname
      let flag = this.$store.getters.appUrlFlag

      if(flag) {
        let pathArr = pathname.split('/')
        let appDomain = pathArr.length > 1 ? pathArr[2] : ''
        if(appDomain) this.toAppURL(appDomain)
      } else this.show = true
    },
    toAppURL(appDomain) {
      if(!appDomain) return

      this.$axios.post(this.$URL.build.application.getAppIdByDomain, { domain: appDomain })
        .then(res => {
          const appId = res.data.id
          
          if(appId) this.$_goto('/app/' + appId)
          else location.href = location.origin + siteConfig.nullUrl

          this.$store.commit('common/setAppUrlFlag', false)
        })
    }
  }
}
</script>
<style scoped>
.my-header {
  border-bottom: 1px solid #E6EAEE;
}
.my-main {
  position: relative;
  height: 100vh;
}
</style>