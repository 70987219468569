const business = {
  namespaced: true,
  state: {
    appList: [],
    appWfDetails: [],
    industryAppList: [],
    ocrCustomTemplates: [],
    smsTemplates: [],
    curAppFlowCenterBadge: false
  },
  mutations: {
    SET_APP_LIST: (state, appList) => {
      state.appList = appList
    },
    SET_APP_WF_DETAIL: (state, detail) => {
      let map = state.appWfDetails.find(app => app.appId == detail.appId)
      if(!map)  state.appWfDetails.push({ appId: detail.appId, wfBusList: detail.wfBusList })
    },
    SET_APP_WF_DETAIL_NODES: (state, detail) => {
      let mapApp = state.appWfDetails.find(app => app.appId == detail.appId)
      if(!mapApp)   return

      let mapBus = mapApp.wfBusList.find(bus => bus.apiName == detail.wfBusApiName)
      if(!mapBus) return

      mapBus.nodes = detail.nodes
    },
    SET_Industry_APP_LIST: (state, appList) => {
      state.industryAppList = appList
    },
    SET_OCR_CUSTOM_TEMPLATES: (state, ocrList) => {
      state.ocrCustomTemplates = ocrList
    },
    SET_OCR_CUSTOM_TEMPLATE: (state, template) => {
      let tIndex = state.ocrCustomTemplates.findIndex(t => t.value == template.value)
      if(tIndex == -1) return

      state.ocrCustomTemplates[tIndex] = template
    },
    SET_SMS_TEMPLATES: (state, smsList) => {
      state.smsTemplates = smsList
    },
    SET_FLOW_CENTER_BADGE: (state, data) => {
      state.curAppFlowCenterBadge = data     
    }
  }
}

export default business
