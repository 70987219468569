import user from './user'
import platform from './platform'
import business from './business'
import workbench from './workbench'

const api = {
  user,
  platform,
  business,
  workbench
}

export default api