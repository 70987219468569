var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "member-group-container", class: { inline: _vm.inline } },
    [
      _vm.entry == "dialog"
        ? [
            _c(
              "div",
              {
                staticClass: "user-area",
                style: { cursor: _vm.disabled ? "not-allowed" : "pointer" },
              },
              [
                _vm._l(_vm.outTagList, function (user, $index) {
                  return _c("User-Item", {
                    key: user.id,
                    attrs: { userInfo: user, deleteable: !_vm.disabled },
                    on: {
                      delete: function ($event) {
                        return _vm.removeTagOuter(user, $index)
                      },
                    },
                  })
                }),
                _vm.inline ? _c("div", { staticClass: "place" }) : _vm._e(),
                !_vm.disabled
                  ? _c("i", {
                      staticClass: "create-icon",
                      on: { click: _vm.openUserDialog },
                    })
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "el-dialog",
              {
                staticClass: "small",
                attrs: {
                  title: _vm.title,
                  visible: _vm.dialogVisible,
                  "append-to-body": true,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                },
              },
              [
                _c("div", { staticClass: "_components_member_group" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "border-block user-list user-inner-container",
                    },
                    [
                      _vm.selectTagList.length == 0
                        ? _c("span", [_vm._v("未选择")])
                        : _vm._e(),
                      _vm._l(_vm.selectTagList, function (user, $index) {
                        return _c("User-Item", {
                          key: user.id,
                          attrs: { userInfo: user, deleteable: !_vm.disabled },
                          on: {
                            delete: function ($event) {
                              return _vm.removeTag(user, $index)
                            },
                          },
                        })
                      }),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "border-block user-select-area" }, [
                    _c(
                      "div",
                      {
                        staticClass: "top-bar",
                        class: { focus: _vm.isSearchFocus },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isSearchFocus,
                                expression: "!isSearchFocus",
                              },
                            ],
                            staticClass: "float-l",
                          },
                          [
                            _c(
                              "ul",
                              _vm._l(_vm.optionList, function (item) {
                                return _c(
                                  "li",
                                  {
                                    key: item.id,
                                    staticClass: "option-item",
                                    class: {
                                      active: _vm.currentOptionId == item.id,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectOption(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item.label))]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "float-r" },
                          [
                            _c(
                              "el-input",
                              {
                                ref: "searchRef",
                                attrs: { placeholder: "搜索", clearable: "" },
                                on: {
                                  blur: _vm.blurFocus,
                                  input: _vm.searchUser,
                                  focus: _vm.handleSearchFocus,
                                  clear: _vm.clearFocus,
                                },
                                model: {
                                  value: _vm.searchStr,
                                  callback: function ($$v) {
                                    _vm.searchStr =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "searchStr",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-input__icon el-icon-search",
                                  attrs: { slot: "prefix" },
                                  slot: "prefix",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "user-content" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.isSearchFocus &&
                                (_vm.currentOptionId == 1 ||
                                  _vm.currentOptionId == 3),
                              expression:
                                "!isSearchFocus && (currentOptionId == 1 || currentOptionId == 3)",
                            },
                          ],
                        },
                        [
                          _c("div", { staticClass: "float-l block info" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.currentOptionId == 1,
                                    expression: "currentOptionId == 1",
                                  },
                                ],
                              },
                              [
                                _c("el-tree", {
                                  ref: "orgTree",
                                  staticClass: "menu-tree",
                                  attrs: {
                                    "node-key": "id",
                                    "icon-class": "el-icon-arrow-right",
                                    data: _vm.orgs,
                                    props: _vm.treeOrgDataDefaultProps,
                                    "highlight-current": "",
                                  },
                                  on: { "node-click": _vm.treeNodeClick },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.currentOptionId == 3,
                                    expression: "currentOptionId == 3",
                                  },
                                ],
                              },
                              [
                                _c("el-tree", {
                                  ref: "roleTree",
                                  staticClass: "menu-tree",
                                  attrs: {
                                    "node-key": "id",
                                    "icon-class": "el-icon-arrow-right",
                                    data: _vm.roles,
                                    props: _vm.treeOrgDataDefaultProps,
                                    "highlight-current": "",
                                  },
                                  on: { "node-click": _vm.roleNodeClick },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "float-r block choose" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.currentOptionId == 1,
                                    expression: "currentOptionId == 1",
                                  },
                                ],
                              },
                              [
                                !_vm.isSingel
                                  ? _c(
                                      "label",
                                      { staticClass: "check-box select-all" },
                                      [
                                        _c("span", { staticClass: "float-l" }, [
                                          _vm._v(
                                            "已选 " +
                                              _vm._s(
                                                _vm.currentGroupUser.checkedUser
                                                  .length ||
                                                  0 +
                                                    "/" +
                                                    _vm.currentGroupUser.list
                                                      .length
                                              )
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "float-r" },
                                          [
                                            _c("el-checkbox", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.currentGroupUser.list
                                                      .length > 0,
                                                  expression:
                                                    "currentGroupUser.list.length > 0",
                                                },
                                              ],
                                              attrs: {
                                                indeterminate:
                                                  _vm.currentGroupUser
                                                    .isIndeterminate,
                                              },
                                              on: {
                                                change: _vm.selectAllUserChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.currentGroupUser
                                                    .selectAll,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentGroupUser,
                                                    "selectAll",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentGroupUser.selectAll",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.isSingel
                                  ? _c(
                                      "div",
                                      { staticClass: "single-radio-area" },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            staticStyle: { width: "100%" },
                                            model: {
                                              value:
                                                _vm.currentGroupUser
                                                  .checkedUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.currentGroupUser,
                                                  "checkedUser",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "currentGroupUser.checkedUser",
                                            },
                                          },
                                          _vm._l(
                                            _vm.currentGroupUser.list,
                                            function (user) {
                                              return _c(
                                                "section",
                                                {
                                                  key: user.id,
                                                  staticClass: "check-box",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeTagRow(
                                                        user,
                                                        "group",
                                                        user.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "user-head",
                                                        attrs: {
                                                          src:
                                                            user.headImgUrl ||
                                                            _vm.defaultImg,
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "float-l name",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              user.realName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: { label: user.id },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeTag(
                                                            user,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return (function (e) {
                                                            return e.stopPropagation()
                                                          })($event)
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "single-checkbox-area" },
                                      [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            model: {
                                              value:
                                                _vm.currentGroupUser
                                                  .checkedUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.currentGroupUser,
                                                  "checkedUser",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "currentGroupUser.checkedUser",
                                            },
                                          },
                                          _vm._l(
                                            _vm.currentGroupUser.list,
                                            function (user) {
                                              return _c(
                                                "section",
                                                {
                                                  key: user.id,
                                                  staticClass: "check-box",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeTagRow(
                                                        user,
                                                        "group"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "user-head",
                                                        attrs: {
                                                          src:
                                                            user.headImgUrl ||
                                                            _vm.defaultImg,
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "float-l name",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              user.realName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      attrs: { label: user.id },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeTag(
                                                            user,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return (function (e) {
                                                            return e.stopPropagation()
                                                          })($event)
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.currentOptionId == 3,
                                    expression: "currentOptionId == 3",
                                  },
                                ],
                              },
                              [
                                _vm.isSingel
                                  ? _c(
                                      "div",
                                      { staticClass: "single-radio-area" },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            staticStyle: { width: "100%" },
                                            model: {
                                              value:
                                                _vm.currentRoleUser.checkedUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.currentRoleUser,
                                                  "checkedUser",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "currentRoleUser.checkedUser",
                                            },
                                          },
                                          _vm._l(
                                            _vm.currentRoleUser.list,
                                            function (user) {
                                              return _c(
                                                "section",
                                                {
                                                  key: user.id,
                                                  staticClass: "check-box",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeTagRow(
                                                        user,
                                                        "role",
                                                        user.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "user-head",
                                                        attrs: {
                                                          src:
                                                            user.headImgUrl ||
                                                            _vm.defaultImg,
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "float-l name",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              user.realName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: { label: user.id },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeTag(
                                                            user,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return (function (e) {
                                                            return e.stopPropagation()
                                                          })($event)
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "single-checkbox-area" },
                                      [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            model: {
                                              value:
                                                _vm.currentRoleUser.checkedUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.currentRoleUser,
                                                  "checkedUser",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "currentRoleUser.checkedUser",
                                            },
                                          },
                                          _vm._l(
                                            _vm.currentRoleUser.list,
                                            function (user) {
                                              return _c(
                                                "section",
                                                {
                                                  key: user.id,
                                                  staticClass: "check-box",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeTagRow(
                                                        user,
                                                        "role"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "user-head",
                                                        attrs: {
                                                          src:
                                                            user.headImgUrl ||
                                                            _vm.defaultImg,
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "float-l name",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              user.realName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      attrs: { label: user.id },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeTag(
                                                            user,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return (function (e) {
                                                            return e.stopPropagation()
                                                          })($event)
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.isSearchFocus ||
                                _vm.currentOptionId == 2 ||
                                _vm.currentOptionId == 4,
                              expression:
                                "isSearchFocus || currentOptionId == 2 || currentOptionId == 4",
                            },
                          ],
                          staticClass: "search-content",
                        },
                        [
                          _c(
                            "ul",
                            _vm._l(_vm.userList, function (user) {
                              return _c("li", { key: user.id }, [
                                _c("label", [
                                  _c("p", { staticClass: "user-info-detail" }, [
                                    _c("img", {
                                      staticClass: "user-head",
                                      attrs: {
                                        src: user.headImgUrl || _vm.defaultImg,
                                      },
                                    }),
                                    _c("span", [_vm._v(_vm._s(user.realName))]),
                                  ]),
                                  _c(
                                    "p",
                                    [
                                      !_vm.isSingel
                                        ? _c("el-checkbox", {
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeUserStatus(
                                                  user,
                                                  $event
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return (function (e) {
                                                  return e.stopPropagation()
                                                })($event)
                                              },
                                            },
                                            model: {
                                              value: user.__checked,
                                              callback: function ($$v) {
                                                _vm.$set(user, "__checked", $$v)
                                              },
                                              expression: "user.__checked",
                                            },
                                          })
                                        : _c(
                                            "el-radio",
                                            {
                                              attrs: { label: user.id },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeUserStatus(
                                                    user,
                                                    $event
                                                  )
                                                },
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return (function (e) {
                                                    return e.stopPropagation()
                                                  })($event)
                                                },
                                              },
                                              model: {
                                                value: user.__checked,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    user,
                                                    "__checked",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.__checked",
                                              },
                                            },
                                            [_vm._v(" ")]
                                          ),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.isSearchFocus && _vm.currentOptionId == 5,
                              expression:
                                "!isSearchFocus && currentOptionId == 5",
                            },
                          ],
                          staticClass: "search-content",
                        },
                        [
                          _c(
                            "ul",
                            _vm._l(_vm.dynamicList, function (user) {
                              return _c("li", { key: user.id }, [
                                _c("label", [
                                  _c("p", [_vm._v(_vm._s(user.realName))]),
                                  _c(
                                    "p",
                                    [
                                      !_vm.isSingel
                                        ? _c("el-checkbox", {
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeUserStatus(
                                                  user,
                                                  $event
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return (function (e) {
                                                  return e.stopPropagation()
                                                })($event)
                                              },
                                            },
                                            model: {
                                              value: user.__checked,
                                              callback: function ($$v) {
                                                _vm.$set(user, "__checked", $$v)
                                              },
                                              expression: "user.__checked",
                                            },
                                          })
                                        : _c(
                                            "el-radio",
                                            {
                                              attrs: { label: user.id },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeUserStatus(
                                                    user,
                                                    $event
                                                  )
                                                },
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return (function (e) {
                                                    return e.stopPropagation()
                                                  })($event)
                                                },
                                              },
                                              model: {
                                                value: user.__checked,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    user,
                                                    "__checked",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.__checked",
                                              },
                                            },
                                            [_vm._v(" ")]
                                          ),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "footer",
                    [
                      _c("el-button", { on: { click: _vm.cancel } }, [
                        _vm._v("取消"),
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.confirm },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _vm.entry == "popover"
        ? [
            _c(
              "div",
              {
                staticClass: "user-area",
                style: { cursor: _vm.disabled ? "not-allowed" : "pointer" },
              },
              [
                _vm._l(_vm.outTagList, function (user, $index) {
                  return _c("User-Item", {
                    key: user.id,
                    attrs: { userInfo: user, deleteable: !_vm.disabled },
                    on: {
                      delete: function ($event) {
                        return _vm.removeTagOuter(user, $index)
                      },
                    },
                  })
                }),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      title: _vm.title,
                      placement: "bottom",
                      trigger: "click",
                      "append-to-body": true,
                      "popper-class": "small",
                    },
                    model: {
                      value: _vm.dialogVisible,
                      callback: function ($$v) {
                        _vm.dialogVisible = $$v
                      },
                      expression: "dialogVisible",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "user-popover _components_member_group" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "border-block user-list user-inner-container",
                          },
                          [
                            _vm.selectTagList.length == 0
                              ? _c("span", [_vm._v("未选择")])
                              : _vm._e(),
                            _vm._l(_vm.selectTagList, function (user, $index) {
                              return _c("User-Item", {
                                key: user.id,
                                attrs: {
                                  userInfo: user,
                                  deleteable: !_vm.disabled,
                                },
                                on: {
                                  delete: function ($event) {
                                    return _vm.removeTag(user, $index)
                                  },
                                },
                              })
                            }),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "border-block user-select-area" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "top-bar",
                                class: { focus: _vm.isSearchFocus },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isSearchFocus,
                                        expression: "!isSearchFocus",
                                      },
                                    ],
                                    staticClass: "float-l",
                                  },
                                  [
                                    _c(
                                      "ul",
                                      _vm._l(_vm.optionList, function (item) {
                                        return _c(
                                          "li",
                                          {
                                            key: item.id,
                                            staticClass: "option-item",
                                            class: {
                                              active:
                                                _vm.currentOptionId == item.id,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectOption(item.id)
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "float-r" },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        ref: "searchRef",
                                        attrs: {
                                          placeholder: "搜索",
                                          clearable: "",
                                        },
                                        on: {
                                          blur: _vm.blurFocus,
                                          input: _vm.searchUser,
                                          focus: _vm.handleSearchFocus,
                                          clear: _vm.clearFocus,
                                        },
                                        model: {
                                          value: _vm.searchStr,
                                          callback: function ($$v) {
                                            _vm.searchStr =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression: "searchStr",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-input__icon el-icon-search",
                                          attrs: { slot: "prefix" },
                                          slot: "prefix",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "user-content" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.isSearchFocus &&
                                        (_vm.currentOptionId == 1 ||
                                          _vm.currentOptionId == 3),
                                      expression:
                                        "!isSearchFocus && (currentOptionId == 1 || currentOptionId == 3)",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "float-l block info" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.currentOptionId == 1,
                                              expression:
                                                "currentOptionId == 1",
                                            },
                                          ],
                                        },
                                        [
                                          _c("el-tree", {
                                            ref: "orgTree",
                                            staticClass: "menu-tree",
                                            attrs: {
                                              "node-key": "id",
                                              "icon-class":
                                                "el-icon-arrow-right",
                                              data: _vm.orgs,
                                              props:
                                                _vm.treeOrgDataDefaultProps,
                                              "highlight-current": "",
                                            },
                                            on: {
                                              "node-click": _vm.treeNodeClick,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.currentOptionId == 3,
                                              expression:
                                                "currentOptionId == 3",
                                            },
                                          ],
                                        },
                                        [
                                          _c("el-tree", {
                                            ref: "roleTree",
                                            staticClass: "menu-tree",
                                            attrs: {
                                              "node-key": "id",
                                              "icon-class":
                                                "el-icon-arrow-right",
                                              data: _vm.roles,
                                              props:
                                                _vm.treeOrgDataDefaultProps,
                                              "highlight-current": "",
                                            },
                                            on: {
                                              "node-click": _vm.roleNodeClick,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "float-r block choose" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.currentOptionId == 1,
                                              expression:
                                                "currentOptionId == 1",
                                            },
                                          ],
                                        },
                                        [
                                          !_vm.isSingel
                                            ? _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "check-box select-all",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "float-l" },
                                                    [
                                                      _vm._v(
                                                        "已选 " +
                                                          _vm._s(
                                                            _vm.currentGroupUser
                                                              .checkedUser
                                                              .length ||
                                                              0 +
                                                                "/" +
                                                                _vm
                                                                  .currentGroupUser
                                                                  .list.length
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "float-r" },
                                                    [
                                                      _c("el-checkbox", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm
                                                                .currentGroupUser
                                                                .list.length >
                                                              0,
                                                            expression:
                                                              "currentGroupUser.list.length > 0",
                                                          },
                                                        ],
                                                        attrs: {
                                                          indeterminate:
                                                            _vm.currentGroupUser
                                                              .isIndeterminate,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.selectAllUserChange,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.currentGroupUser
                                                              .selectAll,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.currentGroupUser,
                                                              "selectAll",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "currentGroupUser.selectAll",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.isSingel
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "single-radio-area",
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentGroupUser
                                                            .checkedUser,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentGroupUser,
                                                            "checkedUser",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentGroupUser.checkedUser",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.currentGroupUser.list,
                                                      function (user) {
                                                        return _c(
                                                          "section",
                                                          {
                                                            key: user.id,
                                                            staticClass:
                                                              "check-box",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeTagRow(
                                                                  user,
                                                                  "group",
                                                                  user.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "align-items":
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "user-head",
                                                                  attrs: {
                                                                    src:
                                                                      user.headImgUrl ||
                                                                      _vm.defaultImg,
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "float-l name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        user.realName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "el-radio",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    user.id,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeTag(
                                                                        user,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return (function (
                                                                        e
                                                                      ) {
                                                                        return e.stopPropagation()
                                                                      })($event)
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v(" ")]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "single-checkbox-area",
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.currentGroupUser
                                                            .checkedUser,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentGroupUser,
                                                            "checkedUser",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentGroupUser.checkedUser",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.currentGroupUser.list,
                                                      function (user) {
                                                        return _c(
                                                          "section",
                                                          {
                                                            key: user.id,
                                                            staticClass:
                                                              "check-box",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeTagRow(
                                                                  user,
                                                                  "group"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "align-items":
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "user-head",
                                                                  attrs: {
                                                                    src:
                                                                      user.headImgUrl ||
                                                                      _vm.defaultImg,
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "float-l name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        user.realName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "el-checkbox",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    user.id,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeTag(
                                                                        user,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return (function (
                                                                        e
                                                                      ) {
                                                                        return e.stopPropagation()
                                                                      })($event)
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v(" ")]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.currentOptionId == 3,
                                              expression:
                                                "currentOptionId == 3",
                                            },
                                          ],
                                        },
                                        [
                                          _vm.isSingel
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "single-radio-area",
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentRoleUser
                                                            .checkedUser,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentRoleUser,
                                                            "checkedUser",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentRoleUser.checkedUser",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.currentRoleUser.list,
                                                      function (user) {
                                                        return _c(
                                                          "section",
                                                          {
                                                            key: user.id,
                                                            staticClass:
                                                              "check-box",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeTagRow(
                                                                  user,
                                                                  "role",
                                                                  user.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "align-items":
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "user-head",
                                                                  attrs: {
                                                                    src:
                                                                      user.headImgUrl ||
                                                                      _vm.defaultImg,
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "float-l name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        user.realName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "el-radio",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    user.id,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeTag(
                                                                        user,
                                                                        "role",
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return (function (
                                                                        e
                                                                      ) {
                                                                        return e.stopPropagation()
                                                                      })($event)
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v(" ")]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "single-checkbox-area",
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.currentRoleUser
                                                            .checkedUser,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentRoleUser,
                                                            "checkedUser",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentRoleUser.checkedUser",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.currentRoleUser.list,
                                                      function (user) {
                                                        return _c(
                                                          "section",
                                                          {
                                                            key: user.id,
                                                            staticClass:
                                                              "check-box",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeTagRow(
                                                                  user,
                                                                  "role"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "align-items":
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "user-head",
                                                                  attrs: {
                                                                    src:
                                                                      user.headImgUrl ||
                                                                      _vm.defaultImg,
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "float-l name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        user.realName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "el-checkbox",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    user.id,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeTag(
                                                                        user,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return (function (
                                                                        e
                                                                      ) {
                                                                        return e.stopPropagation()
                                                                      })($event)
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v(" ")]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.isSearchFocus ||
                                        _vm.currentOptionId == 2 ||
                                        _vm.currentOptionId == 4,
                                      expression:
                                        "isSearchFocus || currentOptionId == 2 || currentOptionId == 4",
                                    },
                                  ],
                                  staticClass: "search-content",
                                },
                                [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.userList, function (user) {
                                      return _c("li", { key: user.id }, [
                                        _c("label", [
                                          _c(
                                            "p",
                                            { staticClass: "user-info-detail" },
                                            [
                                              _c("img", {
                                                staticClass: "user-head",
                                                attrs: {
                                                  src:
                                                    user.headImgUrl ||
                                                    _vm.defaultImg,
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(_vm._s(user.realName)),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            [
                                              !_vm.isSingel
                                                ? _c("el-checkbox", {
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeUserStatus(
                                                          user,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return (function (e) {
                                                          return e.stopPropagation()
                                                        })($event)
                                                      },
                                                    },
                                                    model: {
                                                      value: user.__checked,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          user,
                                                          "__checked",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.__checked",
                                                    },
                                                  })
                                                : _c(
                                                    "el-radio",
                                                    {
                                                      attrs: { label: user.id },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeUserStatus(
                                                            user,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return (function (e) {
                                                            return e.stopPropagation()
                                                          })($event)
                                                        },
                                                      },
                                                      model: {
                                                        value: user.__checked,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            user,
                                                            "__checked",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "user.__checked",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.isSearchFocus &&
                                        _vm.currentOptionId == 5,
                                      expression:
                                        "!isSearchFocus && currentOptionId == 5",
                                    },
                                  ],
                                  staticClass: "search-content",
                                },
                                [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.dynamicList, function (user) {
                                      return _c("li", { key: user.id }, [
                                        _c("label", [
                                          _c("p", [
                                            _vm._v(_vm._s(user.realName)),
                                          ]),
                                          _c(
                                            "p",
                                            [
                                              !_vm.isSingel
                                                ? _c("el-checkbox", {
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeUserStatus(
                                                          user,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return (function (e) {
                                                          return e.stopPropagation()
                                                        })($event)
                                                      },
                                                    },
                                                    model: {
                                                      value: user.__checked,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          user,
                                                          "__checked",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.__checked",
                                                    },
                                                  })
                                                : _c(
                                                    "el-radio",
                                                    {
                                                      attrs: { label: user.id },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeUserStatus(
                                                            user,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return (function (e) {
                                                            return e.stopPropagation()
                                                          })($event)
                                                        },
                                                      },
                                                      model: {
                                                        value: user.__checked,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            user,
                                                            "__checked",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "user.__checked",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "footer",
                          [
                            _c("el-button", { on: { click: _vm.cancel } }, [
                              _vm._v("取消"),
                            ]),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.confirm },
                              },
                              [_vm._v("确定")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    !_vm.disabled
                      ? _c("i", {
                          staticClass: "create-icon",
                          staticStyle: { display: "block" },
                          attrs: { slot: "reference" },
                          on: { click: _vm.openUserDialog },
                          slot: "reference",
                        })
                      : _vm._e(),
                  ]
                ),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }