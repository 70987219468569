<template>
  <component :is="msgDetail" :msgModelData="this.data"></component>
</template>
<script>
export default {
  props: {
    viewModel: Object,
    data: {
      require: true
    }
  },
  data() {
    return {
      msgDetail: null
    }
  },
  created() {
    this.msgDetail = () => ({ component: import(`@/${this.viewModel.path}`) })
  }
}
</script>