var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        [
          _c(
            "el-drawer",
            {
              ref: "drawer",
              staticClass: "iopen-edit-drawer",
              attrs: {
                title: _vm.viewModel.title,
                visible: _vm.visible,
                "append-to-body": true,
                size: _vm.viewModel.width || "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visible = $event
                },
                close: _vm.onClose,
              },
            },
            [
              _c(
                "div",
                { staticClass: "container-content" },
                [
                  _c("loader", {
                    attrs: { viewModel: _vm.viewModel, data: _vm.data },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }