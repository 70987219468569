<template>
  <div class="message-container">
    <el-tooltip class="item" effect="dark" content="我的消息" placement="bottom-start">
      <el-badge is-dot :hidden="badgeNumber == 0" class="mb-message-badge">
        <i class="iconfont mb-icon-common-bell" @click="showMessage()"></i>
      </el-badge>
    </el-tooltip>
    <message-list ref="messageList"></message-list>
  </div>
</template>
<script>
import MessageList from './List'

export default {
  components: {
    MessageList
  },
  data() {
    return {
      badgeNumber: 0
    }
  },
  created() {
    this.getNewMessageCount()
  },
  methods: {
    showMessage() {
      this.$refs.messageList.showMessage()
    },    
    getNewMessageCount() {
      this.$axios.get(this.$URL.common.message.getUnread)
        .then(res => {
          this.badgeNumber = res.data
        })
    }
  }
}
</script>
<style scoped>
.message-container{
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.mb-message-badge{
  height: 60px;
  line-height: 60px;
  vertical-align: top;
}
.mb-message-badge .iconfont{
  line-height: 60px;
  font-size: 20px;
  color: #7891BE;
}
</style>
<style>
.mb-message-badge .el-badge__content.is-fixed.is-dot{
  top: 20px;
}
</style>