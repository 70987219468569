var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.msgDetail, {
    tag: "component",
    attrs: { msgModelData: this.data },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }