import errPage from '@/components/404'
const commons = [{
    name: 'personal',
    path: '/personal',
    component: () => import('@/components/Avatar/Personal')
  }, {
    name: 'personalStatus',
    path: '/personal/:active',
    component: () => import('@/components/Avatar/Personal')
  }, {
    name: 'company',
    path: '/company/:type',
    component: () => import('@/components/Avatar/Company')
  }, {
    path: '*',
    name: '*',
    component: errPage,
  }]

export default commons