
import siteConfig from '@/config/site.config'
import { domainHost } from '@/assets/script/host.js'

export const setHttpHeaders = (config) => {
  // 设置请求头参数 token（token），userid（用户id）、account（账号名称）、tenant（租户id）
  // let token = process.env.NODE_ENV !== 'dev' ? sessionStorage.getItem('login-token') : 'test'
  // let tenant = process.env.NODE_ENV !== 'dev' ? sessionStorage.getItem('login-tenant') : '000000'
  let token = localStorage.getItem('login-token')
  let region = localStorage.getItem('login-region')
  let tenant = localStorage.getItem('login-tenant') || ''
  let userid = JSON.parse(localStorage.getItem('login-userid'))
  
  if (!token || !userid) {
    let url = location.protocol + '//' + domainHost
    let pathName = location.pathname
    location.href = url + siteConfig.loginUrl + (pathName.length > 1 ? '?r_url='+pathName : '')
  }
  let commonHeaders = { token, userid, tenant, regionId: region }
  for (const key in commonHeaders) {
    if (commonHeaders.hasOwnProperty(key)) {
      const value = commonHeaders[key];
      config.headers.common[key] = value
    }
  }
}