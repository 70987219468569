import Vue from 'vue'

import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'

import { SentryHost } from '@/assets/script/host'

class SentryReport {
  static dsn = SentryHost
  constructor (options = {}) {
    this.options = options
  }

  static getInstance(options) {
    if(!(this.instance instanceof this)) {
      this.instance = new this(Vue, options)
      this.instance.install()
      this.instance.registerError()
    }

    return this.instance
  }

  install() {
    if(process.env.NODE_ENV != 'prod') return

    Raven.config(SentryReport.dsn, {
      sampleRate: 0.5,
      release: '1.0.0',
      environment: 'production'
    }).addPlugin(RavenVue, Vue).install()
    //用户信息
    //Raven.setUserContext({ user: this.options.user || '' }) 
    // 设置全局tag标签
    Raven.setTagsContext({ environment: process.env.NODE_ENV || '' })
  }

  registerError() {
    if(process.env.NODE_ENV != 'prod') return

    window.error = (msg, url, line, col, error) => {
      if(msg !== 'Script error.' && !url) return true

      setTimeout(() => {        
        col = col || (window.event && window.event.errorCharactor) || 0
        let data = {
          url: url,
          line: line,
          col: col,
          error: error
        }

        if(!!error && !!error.stack) {
          data.msg = error.stack.toString()
        }

        this.log(data)    
      }, 0)

      return true
    }

    window.addEventListener('unhandledrejection', err => {
      setTimeout(() => {
        this.log(JSON.stringify(err))        
      }, 0)

      return false
    })
  }

  log(data = null, type = 'error', options = {}) {
    Raven.captureBreadcrumb({
      message: data,
      category: 'web message'
    })
  
    if(data instanceof Error) {
      Raven.captureException(data, {
        level: type,
        logger: 'web exception',
        tags: {
          options: options
        }
      })
    } else {
      Raven.captureException('TypeError', {
        level: type,
        logger: 'web exception',
        extra: {
          data: data,
          options: this.options,
          date: new Date()
        }
      })
    }
  }
}

export default SentryReport

