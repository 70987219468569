export default {
  methods: {
    $_goto(url, query) {
      let location = window.location.pathname || ''
      if(!!url && url.toLowerCase() !== location.toLowerCase()) {
        this.$router.push({ path: url, query: query }).catch(err => {})
      }
    },
    $_stopPropagation(e) {
      e = window.event || e
      if(!e) return

      if(document.all)e.cancelBubble = true
      else e.stopPropagation()
    },
    $_isVisible() {
      const isSuper = this.$store.getters.isSuper
      return isSuper == 1
    }
  }
}