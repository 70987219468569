//应用颜色
export const app_colors = [
  {color: '#FF9966', transformColor: '#FCF0E9'},
  {color: '#A382FA', transformColor: '#F1EDFE'},
  {color: '#63BAF7', transformColor: '#EDF5FE'},
  {color: '#FE7CE4', transformColor: '#FFEDFA'},
  {color: '#5EE67A', transformColor: '#E8FDEB'},
  {color: '#FDDE90', transformColor: '#FFFCE1'}
]
//应用图标
let t_app_icons = []
for(let i = 1;i <= 24; i++){
  t_app_icons.push(`mb-icon-app-icon-${i}`)
}


export const app_icons = t_app_icons


export function getRandomIcon(){

  function getNum(max){
    return Math.floor(Math.random() * max);
  }

  let colorNum = getNum(app_colors.length);
  
  return {
    color: app_colors[colorNum].transformColor,
    icon: app_icons[getNum(app_icons.length)],
    iconColor: app_colors[colorNum].color
  }
}