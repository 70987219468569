import axios from 'axios'
import qs from 'qs'
import dayjs from 'dayjs'

import { domainHost } from '@/assets/script/host.js'
import siteConfig from '@/config/site.config'

import store from '@/store'
import { MessageBox } from 'element-ui'
import OwMessage from '@/elementui/message'

import { whitelist } from './whitelist'
import { setHttpHeaders } from '@/modules/saas/assets/script/service.js'
//超时时间
axios.defaults.timeout = 1000 * 30 

//request拦截器
axios.interceptors.request.use(
  config => {    
    setHttpHeaders(config)
    //if(!setInterceptor(config.url)) {
      //return Promise.reject('账号异常,重新登录')
    //}

    if (config.method === 'get') {
      let params = qs.parse(config.url.split('?')[1]), isParams = config.url.indexOf('?') > -1
      //get请求时间戳处理
      params && !params.timestamp && (config.url += (isParams ? '&': '?') + `timestamp=${new Date().getTime()}`)
    }
    
    return config
  }, 
  err => {
    return Promise.reject(err)
  }
)
//判断不同账号或不同组织的拦截
const setInterceptor = (url) => {
  if(whitelist.any(d => d.indexOf(url) > -1)) return

  let res = true
  try {
    const localUserId = localStorage.getItem('login-userid'),
    localRegion = localStorage.getItem('login-region'),
    storeUserId = store && store.getters ? store.getters.userid : undefined,
    storeRegion = store && store.getters ? store.getters.currentRegion : undefined

    if((storeUserId !== undefined && localUserId != storeUserId) 
      || (storeRegion !== undefined && localRegion != storeRegion)) {
      MessageBox({ 
        message: `您已离开当前企业/团队，无法操作同一浏览器，账号只能处于一个企业/团队。如果要继续操作，请在其他标签页内切换到当前企业/团队。`, 
        title: '账号异常', 
        type: 'error', 
        showConfirmButton: false, 
        showCancelButton: true, 
        cancelButtonText: '关闭' 
      })
      res = false
    }
  } catch {
    res = false
  }

  return res
}
// status code异常处理方式
let handles = [{
  reg: /^50/,
  func: (msg) => {
    MessageBox({ 
      message: `${msg}`, 
      title: '异常', 
      type: 'error', 
      showConfirmButton: false, 
      showCancelButton: true, 
      cancelButtonText: '关闭' 
    })
  }
},
{
  reg: /^40/,
  func: (msg) => {
    OwMessage({ message: msg, type: 'error',customClass:'zZindex' })
  }
}
]

const NO_MESSAGE_CODE_LIST = [90001, 90002, 90003, 90004, 90005, 90006, 90007, 900010];

//reponse拦截器
axios.interceptors.response.use(
  response => {
    let diffTimeStamp = store.getters.diffTimeStamp
    if(diffTimeStamp === null && response.headers.date) {
      const sysD = dayjs(response.headers.date).valueOf()
      
      if(!isNaN(sysD)) {
        const nowD = dayjs().valueOf()
        const dT = sysD - nowD
        
        store.commit('common/setDiffTimeStamp', dT)
      }
    }
    if(response.data.code == 400) {
      OwMessage({ message: response.data.msg, type: 'error',customClass:'zZindex' })
      return response.data
    } else if(response.data.code == 403) {
      let url = location.protocol + '//' + domainHost
      location.href = url + siteConfig.loginUrl
    } else if(response.data.code == 401) {
      //  重新登录弹框
      let url = location.protocol + '//' + domainHost
      location.href = url + siteConfig.loginUrl
    } else {
      if(response.config.data && typeof response.config.data == 'string'){
        let config = JSON.parse(response.config.data);
        if(config.$_showHeader) return response;
      }
      return response.data
    }
  }, 
  err => {
    let msg = '服务器异常'
    let noToast = false;

    if(err.response && err.response.data && Object.prototype.toString.call(err.response.data) === '[object Object]') {
      msg = err.response.data.msg || err.response.data.message
    } else if(err.response && err.response.data && Object.prototype.toString.call(err.response.data) === '[object ArrayBuffer]'){
      let enc = new TextDecoder('utf-8')
      let data = JSON.parse(enc.decode(new Uint8Array(err.response.data)))
      msg = data.msg || data.message
      noToast = data.code == 412;
    }
    if(err.response && err.response.data) {
      
      let handle = handles.find(h => { return h.reg.test(err.response.status) }),
          code = err.response.data.code;
      //  不需要提示的code
      if(NO_MESSAGE_CODE_LIST.indexOf(code) == -1 && !noToast) handle && handle.func(msg)
    }

    if(err.response && err.response.data && err.response.data.code == 403 || err.response.data.code == 401) {
      let url = location.protocol + '//' + domainHost
      location.href = url + siteConfig.loginUrl
    }
    
    return Promise.reject(err)
  }
)

export default axios
