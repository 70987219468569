var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-end",
            width: "180",
            "popper-class": "iopen-user-popover",
            "visible-arrow": false,
            trigger: "hover",
          },
        },
        [
          _c("div", { staticClass: "user-menu" }, [
            _c(
              "div",
              {
                staticClass: "item",
                on: {
                  click: function ($event) {
                    return _vm.$_goto("/personal")
                  },
                },
              },
              [
                _c("i", { staticClass: "iconfont mb-icon-common-personal" }),
                _c("span", [_vm._v("个人中心")]),
              ]
            ),
            !!_vm.isSuper
              ? _c(
                  "div",
                  {
                    staticClass: "item",
                    on: {
                      click: function ($event) {
                        return _vm.$_goto("/company/version")
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "iconfont mb-icon-common-company" }),
                    _c("span", [_vm._v("企业管理")]),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "item", on: { click: _vm.wxOAOpen } }, [
              _c("i", { staticClass: "iconfont mb-icon-mobile" }),
              _c("span", [_vm._v("移动端")]),
              _c("span", { staticClass: "new" }, [_vm._v("new")]),
            ]),
            _c("div", { staticClass: "split-line" }),
            _c("div", { staticClass: "item", on: { click: _vm.exit } }, [
              _c("i", { staticClass: "iconfont mb-icon-common-exit" }),
              _c("span", [_vm._v("退出登录")]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "user-avatar",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [_c("el-avatar", { attrs: { size: 26, src: _vm.circleUrl } })],
            1
          ),
        ]
      ),
      _c("WXOfficialAccounts", { ref: "wxOA" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }