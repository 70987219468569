import userHead from '@/assets/img/user-head.png'

export const OPTION_LIST = [
  { label: "部门", id: "org" },
  { label: "角色", id: "role" },
  { label: "成员", id: "user" },
  { label: '动态参数', id: 'dynamic' }
]

export const TREE_PROPS = {
  children: "children",
  label: "title",
}

export const SPECIAL_ORG = {
  id: -1,
  title: '全部用户'
}

export const DYNAMIC_ORG = {
  id: '${current_user_dept}',
  groupName: '当前用户所在部门',
  name: '当前用户所在部门'
}

export const DYNAMIC_USER = {
  id: '${current_user}',
  realName: '当前用户',
  name: '当前用户',
  img: String(userHead),
  $_checked: false,
}

export const ANONYMOUS_USER = {
  id: '0',
  realName: '匿名用户',
  name: '匿名用户',
  $_checked: false
}

export const USER_OPTION_LIST = {
  orgs: {
    label: "部门",
    id: "1",
  },
  roles: {
    label: "角色",
    id: "3"
  },
  users: {
    label: "用户",
    id: "4"
  },
  dynamic: {
    label: '动态参数',
    id: "5"
  },
  current: {
    label: '当前用户',
    id: "2"
  }
}

export const EXTENTION_ENUM = {
  DEPT_LEADER: 'deptLeader'
}