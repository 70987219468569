import layoutS from '@/modules/saas/layoutSystem'

const platform = [{
  name: 'system',
  path: '/',
  component: layoutS,
  children: [{
      name: 'platform_workflow_instance', 
      path: 'platform/workflow/instance',
      component: () => import('@/modules/saas/views/platform/workflow/instance')
    },
    {
      name: 'platform_organization', 
      path: 'platform/organization',
      component: () => import('@/modules/saas/views/platform/organization')
    },{
      name: 'platform_system_dict', 
      path: 'platform/system/dict',
      component: () => import('@/modules/saas/views/platform/system/dictionary')
    },{
      name: 'platform_system_rule', 
      path: 'platform/system/rule',
      component: () => import('@/modules/saas/views/platform/system/codeRule')
    },{
      name: 'platform_system_config', 
      path: 'platform/system/config',
      component: () => import('@/modules/saas/views/platform/system/config')
    },{
      name: 'platform_role', 
      path: 'platform/role',
      component: () => import('@/modules/saas/views/platform/role')
    },{
      name: 'platform_user_member', 
      path: 'platform/user/user',
      component: () => import('@/modules/saas/views/platform/user/user/index')
    }
  ]
}]

export default platform