import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import demos from './demo'
import workbench from './workbench'
import application from './application'
import platform from './platform'

import commons from '@/router/common'
import { getToken, setLoginData } from '@/modules/saas/assets/script/auth'
import { setHost } from '@/assets/script/host'

Vue.use(VueRouter)

let routes = [...demos, ...workbench, ...platform]

// 路由按权限过滤
function filterRoutes(routes) {
  let resRoutes = []
  routes.forEach(ri => {
    let children = []    
    if (children.length > 0) {
        let routerItem = { name: ri.name, path: ri.path, component: ri.component, children: children.distinct(['name'])}
        resRoutes.push(routerItem)
    }
  })
  
  resRoutes = resRoutes.concat(workbench)
  return resRoutes
}

//router注册
let constantRoutes = [
    {
        path: '/',
        redirect: 'main',
    },
    {
        name: 'join', 
        path: '/join', 
        component: () => import('@/modules/saas/views/join')
    },
    ...application
]
const createRouter = () => new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()
//跳转拦截
// TODO: 用户登录之后  本地应该会存在一个信息  每次路由经过这儿对这个信息做校验  这个优先级应当是最高的
router.beforeEach(async(to, from, next) => {
    const hasToken = getToken()
    
    if(!hasToken) setLoginData()

    const userId = store.getters.userid
    //如果存在userId
    if (userId) {
        next()
    } else {
        if(to.path == '/join'){
            next()
            return
        }
        
        const { isSuper } = await store.dispatch('user/getInfo')      
        const accessRoutes = isSuper ? routes : filterRoutes(routes)
        router.addRoutes(accessRoutes)
        router.addRoutes(commons)
        // 找到系统管理第一位路由 重定向到system下
        const sysRoutes = accessRoutes.find(a => a.name == 'system')

        const sysFirstRoutePath = sysRoutes && sysRoutes.children && sysRoutes.children[0] ? sysRoutes.children[0].path : '/platform/workflow/instance'
        router.addRoutes([
            {
                path: '/system',
                redirect: sysFirstRoutePath,
                children: []
            }
        ])

        next({ ...to, replace: true })
    }
})
// router AOP日志
router.afterEach((to) => {
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router