<template>
  <div class="aside">
    <div class="aside-main" v-scrollBar>
      <div class="logo">
        <img src="@/assets/img/logo-black.png" alt="BYGO">      
      </div>

      <div class="menu-item" :class="{active: $route.path.includes('/main')}" @click="$_goto('/main')">
        <i class="menu-icon iconfont mb-icon-common-index"></i>
        <span class="menu-name">首页</span>
      </div>
      <el-divider class="less-divide"></el-divider>
      <!-- 应用区域 -->
      <App-List></App-List>
    </div>
  </div>
</template>
<script>
import AppList from './App-List'

export default {
  components: { AppList },
  data() {
    return {
    }
  },
}
</script>
<style scope>
@import './aside.css'; /* 弹出框公共样式 */
.logo img{
  width: 120px;
}
.aside {
  height: 100vh;
}
.aside .aside-main {
  position: relative;
  height: 100%;
  padding: 20px 12px;
  background-color: #fff;
  border-right: 1px solid #E6EAEE;
  position: relative;
  z-index: 1000;
  box-sizing: border-box;
  overflow: auto;
}
.aside .logo {
  width:100%;
  margin-bottom: 30px;
  text-align: left;
  margin-left: 20px;
}

.menu-item {
  font-size: 14px;
  width: 100%;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  border-radius: 10px;
  color: #798A89;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
}
.menu-item .menu-icon {
  color: #00C185;
  font-size: 18px;
  margin-right: 15px;
}
.menu-item .menu-name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.menu-item.active {
  color: #00C185;
  background:#E8F8F3;
}
.menu-item:hover {
  background:#E8F8F3;
}
</style>
