const demos = [{
  name: 'draggle', 
  path: '/demo/draggle',
  component: () => import('@/modules/saas/views/demo/draggle')
}, {
  name: 'draggabledemo', 
  path: '/demo/draggabledemo',
  component: () => import('@/modules/saas/views/demo/draggable-demo')
}, {
  name: 'designer', 
  path: '/demo/designer/:designerMode',
  component: () => import('@/modules/saas/views/demo/designer')
}, {
  name: 'email', 
  path: '/demo/email',
  component: () => import('@/modules/saas/views/demo/email')
}, {
  name: 'table', 
  path: '/demo/table',
  component: () => import('@/modules/saas/views/demo/table.vue')
}, {
  name: 'gantt', 
  path: '/demo/gantt',
  component: () => import('@/modules/saas/views/demo/gantt.vue')
}, {
  name: 'error', 
  path: '/demo/error',
  component: () => import('@/modules/saas/views/demo/error.vue')
},{
  name: 'templateTree',
  path: '/demo/templateTree',
  component: () => import('@/modules/saas/views/demo/templateTree.vue')
},{
  name: 'select',
  path: '/demo/select',
  component: () => import('@/modules/saas/views/demo/select.vue')
},{
  name: 'formula',
  path: '/demo/formulaCalc',
  component: () => import('@/modules/saas/views/demo/formulaCalc.vue')
},{
  name: 'formulaTest',
  path: '/demo/formulaTest',
  component: () => import('@/modules/saas/views/demo/formulaTest.vue')
}]

export default demos