<template>
  <div class="aside-s">
    <div class="logo">
      <el-tooltip class="item" effect="light" content="返回首页" placement="bottom">
        <img src="@/assets/img/logo-white.png" alt="BYGO" @click="goHome">
      </el-tooltip>      
    </div>
    <div class="menu" v-for="(m, mI) in menus" :key="mI">
      <div class="menu-title">{{m.title}}</div>
      <section v-for="(c, cI) in m.children" :key="cI">
        <div v-if="cI < 5" class="menu-item" :class="{'active': c.active }" @click="$_goto(c.url)">
          <i class="menu-icon" :class="c.icon"></i>
          <span class="menu-name">{{c.name}}</span>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { menusNew } from '@/modules/saas/config/menus'

export default {
  watch: {
    $route(nV) {
      this.activeMenu(nV)
    }
  },
  created() {
    this.activeMenu(this.$route)
  },
  data() {
    return {
      menus: menusNew,
    }
  },
  methods: {
    activeMenu(route) {
      this.menus.forEach(mi => {
        mi.children.forEach(ci => {
          let active = ci.url === route.path || (ci.id && ci.id == route.query.id)
          ci.active = active
        })
      })
      this.$forceUpdate()
    },
    goHome() {
      document.location.href = `${document.location.protocol}//${document.location.host}/#/`
    }
  }
}
</script>
<style scope>
.logo img{
  width: 120px;
  cursor: pointer;
}
.aside-s {
  height: 100vh;
  padding: 25px 12px;
  background-color: #242436;
  border-right: 1px solid #E6EAEE;
  position: relative;
  z-index: 1000;
  box-sizing: border-box;
  overflow: auto;
}
.aside-s .logo {
  margin-bottom: 30px;
  text-align: left;
  margin-left: 20px;
}

.aside-s .menu {
  /* margin-top: 50px; */
}
.aside-s .menu-title {
  font-size:12px;
  font-weight:600;
  color:#7C7D7F;
  line-height: 36px;
  letter-spacing:1px;
}
.aside-s .menu-item {
  font-size:14px;
  width:100%;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  border-radius:4px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
}
.aside-s .menu-item .menu-icon {
  color: #CDCDCD;
}
.aside-s .menu-item .menu-name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.aside-s .menu-item.active {
  color: #ffffff;
  background:#00BD89;
}
.aside-s .menu-item.active .menu-icon{
  color: #ffffff;
}
.aside-s .menu-item:hover {
  background:#00BD89;
}
</style>
