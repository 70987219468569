<template>
  <div class="err-page">
    <img src="@/assets/img/404.png">
    <div class="prompt">sorry,您访问的页面在路上迷失了...</div>
    <div class="go-home" @click="goHome">返回首页</div>
  </div>
</template>
<script>
export default {
  methods: {
    goHome() {
      document.location.href = `${document.location.protocol}//${document.location.host}/#/`
    }
  }
}
</script>
<style scoped>
.err-page{
  display: flex;
  justify-content: center;
  width: 100%;
  flex-flow: column;
  align-items: center;
}
.err-page img{
  width: 30%;
}
.prompt{
  margin-top: 30px;
  color: #7E8E8D;
}
.go-home{
  margin-top: 20px;
  width: 100px;
  height: 36px;
  line-height: 36px;
  background: #00BD89;
  color: #fff;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;  
}
</style>