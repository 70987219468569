<template>
  <div>
    <el-input class="search-input" prefix-icon="el-icon-search" placeholder="搜索应用名称" clearable v-model.trim="searchAppName"></el-input>
    
    <ul v-if="!hasIndustry" class="app-container" style="margin-top: 20px">
      <li class="app-item" :class="{active: app.isActive}" v-for="app in menus[0].list" :key="app.id" @click="menus[0].click(app)">
        <span class="icon"> <i :style="app.$_style" class="iconfont" :class="app.$_style.icon"></i> </span>
        <span class="text">{{ app.name }}</span>
      </li>
    </ul>
    <el-tabs v-else class="app-tabs" v-model="curId">
      <el-tab-pane v-for="m in menus" :key="m.id" :label="m.name" :name="m.id">
        <ul class="app-container">
          <li class="app-item" :class="{active: app.isActive}" v-for="app in m.list" :key="app.id" @click="m.click(app)">
            <span class="icon"> <i :style="app.$_style" class="iconfont" :class="app.$_style.icon"></i> </span>
            <span class="text">{{ app.name }}</span>
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
const eaTypeMap = {
  eaProvider: 'provider',
  eaCustomer: 'customer'
}

export default {
  data() {
    return {
      curId: '1',
      searchAppName: ''
    };
  },
  computed: {
    hasIndustry() {
      const regionConfig = this.$store.getters.regionConfig
      return regionConfig 
        && ((regionConfig.versionPackage && regionConfig.versionPackage.versionType == 'er_std') 
        || (regionConfig.profile && regionConfig.profile.eaService))
    },
    menus() {
      let res = [
        { id: '1', name: '我的应用', click: v => this.toAppList(v), list: this.$store.getters.appList.filter(app => app.name.indexOf(this.searchAppName) > -1) },
        { id: '2', name: '企联应用', click: v => this.toIndustryAppList(v), list: this.$store.getters.industryAppList.filter(app => app.name.indexOf(this.searchAppName) > -1) }
      ]
      return res
    },
  },
  methods: {
    toAppList({id}){
      this.$router.push(`/app/${id}`);
    },
    toIndustryAppList({id, type}) {
      this.$router.push(`/eaapp/${eaTypeMap[type]}/${id}`);
    }
  }
}
</script>

<style scoped>
.app-container .app-item {
	display: flex;
	justify-content: flex-start;
	height: 36px;
	line-height: 36px;
  color: #274048;
  font-size: 14px;
  padding-left: 10px;
	box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  transition: .3s;
  margin-bottom: 8px;
}
.app-item .icon{
	margin-right: 8px;
}
.app-item .icon i{
	font-size: 14px;
	padding: 5px;
	border-radius: 4px;
	background-color: #FFEDFA;
}
.app-item .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-item:hover, .app-item.active{
	background-color: #E8F8F3;
	color: #00C185;
}
</style>

<style>
.app-tabs .el-tabs__active-bar.is-top {
  width: 50% !important;
  margin-left: -20px;
}
.app-tabs .el-tabs__nav.is-top {
  width: 100%;
}
.app-tabs .el-tabs__item.is-top {
  width: 50%;
  text-align: center;
  padding: 0 20px !important;
  box-sizing: border-box;
}
.app-tabs .el-tabs__item.is-top:not(.is-active) {
  color: #C4C4C4;
}

.search-input .el-input__inner {
  border-radius: 4px;
}
</style>