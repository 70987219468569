<template>
  <div class="message-item" @mouseenter="data.hover = true" @mouseleave="data.hover = false" @click="rowClick($event)">
    <div class="top">
      <div v-if="data.isRead == 0" class="new"></div>
      <div class="date">{{data.createTime}}</div>
    </div>
    <div class="bottom">
      <el-checkbox v-model="data.checked" @change="itemClick"></el-checkbox>
      <el-tooltip class="icon-item" popper-class='msg-content' effect="dark" :content="data.content" placement="bottom">
        <div class="content">{{data.content}}</div> 
      </el-tooltip>
      <div v-if="data.hover" class="message-operation">
        <div v-if="data.isRead == 0" class="item-btn" @click="read()">
          <i class="el-icon-edit"></i>
          <span class="item-btn-label">标记为已读</span>
        </div>
        <div class="item-btn" @click="del()">
          <i class="iconfont mb-icon-common-delete"></i>
          <span class="item-btn-label">删除</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object,
    msgType: String
  },
  computed: {
    getFormatDate() {
      return this.data.createTime
    }
  },
  methods: {
    rowClick(ev) {
      //if(ev.target.className.indexOf('el-checkbox') > -1) return

      //this.read()
      //this.$emit('rowClick', this.data)
    },
    itemClick(v) {
      this.$emit('itemClick', v)
    },
    read() {
      this.$axios.post(this.$URL.common.message.mark, [this.data.id])
        .then(res => {
          this.$store.commit('common/readMessage', this.data.id)
          this.$emit('read')
        })
    },
    del() {
      this.$confirm('确定要删除消息吗', '提示', {
        type: 'warning',
        confirmButtonClass: 'dialog-btn danger'
      }).then(() => {
        this.$axios.post(this.$URL.common.message.delMsg, [this.data.id])
          .then(res => {
            this.$store.commit('common/delMessage', { type: this.msgType, id: this.data.id })
            this.$emit('delete')
          })
      }).catch(() => {})
    }
  }
}
</script>
<style scoped>

.message-item{
  padding: 20px 0;
  cursor: pointer;
  height: 80px;
  box-sizing: border-box;
  border-bottom: 1px solid #DCDCDC;
  font-size: 14px;
}
.message-item .top{
  padding-left: 25px;
  position: relative;
  margin-bottom: 10px;
}
.message-item .bottom{  
  position: relative;
}
.message-item .new{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 5px;
  top: 4px;
  background: #F5292D;
  position: absolute;
}
.message-item .date{
  color: #8E8E8E;
}
.message-item .content{
  display: inline-block;
  margin-left: 10px;
  white-space: nowrap;
  max-width: calc(100% - 180px);
  overflow: hidden;
  text-overflow:ellipsis; 
  vertical-align: middle;
  height: 20px;
  line-height: 20px;
}
.message-item .message-operation{
  position: absolute;
  right: 0;
  top: 0;
}
.message-item .item-btn{
  color: #6C6C6C;
  font-size: 13px;
  display: inline-block;
  margin-left: 15px;
}
.message-item .item-btn:hover{
  color: #00BD89;
}
.message-item .item-btn .item-btn-label{
  margin-left: 5px;
}

</style>
<style>
.msg-content.el-tooltip__popper {
  max-width: 900px;
}
</style>