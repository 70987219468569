var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "消息提醒",
        visible: _vm.visible,
        "append-to-body": true,
        direction: "btt",
        "custom-class": "mb-message-mgr",
        size: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
      },
    },
    [
      _c(
        "section",
        { staticClass: "message-tab" },
        _vm._l(_vm.messageTypes, function (mt, tIndex) {
          return _c(
            "div",
            {
              key: tIndex,
              staticClass: "message-type",
              class: { active: mt.active },
              on: {
                click: function ($event) {
                  return _vm.activeType(mt)
                },
              },
            },
            [
              _c("i", { staticClass: "message-type-icon", class: mt.icon }),
              mt.unRead ? _c("div", { staticClass: "new-flag" }) : _vm._e(),
              _c("span", { staticClass: "message-type-label" }, [
                _vm._v(_vm._s(mt.label)),
              ]),
            ]
          )
        }),
        0
      ),
      _c(
        "section",
        { staticClass: "message-box" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "mb-tab-container",
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "未读", name: "new" } },
                [
                  _c("message-list", {
                    attrs: {
                      datas: _vm.$store.state.common.newMessages,
                      dataTotal: _vm.newTotal,
                      msgType: "new",
                    },
                    on: {
                      pagingChange: _vm.getNewMessages,
                      rowClick: _vm.rowClick,
                      read: _vm.reloadMessage,
                      delete: _vm.reloadMessage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "已读", name: "all" } },
                [
                  _c("message-list", {
                    attrs: {
                      datas: _vm.$store.state.common.oldMessages,
                      dataTotal: _vm.oldTotal,
                      msgType: "old",
                    },
                    on: {
                      pagingChange: _vm.getOldMessages,
                      rowClick: _vm.rowClick,
                      read: _vm.reloadMessage,
                      delete: _vm.reloadMessage,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("detail", { ref: "msgDetail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }