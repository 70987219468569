<template>
  <div>
    <el-tooltip class="icon-item" effect="dark" content="微信公众号" placement="bottom">
      <i class="iconfont mb-icon-mobile" @click="wxOAOpen"></i>
    </el-tooltip>
    <WXOfficialAccounts ref="wxOA"></WXOfficialAccounts>
  </div>
</template>
<script>
import WXOfficialAccounts from '@/components/WX/WXOfficialAccounts'

export default {
  components: {
    WXOfficialAccounts
  },
  methods: {
    wxOAOpen() {
      this.$refs.wxOA.init()
    }
  }
}
</script>
<style scoped>
.mb-icon-mobile{
  font-size: 19px;
  vertical-align: text-bottom;
}
</style>