const getters = {
  isSuper: state => state.user.isSuper,
  hasPwd: state => state.user.user.hasPwd,
  userid: state => state.user.user.id,
  currentRegion: state => state.user.currentRegion,
  appList: state => state.business.appList,
  industryAppList: state => state.business.industryAppList,
  headImgUrl: state => state.user.user.headImgUrl,
  realName: state => state.user.user.realName,
  regionList: state => state.user.regionList,
  regionConfig: state => state.user.regionConfig,
  areaList: state => state.common.areaList,
  deptList: state => state.common.deptList,
  roleList: state => state.common.roleList,
  appUrlFlag: state => state.common.appUrlFlag,
  diffTimeStamp: state => state.common.diffTimeStamp
}
export default getters