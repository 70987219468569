if(!Array.prototype.any) {
  Array.prototype.any = function(lambda) {
    if(this.length === 0) return false

    for(var i = 0;i<this.length;i++) {
      if(lambda(this[i]) === true) {
        return true
      }
    }

    return false
  }
}

if(!Array.prototype.add) {
  Array.prototype.add = function(arr) {
    for(var i = 0;i<arr.length;i++) {
      this.push(arr[i])
    }

    return this
  }
}

if(!Array.prototype.every) {
  Array.prototype.every = function(lambda) {    
    if(this.length === 0) return false

    let res = true
    for(var i =0;i<this.length;i++) {
      if(lambda(this[i]) === false) {
        res = false
        break
      }
    }

    return res
  }
}

if(!Array.prototype.max) {
  Array.prototype.max = function(field) {
    if(this.length === 0) return 0
    
    let max = 0
    if(!field) {
      max = this[0]
      for(let i = 0;i < this.length; i++) {
        if(max < this[i]) {
          max = this[i]
        }
      }
    } else {
      max = this[0][field]
      for(let i = 0;i < this.length; i++) {
        if(max < this[i][field]) {
          max = this[i][field]
        }
      }
    }

    return max
  }
}

if(!Array.prototype.min) {
  Array.prototype.min = function(field) {
    if(this.length === 0) return 0
    
    let min = 0
    if(!field) {
      min = this[0]
      for(let i = 0;i < this.length; i++) {
        if(min > this[i]) {
          min = this[i]
        }
      }
    } else {
      min = this[0][field]
      for(let i = 0;i < this.length; i++) {
        if(min > this[i][field]) {
          min = this[i][field]
        }
      }      
    }

    return min
  }
}

if(!Array.prototype.distinct) {
  Array.prototype.distinct = function(fields) {
    if(this.length === 0) return []

    let arr = [], $this = this
    if(Object.prototype.toString.call(this[0]) == '[object Object]') {
      for(let i = 0;i <this.length; i++) {
        let filter = ''
        for(var j = 0; j < fields.length; j++) {        
          filter += `$this[i].${fields[j]} === d.${fields[j]}`
          j !== fields.length - 1 && (filter += '&&')
        }
        
        let r = arr.any((d) => {return eval(filter) })
        !r && arr.push(this[i])
      }
    } else {
      for(let i=0;i<this.length;i++) {
        if(arr.indexOf(this[i]) == -1) {
          arr.push(this[i])
        }
      }
    }

    return arr
  }
}

if(!Array.prototype.equals) {
  Array.prototype.equals = function(array) {
    if (!Array.isArray(array)) return false
    if (this.length != array.length) return false

    for(let i = 0; i < this.length; i++) {
      if(this[i] != array[i]) return false
    }

    return true
  }
}

if(!String.prototype.toChar) {
  String.prototype.toChar = function() {
    var _r = []
    for(var i=0; i<this.length; i++) {
        _r.push(this.charAt(i))
    }

    return _r
  }
}
if(!String.prototype.startsWith) {
  String.prototype.startsWith = function(str) {
    var d = this.length - str.length
    return (d >= 0 && this.indexOf(str) == 0)
  }
}
if(!String.prototype.endsWith) {
  String.prototype.endsWith = function(str) {
    var d = this.length - str.length;
    return (d >= 0 && this.lastIndexOf(str) == d)
  }
}
// 随机获取数组元素
if(!Array.prototype.randomElement) {
  Array.prototype.randomElement = function () {
    return this[Math.floor(Math.random() * this.length)]
  }
}

Math.mod = function(n, m) {
  return ((n % m) + m) % m
}

Date.str2Date = function(strDate) {
  var date = eval('new Date(' + strDate.replace(/\d+(?=-[^-]+$)/, function (a) { return parseInt(a, 10) - 1; }).match(/\d+/g) + ')')
  
  return date
}

Date.prototype.format = function(fmt) {
  var o = { 
    "M+" : this.getMonth()+1,                 //月份 
    "d+" : this.getDate(),                    //日 
    "h+" : this.getHours(),                   //小时 
    "m+" : this.getMinutes(),                 //分 
    "s+" : this.getSeconds(),                 //秒 
    "q+" : Math.floor((this.getMonth()+3)/3), //季度 
    "S"  : this.getMilliseconds()             //毫秒 
  } 
  if(/(y+)/.test(fmt)) {
    fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
  }
  for(var k in o) {
    if(new RegExp("("+ k +")").test(fmt)){
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
      }
  }
  return fmt
}

Element.prototype.closestReplenish = function(tar) {

  var ele = this;

  var elArr = (function() {

      if (tar instanceof HTMLElement) return [tar];

      try {
          tar = document.querySelectorAll(tar);
      } catch (e) {
        console.error(e)
      } finally {
          var type = Object.prototype.toString.call(tar).slice(8, -1);
          if (['NodeList', 'HTMLCollection', 'Array'].indexOf(type) > -1) {
            return [].slice.call(tar);
          }
      }

  })();

  do {

      if (elArr.indexOf(ele) > -1) return ele;

      ele = ele.parentElement;

  } while (ele !== null);

  return null;

}
