import axios from 'axios'
import qs from 'qs'

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params){   
  return new Promise((resolve, reject) =>{        
    axios.get( url, { params: params } )
    .then(res => resolve(res) )
    .catch(err => reject(err) )
  })
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function postStringify(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, qs.stringify(params))
    .then(res => resolve(res) )
    .catch(err => reject(err) )
  })
}

export function postJson(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
    .then(res => resolve(res) )
    .catch(err => reject(err) )
  })
}

