import { app_colors, app_icons } from '@/assets/script/business.style'
import { domainMap } from '@/assets/script/host'

const fieldValidate = (n, r, val) => {
  if (r.relate) {    
    if(r.relate.name) {
      let nv =  eval(`n.${r.relate.name}`)
      if(Array.isArray(nv)) {
        return r.relate.value.any(rv => { return nv.any(v => { return rv === v }) }) && utils.unValidate(val, r.inRequired)
      } else {
        return r.relate.value.any(rv => { return rv === nv }) && utils.unValidate(val)
      }
    } else if(r.relate.target) {
      return r.relate.target.any(rv => { return rv === r.relate.value }) && utils.unValidate(val)
    }
  } else {
    return utils.unValidate(val, r.inRequired)
  }
}
//  @params datas 所有的路由信息 
//  @params node  路由父节点
const recursionHelp = (datas, node, config) => {
  if(datas.any(d => { return d[config.parentKey] === node[config.idKey] })){
    node[config.childKey] = datas.filter(d => { return d[config.parentKey] === node[config.idKey] })
    node[config.childKey].forEach(c => {
      recursionHelp(datas, c, config)
    })
  }
}
// 深度对比两个对象是否完全相等
function deepCompare(x, y) {
  var i, l, leftChain, rightChain;

  function compare2Objects(x, y) {
      var p;

      // remember that NaN === NaN returns false
      // and isNaN(undefined) returns true
      if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
          return true;
      }

      // Compare primitives and functions.     
      // Check if both arguments link to the same object.
      // Especially useful on the step where we compare prototypes
      if (x === y) {
          return true;
      }

      // Works in case when functions are created in constructor.
      // Comparing dates is a common scenario. Another built-ins?
      // We can even handle functions passed across iframes
      if ((typeof x === 'function' && typeof y === 'function') ||
          (x instanceof Date && y instanceof Date) ||
          (x instanceof RegExp && y instanceof RegExp) ||
          (x instanceof String && y instanceof String) ||
          (x instanceof Number && y instanceof Number)) {
          return x.toString() === y.toString();
      }

      // At last checking prototypes as good as we can
      if (!(x instanceof Object && y instanceof Object)) {
          return false;
      }

      if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
          return false;
      }

      if (x.constructor !== y.constructor) {
          return false;
      }

      if (x.prototype !== y.prototype) {
          return false;
      }

      // Check for infinitive linking loops
      if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
          return false;
      }

      // Quick checking of one object being a subset of another.
      // todo: cache the structure of arguments[0] for performance
      for (p in y) {
          if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
              return false;
          } else if (typeof y[p] !== typeof x[p]) {
              return false;
          }
      }

      for (p in x) {
          if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
              return false;
          } else if (typeof y[p] !== typeof x[p]) {
              return false;
          }

          switch (typeof(x[p])) {
              case 'object':
              case 'function':

                  leftChain.push(x);
                  rightChain.push(y);

                  if (!compare2Objects(x[p], y[p])) {
                      return false;
                  }

                  leftChain.pop();
                  rightChain.pop();
                  break;

              default:
                  if (x[p] !== y[p]) {
                      return false;
                  }
                  break;
          }
      }

      return true;
  }

  if (arguments.length < 1) {
      return true; //Die silently? Don't know how to handle such case, please help...
      // throw "Need two or more arguments to compare";
  }

  for (i = 1, l = arguments.length; i < l; i++) {

      leftChain = []; //Todo: this can be cached
      rightChain = [];

      if (!compare2Objects(arguments[0], arguments[i])) {
          return false;
      }
  }

  return true;
}

const utils = {
  flowList: [
    {
      id: -1,
      label: '流程发起人'
    }
  ],
  fieldList: [
    {
      api_name: '${obj_creater}',
      label: '数据创建人'
    },{
      api_name: '${obj_last_editor}',
      label: '数据修改人'
    }
  ],
  isMobile: () => {
    let res = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    
    return res
  },
  isNumeric: (str) => {
    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数

    let res = regPos.test(str) || regNeg.test(str)

    return res
  },
  formatPrecision: (num, precision) => {
    if (typeof num === 'number' && precision !== undefined) {
      return num.toFixed(precision)
    } else if (typeof num === 'string' && precision !== undefined) {
      let val = Number(num.replace(/,/g, ''))
      val = utils.toPrecision(val, precision)
      return val
    }
  },
  toPrecision(num, precision) {
    if (num === '') return ''
    return Number(num).toFixed(precision)
  },
  thousandSeparator: (num) => {
    if(num === null || num === undefined) return ''
    
    let arr = num.toString().split('.')
    arr[0] = (arr[0].toString()).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,')

    return arr.join('.')
  },
  urlEncode(str){
    str = (str + '').toString()  

    return encodeURIComponent(str)
      .replace(/!/g, '%21')
      .replace(/'/g, '%27')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29')
      .replace(/\*/g, '%2A')
      .replace(/%20/g, '+')
  },
  math: {
    maxNumber: 2147483647,
    minNumber: -2147483648,
    decimalLength: 9,
    add: (arg1, arg2) => {
      var r1, r2, m;
      try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
      try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
      m = Math.pow(10, Math.max(r1, r2))
      return utils.math.division((utils.math.multiple(arg1, m) + utils.math.multiple(arg2, m)), m)
    },
    multiple: (arg1, arg2) => {
      var m = 0, s1 = arg1.toString(), s2 = arg2.toString(), block = 0//占位使用(catch不能为空块)
      try { m += s1.split(".")[1].length } catch (e) { block = 0 }
      try { m += s2.split(".")[1].length } catch (e) { block = 0 }
      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    },
    division: (arg1, arg2) => {
      var t1 = 0, t2 = 0, r1, r2, block = 0
      try { t1 = arg1.toString().split(".")[1].length } catch (e) { block = 0 }
      try { t2 = arg2.toString().split(".")[1].length } catch (e) { block = 0 }
      r1 = Number(arg1.toString().replace(".", ""))
      r2 = Number(arg2.toString().replace(".", ""))
      return (r1 / r2) * Math.pow(10, t2 - t1)
    }
  },
  recursion: (datas, idKey, parentKey, childKey) => {
    let ik = idKey || 'id',
      pk = parentKey || 'parentId', 
      ck = childKey || 'children'

    let config = {
      idKey: ik,
      parentKey: pk,
      childKey: ck
    }
    //  找到跟路由  没有pk或者pk == -1
    let roots = datas.filter(r => { return !r[pk] || r[pk] == -1 })
    roots.forEach(r => {
      recursionHelp(datas, r, config)
    })

    return roots
  },
  getMax: (arr) => {
    if(arr.length === 0) return 0
  
    let max = 0
    arr.forEach(f => { 
      let t = parseInt(f.replace(/[^0-9]/ig,""))
      if(isNaN(t)) {
        let fArr = f.toChar()
        fArr.reverse()      
        t = parseInt(fArr.join(''))
        if(isNaN(t)) {
          t = 0
        }
      }
  
      if(t > max) {
        max = t
      }
    })
  
    return max
  },
  unValidate: (val, inRequired = false) => {
    // inRequired: 表示此值为array类型 需要判断对象内，至少存在一项 不为空的有效键值对 (人员字段)
    if (inRequired) {
      let flag = true
      for (const key in val) {
        if (val.hasOwnProperty(key) && !utils.unValidate(val[key])) flag = false
      }
      return flag
    } else {
      return val === null || val === undefined || val === '' || (Array.isArray(val) && val.length === 0) || (Object.prototype.toString.call(val) === '[object Object]' && Object.keys(val).length === 0)
    }

  },
  getUnValidateRule: (data, rules) => {
    return rules.find(r => {
      let val = eval(`data.${r.name}`)
      if(r.field) {
        if(r.relate && r.relate.value.any(rv => { return rv === eval(`data.${r.relate.name}`) })) {
          return utils.unValidate(val) || val.length === 0 || val.any(d => { return fieldValidate(data, r.field, eval(`d.${r.field.name}`)) })
        } else {
          return val.any(v => { return utils.unValidate(v[r.field.name]) }) 
        }
      } else {
        return fieldValidate(data, r, val)
      }                
    })
  },
  getRandomString(len) {
    len = len || 32
    var $chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
    var maxPos = $chars.length
    var pwd = ''
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
    }

    return pwd
  },
  //获取dom中的焦点位置
  getDomFocus(query) {
    let selection = window.getSelection || document.selection
    !selection && (selection = () => { return null })

    return selection()
  },
  unescapeHTML(a){
    a = "" + a
    return a.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'")
  },
  getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
    var r = window.location.search.substr(1).match(reg)
    if (r != null)
        return decodeURI(r[2])
    return null
  },
  isMobileUserAgent() {
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true
    }
    else {
      return false
    }
  },
  // 防抖函数
  debounce(fn, delay = 300){
    let timer = null 
    return function(){
        if(timer){
            clearTimeout(timer);
        }
        timer = setTimeout(fn, delay)    
    }
  },
  deepClone(obj){
    if(typeof obj !== "object") return;
    let newObj = obj instanceof Array ? [] : {};
    for(let key in obj){
      if(obj.hasOwnProperty(key)){
        newObj[key] = typeof obj[key] === "object" ? utils.deepClone(obj[key]) : obj[key];
      }
    }
    return newObj;
  },
  // 将下划线key转为驼峰key
  transformUnderlineToCamel(key){
    return key.replace(/\_(\w)/g, function(all, letter){
      return letter.toUpperCase();
    });
  },
  transformCamelToUnderline(key){
    return key.replace(/([A-Z])/g,"_$1").toLowerCase();
  },
  //  @params  num  如果有num的话  不再随机生成，根据num取余生成
  createRandomIcon(num){

    let randomColorIndex = num ? num % (app_colors.length) : Math.floor(Math.random() * app_colors.length),
        randomIconIndex = num ? num % (app_icons.length) : Math.floor(Math.random() * app_icons.length)

    return {
      color: app_colors[randomColorIndex].transformColor,
      iconColor: app_colors[randomColorIndex].color,
      icon: app_icons[randomIconIndex]
    }
  },
  copyText(text){
    let tInput = document.createElement('input')
    document.body.appendChild(tInput)
    tInput.value = text
    tInput.select()
    document.execCommand('copy')
    tInput.blur()
    document.body.removeChild(tInput)
  },
  exportExcel(data, title) {
    const aLink = document.createElement("a")
    let blob = new Blob([data], { type: "application/vnd.ms-excel" })
    aLink.href = URL.createObjectURL(blob)
    aLink.download = title
    aLink.click()
    document.body.appendChild(aLink)
  },
  exportPdf(data, title) {
    const aLink = document.createElement("a")
    let blob = new Blob([data], { type: "application/pdf" })
    aLink.href = URL.createObjectURL(blob)
    aLink.download = title
    aLink.click()
    document.body.appendChild(aLink)
  },
  
  downloadImg(src, name){
    let a = document.createElement('a');          // 创建一个a节点插入的document
    let event = new MouseEvent('click')           // 模拟鼠标click点击事件
    a.download = name                  // 设置a节点的download属性值
    a.href = src;                                 // 将图片的src赋值给a节点的href
    a.dispatchEvent(event) 
  },
  downloadImgByStream(src, name){
    let xmlHttp = new XMLHttpRequest()

    xmlHttp.open('GET', src, true)
    xmlHttp.responseType = 'blob'

    xmlHttp.onload = function(res){
      let blob = this.response,
        href = window.URL.createObjectURL(blob),
        aEle = document.createElement('a')

      aEle.href = href
      aEle.download = name
      aEle.click()
    }

    xmlHttp.send()
  },
  downloadFile(event, file, file_list){
    event && event.stopPropagation()
    // 开发环境和本地直接下载,生产环境使用流(处理文件名称)
    let commonAddressList = [domainMap.dev, domainMap.sit, domainMap.uat],
      host = location.host

    if(commonAddressList.any(address => host.indexOf(address) > -1)){
      if(file.url) {
        window.open(file.url)
        return
      }
      
      if(Array.isArray(file_list)) {
        let tf = file_list.find(f => file.uid == f.uid)
        tf && tf.url && window.open(tf.url)
      }
    }else{
      this.downloadImgByStream(file.url, file.fileName)
    }    
  },
  // ArrayBuffer对象转字符串
  arrayBufferToStr(buffter, callback) {
    var b = new Blob([buffter]);
    var r = new FileReader();
      r.readAsText(b, 'utf-8');
      r.onload = function (){
        if(callback) callback.call(null, r.result)
      }
  },

  document: {
    getPrevNode: (element) =>{
      if (element.previousElementSibling !== undefined) {
        return element.previousElementSibling
      } else {
        var prev = element.previousSibling;
        //存在前面的节点，而且那个节点不是元素节点
        while (prev && prev.nodeType != 1) {
            //继续往前
            prev = prev.previousSibling
        }
        return prev
      }
    },
    getContentSize: (content) => {
      let cType = Object.prototype.toString.call(content)
      if( cType !== '[object String]' && cType !== '[object String]') return { width: 0, height: 0 }

      let span = document.createElement('span')
      span.style.visibility= 'hidden'
      span.innerHTML = content
      document.body.appendChild(span)

      let res = {
        width: span.offsetWidth,
        height: span.offsetHeight
      }

      document.body.removeChild(span)

      return res
    }
  },
  sleep(numberMillis) { 
    var now = new Date(), flag = true
    var exitTime = now.getTime() + numberMillis
    while (flag) { 
      now = new Date()
      if (now.getTime() > exitTime) return
    } 
  },
  deepCompare: deepCompare
}

export default utils