var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-container" },
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "我的消息",
            placement: "bottom-start",
          },
        },
        [
          _c(
            "el-badge",
            {
              staticClass: "mb-message-badge",
              attrs: { "is-dot": "", hidden: _vm.badgeNumber == 0 },
            },
            [
              _c("i", {
                staticClass: "iconfont mb-icon-common-bell",
                on: {
                  click: function ($event) {
                    return _vm.showMessage()
                  },
                },
              }),
            ]
          ),
        ],
        1
      ),
      _c("message-list", { ref: "messageList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }