<template>
  <div v-if="visible">
    <el-drawer ref="drawer" class="iopen-edit-drawer" :title="viewModel.title" :visible.sync="visible" 
      :append-to-body="true" :size="viewModel.width || '60%'" @close="onClose">
      <div class="container-content">
        <loader :viewModel="viewModel" :data="data"></loader>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Loader from './Loader'

export default {
  components: {
    Loader
  },
  props: {
    viewModel: Object,
    data: {
      require: true
    }
  },
  data() {
    return {
      visible: true
    }
  },
  methods: {
    onClose() {
      this.visible = false
    }
  }
}
</script>
<style scoped>
.container-content{
  padding-bottom: 20px !important;
}
</style>