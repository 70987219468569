<template>
  <div>
    <el-dropdown v-if="regionList.length > 1 && !readonly" class="region-select-container" placement="bottom-start">
      <span class="el-dropdown-link">
        {{regionInfo.name}}<i class="iconfont mb-icon-common-switch1 el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" class="region-list">
        <el-dropdown-item v-for="region in regionList" @click.native="changeRegion(region)"
          :key="region.code" :class="{active: currentRegion == region.code}">
            {{region.name}}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <span v-else class="el-dropdown-link">{{regionInfo.name}}</span>
  </div>
</template>

<script>
import { domainHost, domainRootHost } from '@/assets/script/host.js'

export default {
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      regionList: [],
      regionName: '',
    }
  },
  mounted() {
    this.regionList = this.$store.getters.regionList
  },
  computed: {
    currentRegion(){
      return localStorage.getItem('login-region') || ''
    },
    regionInfo(){
      return this.regionList.find(region => region.code == this.currentRegion) || {}
    }
  },
  methods: {
    // 切换组织
    changeRegion({code, tenantId}){
      this.$axios.post(this.$URL.common.base.switchRegion, { code }).then(res => {
        localStorage.setItem('login-region', code);
        localStorage.setItem('login-tenant', tenantId);
        // localStorage.setItem('login-token', res.data.token.token);

        let data = res.data

        let domain = JSON.stringify( {
          domain: data.domain,
          domainStatus: data.status,
        })
        localStorage.setItem('login-domain', domain)

        let dStr = JSON.stringify({
          tenant: localStorage.getItem('login-tenant'),
          token: localStorage.getItem('login-token'),
          userid: localStorage.getItem('login-userid'),
          region: localStorage.getItem('login-region'),
          remember: localStorage.getItem('login-remember'),
          domain: localStorage.getItem('login-domain'),
          status: sessionStorage.getItem('login-status'),
        })
        let cStr = `u-data=${dStr}; domain=${domainRootHost};path=/`
        document.cookie = cStr

        let url = data.status === 1 ? location.protocol + '//' + data.domain + '.' + domainHost : location.protocol + '//' + domainHost

        location.href = url + `/`
      })
    },
  },
}
</script>
<style scoped>
  .region-select-container{
    color: #274048;
    height: 32px;
    line-height: 32px;
    border-radius: 5px;
    cursor: pointer;
  }
  .el-dropdown-link{
    font-size: 16px;
  }
  .el-dropdown-link i {
    color: #A1A1A1;
  }
</style>
<style>
  .region-list{
    max-height: 230px;
    overflow-y: auto;
  }
  .region-list .el-dropdown-menu__item {
    width: 160px;
    color: #274048;
    font-size: 14px;
    white-space: nowrap;
    padding: 0 8px;
    overflow: hidden;
    text-overflow:ellipsis;
    box-sizing: border-box;
  }
  .region-list .el-dropdown-menu__item.active{
    color: #00C185;
    background-color: #E8F8F3;
    background-image: url(../assets/img/right.png);
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: 14px auto;
  }
  .region-list .el-dropdown-menu__item:not(.is-disabled):not(.active):hover{
    background-color: #E8F8F3;
    color: #274048;
  }
</style>