<template>
  <el-dialog :visible.sync="visible" append-to-body
    width='800px' custom-class="wx-dialog">

    <template v-slot:title>
      <div class="wx-dialog-title">
        <i class="iconfont mb-icon-wxicon"></i>
        <span>微信服务号</span>
      </div>
    </template>

    <div class="wx-oa-panel">
      <div class="wx-info">
        <img :src="wxUrl" />
        <div class="desc">扫码关注「BYGO」微信公众号</div>
        <div class="desc">在微信上使用 BYGO</div>
      </div>
      <div class="func-box">
        <div class="func-item">
          <i class="iconfont mb-icon-wxwork"></i>
          <span class="label">微信办公</span>
        </div>
        <div class="func-item">
          <i class="iconfont mb-icon-custome-service"></i>
          <span class="label">客服支持</span>
        </div>
        <div class="func-item">
          <i class="iconfont mb-icon-wxinformation"></i>
          <span class="label">获取最新资讯</span>
        </div>
      </div>
      <div class="center-line"></div>
    </div>
  </el-dialog>  
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      wxUrl: `https://open.weixin.qq.com/qr/code?username=MagicBYGO`
    }
  },
  methods: {
    init() {
      this.visible = true
    }
  }
}
</script>
<style scoped>
.wx-oa-panel{
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
}
.wx-dialog{
  border-radius: 4px;
}
.wx-dialog-title{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #274048;
}
.wx-dialog-title .mb-icon-wxicon{
  font-size: 30px;
  color: #07B142;
  margin-right: 12px;
}
.wx-oa-panel .wx-info{
  width: 200px;
}
.wx-oa-panel .wx-info img{
  display: block;
  margin: 0 auto;
  width: 140px;
  margin-bottom: 8px;
}
.wx-oa-panel .wx-info .desc{
  color: #798A89;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
.wx-oa-panel .func-box{
  padding: 0 20px 20px 0px;
}
.wx-oa-panel .func-box .func-item{
  height: 60px;
  line-height: 60px;
}
.wx-oa-panel .func-box .func-item .label{
  margin-left: 10px;
}
.wx-oa-panel .iconfont.mb-icon-wxwork{
  color: #9A95FB;
}
.wx-oa-panel .iconfont.mb-icon-custome-service{
  color: #FC9702;
}
.wx-oa-panel .iconfont.mb-icon-wxinformation{
  color: #04BF88;
}
.wx-oa-panel .center-line{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 205px;
  background-image: linear-gradient(to bottom, #fff, #E9E8E4, #fff);
}
</style>
<style>
.wx-dialog .el-dialog__header{
  border-bottom: none;
  line-height: 30px;
  padding-top: 32px;
  padding-bottom: 12px;
  height: auto;
}
.wx-dialog .el-dialog__body{
  padding-bottom: 100px;
}
</style>