// 新UI menu
export const menusNew = [
  { title: '流程管理', children: [
    { id: 'platform_workflow_instance', icon: 'iconfont mb-icon-saas-instance', name: '流程实例', url: '/platform/workflow/instance' },
  ] },
  { title: '用户管理', children: [
    {id: 'platform_user_member', icon: 'iconfont mb-icon-saas-member', name: '部门管理', url: '/platform/user/user' },
  ] },
  { title: '角色', children: [
    { id: 'platform_role', icon: 'iconfont mb-icon-saas-role', name: '角色管理', url: '/platform/role' },
  ] },
  { title: '组织', children: [
    { id: 'platform_organization', icon: 'iconfont mb-icon-saas-wg', name: '组织管理', url: '/platform/organization' },
  ] },
  /*{ title: '系统设置', children: [
    { id: 'platform_system_dict', icon: 'iconfont mb-icon-saas-dict', name: '数据字典', url: '/platform/system/dict' },
    { id: 'platform_system_rule', icon: 'iconfont mb-icon-saas-coderule', name: '编码规则', url: '/platform/system/rule' },
    { id: 'platform_system_config', icon: 'iconfont mb-icon-saas-syscfg', name: '系统配置', url: '/platform/system/config' },
  ]}*/  
]