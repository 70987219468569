<template>
  <el-dialog title="内容" :append-to-body="true" :visible.sync="visable" width="400px" 
    class="mb-message-detail" @close="$emit('close')">
    <div class="content">{{data.content}}</div>
    <div class="date">{{data.date}}</div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      visable: true
    }
  }
}
</script>