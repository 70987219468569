//系统管理接口
import host from '@/assets/script/host'

let platform = {}
//系统管理-工作流
platform.workflow = {
  template: {
    list: `${host}/bpm/template/view`,
    delete: `${host}/bpm/template/delete`,
    enable: `${host}/bpm/template/operate`,
    create: `${host}/bpm/template/commit`,
    update: `${host}/bpm/template/update`,
    detail: `${host}/bpm/template/info`,
    nodeList: `${host}/bpm/node/view`,
    nodeDetail: `${host}/bpm/node/detail`,
    businessWfTmpl: `${host}/bpm/template/templatesOfbusiness`,
    systemFormList: `${host}/bizmeta/v1/object/describe/service/sysDescribeList`,
    getSystemFields: `${host}/bizmeta/v1/object/describe/service/findSysDescribeByApiName`,
    getBusTypeWfDetail: (businessId, wfType) => { 
      return `${host}/bpm/template/business/${businessId}/type/${wfType}` 
    }
  },
  instance: {
    list: `${host}/bpm/process/viewAll`,
    operate: `${host}/bpm/process/operate`,
    detail: `${host}/bpm/process/detail`
  }
}
//系统管理-业务管理
platform.business = {
  list: `${host}/bizmeta/v1/object/describe/service/findDescribeList`, 
  createlist: `${host}/bizmeta/v1/object/describe/service/getSupportFlowObjects`, 

  create: `${host}/bizmeta/v1/object/describe/service/createDescribe`, 
  detail: `${host}/bizmeta/v1/object/describe/service/findDescribeByApiName`, 
  update: `${host}/bizmeta/v1/object/describe/service/updateDescribe`, 
  delete: `${host}/bizmeta/v1/object/describe/service/deleteDescribe`, 

  uploadExcel: `${host}/bizmeta/v1/object/bulkimport/getExcelInfo`,
  uploadFile: `${host}/bizmeta/v1/object/bulkimport/uploadFile`,
  sheetInfo: `${host}/bizmeta/v1/object/bulkimport/sheetInfo`,
  importData: `${host}/bizmeta/v1/object/bulkimport/importDescribeWithData`,

  checkName: `${host}/bizmeta/objectDescribe/checkName`,  
  businessWfTmpl: `${host}/bpm/template/allBusinessTemplate`,

  getLinkDataFieldList: `${host}/bizmeta/v1/object/describe/service/findFieldDescribes`,  // 查询映射对象的字段列表
}
//外链业务表
platform.smartform = {
  create: `${host}/bizmeta/v1/smartform/admin/create`,
  list: `${host}/bizmeta/v1/smartform/admin/lists`,
  delete: `${host}/bizmeta/v1/smartform/admin/delete`,
  update: `${host}/bizmeta/v1/smartform/admin/update`,
  findDataList: `${host}/bizmeta/v1/smartform/admin/findDataList`,
  dataHeader: `${host}/bizmeta/v1/smartform/rest/ext/describe/detail`,
  dataSubmit: `${host}/bizmeta/v1/smartform/rest/ext/data/submit`
}
//系统管理-用户管理
platform.user = {
  org: {
    tree: `${host}/upm/org/tree`,
    assignedTree: `${host}/upm/org/assignedTree`,
    addOrgGroup: `${host}/upm/org/addOrgGroup`,
    deleteOrgGroup: `${host}/upm/org/deleteOrgGroup`,
    updateOrgGroup: `${host}/upm/org/updateOrgGroup`,    
    detailOrgGroup: `${host}/upm/org/detailOrgGroup`,
    listOrgByIds: `${host}/upm/org/listOrgByIds`,
    getOrgGroupInfoById: `${host}/upm/org/getOrgGroupOwnerById`,
    getOrgGroupOwnerByIds: `${host}/upm/org/getOrgGroupOwnerByIds`,
    listUserByCnd: `${host}/upm/sysUser/listUserByCnd`
  },
  userOrg: {
    deleteUserOrgGroup: `${host}/upm/userOrg/deleteUserOrgGroup`,
    pageUserOrgByCnd: `${host}/upm/userOrg/pageUserOrgByCnd`,
    inviteuser: `${host}/upm/userOrg/inviteuser`,
    inviteUserByPhone: `${host}/upm/userOrg/inviteUserByPhone`,
    generateTemplate: `${host}/upm/userOrg/generateTemplate`,
    importUser: `${host}/upm/userOrg/importUser`,

    orgUserPageList: `${host}/upm/userOrg/orgUserPageList`,
    addUserOrg: `${host}/upm/userOrg/addUserOrg`,

    getUserByOrg: `${host}/upm/userOrg/getUserByOrg`,
    getUserByOrgIds: `${host}/upm/userOrg/getUserByOrgIds`,

    detailUserInfo: `${host}/upm/userOrg/detailUserInfo`,
    
    addOwner: `${host}/upm/userOrg/createGroupOwner`,
    cancelOwner: `${host}/upm/userOrg/deleteGroupOwner`,
    removeByUserId: `${host}/upm/userOrg/removeByUserId`,
    listOrgByUserId: `${host}/upm/userOrg/listOrgByUserId`
  },
  user: {
    getUserByIdsName: `${host}/upm/sysUser/listByIdsAndName`,
    userPageList: `${host}/upm/sysUser/userPageList`,
    getUserRegion: `${host}/upm/region/getUserRegions`,
    getUserByRegionCode: `${host}/upm/region/getUserByRegionCode`,
    getInactivatedUser: `${host}/upm/region/getInactivatedUser` 
  },
  transferWork: {
    count: `${host}/bpm/work/transferWork/count`,
    template: `${host}/bpm/work/transferWork/query`,
    update: `${host}/bpm/work/transferWork/update`
  },
  tenant: {
    detailTenant: `${host}/upm/tenant/detailTenant`,
    updateTenant: `${host}/upm/tenant/updateTenant`,
    // reSendMail: `${host}/upm/tenant/reSendMail`, //用户邮箱重新激活
    activeAgain: `${host}/upm/tenant/activeAgain/`
  }
}
//系统管理-角色管理
platform.role = {
  systemObjectList: `${host}/busmodel/business/systemObjectList`,  // 公司组业务 + 应用

  role: {
    tree: `${host}/upm/role/tree`,
    detail: `${host}/upm/role/detailRole`,
    listRoleByType: `${host}/upm/role/listRoleByType`,
    addRole: `${host}/upm/roleMenu/save`,
    updateRole: `${host}/upm/roleMenu/update`,

    deleteRole: `${host}/upm/roleMenu/delete`,
  },
  userRole: {
    addRoleUserCount: `${host}/upm/userRole/addRoleForSeveralUser`,
    deleteUserRole: `${host}/upm/userRole/deleteUserRole`,
    removeUserRole: `${host}/upm/userRole/removeUserRole`,
    listUserRoleByCnd: `${host}/upm/userRole/listUserRoleByCnd`,
    pageUserRoleList: `${host}/upm/userRole/pageUserRoleList`,
    listRoleUsers: `${host}/upm/userRole/listRoleUsers`
  }
}
//系统管理-工作组管理
platform.workgroup = {
  workgroup: {
    checkName: `${host}/busmodel/workGroup/checkName`,
    create: `${host}/busmodel/workGroup/create`,
    groupLists: `${host}/busmodel/workGroup/lists`, // 带权限的工作组列表  用于 工作组管理
    groupList: `${host}/busmodel/workGroup/groupList`,
    getDetail: `${host}/busmodel/workGroup/getDetail`,
    renovateStatus: `${host}/busmodel/workGroup/renovate/status`,
    renovateWork: `${host}/busmodel/workGroup/renovate/work`,
    renovateDel: `${host}/busmodel/workGroup/renovate/del`,
    findAllBusinessDescribeList: `${host}/bizmeta/v1/object/describe/service/findAllBusinessDescribeList`,
  },
  business: {
    getNumByGroupId: `${host}/bizmeta/object/group/relResource/`

  }  
}


platform.organization = {
  //  save  update  detail
  region(path){
    return `${host}/upm/region/${path}`
  },
  wsList: `${host}/upm/ws/lists`,
  activeRegion: `${host}/upm/region/active`,

  updateDomainStatus: `${host}/upm/region/updateDomainStatus`,
  updateDomain: `${host}/upm/region/updateDomain`,
}




//系统管理-系统管理
platform.system = {
  dictionary: { // 数据字典
    catalog: `${host}/system/dict/catalog`,
    submit: `${host}/system/dict/submit`,
    list: `${host}/system/dict/list`,
    dicts: `${host}/system/dict/dictionary`,
    detail: `${host}/system/dict/detail`,
    remove: `${host}/system/dict/remove`
  },
  buscode: {
    codes: `${host}/system/coderule/codes`,
    code: `${host}/system/coderule/code`
  },
  config: { //配置
    list: `${host}/system/param/list`,
    remove: `${host}/system/param/remove`,
    submit: `${host}/system/param/submit`
  }
}

export default platform